import React, { useState, useEffect } from 'react'
import {
  Dialog, TextField, Grid, DialogActions, DialogContent, Button,
  Tabs, Tab, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Typography,
  Select, MenuItem, FormControl, Checkbox, FormControlLabel
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux'
import AutoComplite from '../../components/Autocomplite'
import * as actions from '../../reducers/form';
import 'moment/locale/ru';
import moment from 'moment'
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { fetchDispath } from '../../functions/fetch'
import { delWorkGant, addWorkToGant, updWorkGant, delArrowWorkToGant } from '../../api/form-gant.api'
import arrowType from './gantArrowType'
import { typeGant } from '../../enum/gant.enum'
import { addGantTree, delGantArrowIdArrow, changeGantTreeAll } from '../../reducers/gant'
import { formFacilityProject } from './gant.const'
import { getProjectGant } from '../../api/form-gant.api'

const TabPanel = ({ children, value, index }) => <div>{value === index && children}</div>
function formSelector(formName, field) {
  return state => state.form?.[formName]?.values[field]
}
export default function GantDialog({ formDialog, formName, formWork, oneRow, idProject, formMaterial, setLoad, }) {
  const [numbTab, setNumbTab] = useState(0)
  const dispatch = useDispatch()

  const obj = useSelector(formSelector(formDialog, 'obj')) || oneRow
  const id = useSelector(formSelector(formDialog, 'id'))
  const open = useSelector(formSelector(formDialog, 'open')) || false
  const newRow = useSelector(formSelector(formDialog, 'newRow')) || false
  const autocompliteParent = useSelector(formSelector(formDialog, 'autocompliteParent')) || []
  const startForm = useSelector(formSelector(formName, 'start'))
  const endForm = useSelector(formSelector(formName, 'end'))

  const [dateStart, setStart] = useState(moment().format('YYYY-MM-DD'))
  const [dateEnd, setEnd] = useState(moment().format('YYYY-MM-DD'))

  const [dateStartPlan, setStartPlan] = useState(moment().format('YYYY-MM-DD'))
  const [dateEndPlan, setEndPlan] = useState(moment().format('YYYY-MM-DD'))
  const [changeFactDate, setChangeFactDate] = useState(false)
  useEffect(() => {
    if (!open) return;
    if (newRow) {
      setEnd(moment().format('YYYY-MM-DD'))
      setStart(moment().format('YYYY-MM-DD'))
      setEndPlan(moment().format('YYYY-MM-DD'))
      setStartPlan(moment().format('YYYY-MM-DD'))
      setChangeFactDate(false)
    }
    else {
      setStart(obj.start.format('YYYY-MM-DD'))
      setEnd(obj.end.format('YYYY-MM-DD'))
      if (obj.startPlan.isValid()) { setStartPlan(obj.startPlan.format('YYYY-MM-DD')) } else { setStartPlan('') }
      if (obj.endPlan.isValid()) { setEndPlan(obj.endPlan.format('YYYY-MM-DD')) } else { setStartPlan('') }
      setChangeFactDate(true)
    }
  }, [open, newRow])
  const onClose = () => {
    setNumbTab(0)
    dispatch(actions.formChange(false, { field: 'open', name: formDialog }))
    if (newRow) {
      dispatch(actions.formArrayDelete(id, { field: 'allIds', name: formName }))
      dispatch(actions.formObjDelete({ id: id, field: 'byId', name: formName }))
    }
  }
  const workArr = useSelector(state => state.autocomplite[formWork] || state.autocomplite.default)
  const groupObj = JSON.parse(JSON.stringify(workArr.group || []))
  let filterArr = workArr.arr || []
  filterArr.forEach(e => {
    if (groupObj[e.groupKey]) {
      groupObj[e.groupKey].options.push(e)
    }
  })
  filterArr = Object.values(groupObj)

  const materialArr = useSelector(state => state.autocomplite[formMaterial] || state.autocomplite.default)
  const groupObjM = JSON.parse(JSON.stringify(materialArr.group || []))
  let filterArrM = materialArr.arr || []
  filterArrM.forEach(e => {
    if (groupObjM[e.groupKey]) {
      groupObjM[e.groupKey].options.push(e)
    }
  })
  filterArrM = Object.values(groupObjM)

  const changeWork = (e) => {
    dispatch(actions.formChange({
      ...obj,
      idWork: e.id,
      name: e.label,
      idGroup: e.groupId,
      value: e,
      estNumber: e.groupNumber,
      idOrder: undefined,
      storageUnit: e.storageUnit,
    }, { field: 'obj', name: formDialog }))
  }
  const changeParent = (e) => {
    dispatch(actions.formChange({
      ...obj,
      parentValue: e,
      parent: e ? e.value : null
    }, { field: 'obj', name: formDialog }))
  }
  const changeMaterial = (e) => {
    dispatch(actions.formChange({
      ...obj,
      idMaterial: e.id,
      name: e.label,
      idGroup: e.groupId,
      valueMaterial: e,
      estNumber: e.groupNumber,
      idOrder: undefined,
      storageUnit: e.storageUnit,
    }, { field: 'obj', name: formDialog }))
  }
  const changeObjValue = (field, value) => {
    dispatch(actions.formObjAdd(value, { field: 'obj', id: field, name: formDialog }))
  }
  const handleOk = () => {
    let correct = {}
    const momentStart = moment(dateStart)
    const momentEnd = moment(dateEnd)
    if (momentStart < startForm) {
      correct.start = moment(momentStart)
      correct.end = moment(endForm)
      correct.start.add(-2, 'd')
    }
    if (momentEnd > endForm) {
      correct.end = moment(momentEnd)
      correct.start = moment(startForm)
      correct.end.add(2, 'd')
    }
    if (obj.idAPI === '') {
      dispatch(fetchDispath({
        param: {
          id: idProject
        },
        body: {
          start: dateStart,
          end: dateEnd,
          startPlan: dateStartPlan,
          endPlan: dateEndPlan,
          idWork: obj.idWork,
          idMaterial: obj.idMaterial,
          pos: obj.pos || 0,
          idGroup: obj.idGroup,
          count: obj.count,
          textField: obj.textField,
          typeGant: obj.typeGant,
          progress: obj.progress,
          parent: obj.parent,
          color: obj.color,
          idDateEndLink: obj.idDateEndLink,
          idDateStartLink: obj.idDateStartLink,
        },
        request: addWorkToGant,
      })).then(res => {
        const duration = obj.end.diff(obj.start, 'days')

        dispatch(actions.formArrayDelete(id, { field: 'allIds', name: formName }))
        dispatch(actions.formObjDelete({ id: id, field: 'byId', name: formName }))

        dispatch(actions.formObjChangeObj({
          ...obj,
          id:'id'+res.id,
          duration,
          idAPI: res.id,
          name: (obj.typeGant === 'textField' || obj.typeGant === 'parent' || obj.typeGant === 'milestone' || obj.typeGant === 'link') ? obj.textField : obj.name,
          start: momentStart,
          end: momentEnd,
          startPlan: moment(dateStartPlan),
          endPlan: moment(dateEndPlan),
        }, { field: 'byId', id: 'id'+res.id, name: formName }))
        if (Object.keys(correct).length > 0) {
          dispatch(actions.formChangeAsObj({ ...correct, count: correct.end.diff(correct.start, 'days') }, { name: formName }))
        }
        dispatch(actions.formChange(false, { field: 'open', name: formDialog }))
        dispatch(addGantTree({ parent: obj.parent || 0, id: Number(res.id), pos: Number(obj.pos) || 0, color: obj.color }))
        if (obj.typeGant === 'parent') {
          const objAdd = { value: res.id, label: obj.pos + ' ' + obj.textField }
          if (obj.parent) {
            const index = autocompliteParent.findIndex(el => el.value === obj.parent);
            const arr = [...autocompliteParent];
            arr.splice(index + 1, 0, objAdd);
            dispatch(actions.formChangeAsObj({ autocompliteParent: arr }, { name: formDialog }))
          }
          else {
            dispatch(actions.formArrayAdd(objAdd, { name: formDialog, field: 'autocompliteParent', end: true }))
          }
        }
      }).catch(err => console.log(err))
    }
    else {
      dispatch(fetchDispath({
        param: {
          id: obj.idAPI
        },
        body: {
          start: dateStart,
          end: dateEnd,
          startPlan: dateStartPlan,
          endPlan: dateEndPlan,
          idWork: obj.idWork,
          idMaterial: obj.idMaterial,
          progress: obj.progress,
          pos: obj.pos || 0,
          idGroup: obj.idGroup,
          count: obj.count,
          textField: obj.textField,
          typeGant: obj.typeGant,
          parent: obj.parent,
          color: obj.color,
          idDateEndLink: obj.idDateEndLink,
          idDateStartLink: obj.idDateStartLink,
        },
        request: updWorkGant(idProject),
      })).then(res => {
        const duration = obj.end.diff(obj.start, 'days')
        dispatch(actions.formObjChangeObj({
          ...obj,
          duration,
          name: (obj.typeGant === 'textField' || obj.typeGant === 'parent' || obj.typeGant === 'milestone' || obj.typeGant === 'link') ? obj.textField : obj.name,
          start: momentStart,
          end: momentEnd,
          startPlan: moment(dateStartPlan),
          endPlan: moment(dateEndPlan),
        }, { field: 'byId', id: id, name: formName }))
        if (Object.keys(correct).length > 0) {
          dispatch(actions.formChangeAsObj({ ...correct, count: correct.end.diff(correct.start, 'days') }, { name: formName }))
        }
        dispatch(actions.formChange(false, { field: 'open', name: formDialog }))
        dispatch(changeGantTreeAll({ parent: obj.parent || 0, id: Number(id.replace('id', '')), pos: Number(obj.pos) || 0, color: obj.color }))
        if (obj.typeGant === 'parent') {
          const objAdd = { value: obj.idAPI, label: obj.pos + ' ' + obj.textField }
          const index = autocompliteParent.findIndex(el => el.value === obj.idAPI);
          const arr = [...autocompliteParent];
          arr.splice(index, 1, objAdd);
          dispatch(actions.formChangeAsObj({ autocompliteParent: arr }, { name: formDialog }))
        }

      }).catch(err => console.log(err))
    }
  }
  const handleDelete = () => {
    if (obj.idAPI === '') {
      onClose()
      dispatch(actions.formArrayDelete(id, { field: 'allIds', name: formName }))
      dispatch(actions.formObjDelete({ id: id, field: 'byId', name: formName }))
    }
    else {
      dispatch(fetchDispath({
        param: {
          id: obj.idAPI
        },
        request: delWorkGant(idProject),
      })).then(res => {
        setLoad(true)
        // dispatch(actions.formArrayDelete(id, { field: 'allIds', name: formName }))
        // dispatch(actions.formObjDelete({ id: id, field: 'byId', name: formName }))
        onClose()
      }).catch(err => console.log(err))
    }
  }
  return (
    <Dialog
      open={open}
      maxWidth='sm'
      keepMounted
      fullWidth
      scroll="paper"
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      PaperProps={{ style: { height: 480 } }}
    >
      <DialogContent >
        <Tabs value={numbTab} onChange={(e, v) => setNumbTab(v)} indicatorColor='primary'>
          <Tab label='Основные' />
          <Tab label='Связи' disabled={newRow || obj.typeGant === 'parent'} />
        </Tabs>
        <TabPanel value={numbTab} index={0}>
          <Grid container
            direction='column'
            spacing={1}
            alignItems="flex-start"
          >
            <Grid item container direction="row" spacing={2}>
              <Grid item xs={12}>
                <AutoComplite
                  arr={autocompliteParent}
                  action={changeParent}
                  value={obj.parentValue}
                  formName={formName}
                  palaseHolder='Родитель'
                  selectProps={{
                    isClearable: true
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-end"
                spacing={2}
              >
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => changeObjValue('pos', e.target.value)}
                    value={obj.pos || ''}
                    label='Позиция в гант'
                  />
                </Grid>
                <Grid item>
                  <Typography>Тип</Typography>
                </Grid>
                <Grid item>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={obj.typeGant}
                      onChange={(e) => changeObjValue('typeGant', e.target.value)}
                      label="тип"
                      fullWidth
                      disabled={obj.idAPI !== ''}
                    >
                      {typeGant.map(e => {
                        return <MenuItem key={e.value} value={e.value}>{e.label}</MenuItem>
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              {obj.typeGant === 'work' && <Grid item xs={12}><AutoComplite
                arr={filterArr}
                action={changeWork}
                value={obj.value}
                formName={formName}
                palaseHolder=''
              /></Grid>}
              {obj.typeGant === 'material' && <Grid item xs={12}><AutoComplite
                arr={filterArrM}
                action={changeMaterial}
                value={obj.valueMaterial}
                formName={formName}
                palaseHolder=''
              /></Grid>}
              {(obj.typeGant === 'textField' || obj.typeGant === 'parent' || obj.typeGant === 'milestone' || obj.typeGant === 'link') && <Grid item xs={12}><TextField
                fullWidth
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => changeObjValue('textField', e.target.value)}
                value={obj.textField || ''}
                label='Текст'
              /></Grid>}
              {(obj.typeGant === 'milestone') &&
                <Grid item xs={6}>
                  <TextField
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      setStartPlan(e.target.value)
                      setEndPlan(e.target.value)
                      setStart(e.target.value)
                      setEnd(e.target.value)
                    }}
                    fullWidth
                    value={dateStartPlan}
                    label='Дата'
                  />
                </Grid>}
              {
                (obj.typeGant !== 'parent' && obj.typeGant !== 'milestone' && obj.typeGant !== 'link') && <React.Fragment>
                  <Grid item xs={6}>
                    <TextField
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => { setStartPlan(e.target.value); if (!changeFactDate) setStart(e.target.value) }}
                      fullWidth
                      value={dateStartPlan}
                      label='Начало план'
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => { setEndPlan(e.target.value); if (!changeFactDate) setEnd(e.target.value) }}
                      value={dateEndPlan}
                      label='Окончание план'
                      fullWidth
                      inputProps={{
                        min: dateStartPlan
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onClick={(e) => setChangeFactDate(e.target.checked)}
                          checked={changeFactDate}
                          inputProps={{ 'aria-label': 'Факт отличается от плана' }}
                        />
                      }
                      label='Факт отличается от плана'
                    />
                  </Grid>
                  {changeFactDate && <React.Fragment>
                    <Grid item xs={6}>
                      <TextField
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) => { setStart(e.target.value) }}
                        fullWidth
                        value={dateStart}
                        label='Начало'
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) => setEnd(e.target.value)}
                        value={dateEnd}
                        label='Окончание'
                        fullWidth
                        inputProps={{
                          min: dateStart
                        }}
                      />
                    </Grid>
                  </React.Fragment>}
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => changeObjValue('count', e.target.value)}
                      onFocus={e => e.target.select()}
                      value={obj.count || 0}
                      label='Количество'
                      inputProps={{
                        min: 0,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    {obj.typeGant === 'textField' ?
                      <TextField
                        fullWidth
                        type="number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) => changeObjValue('progress', e.target.value)}
                        onFocus={e => e.target.select()}
                        value={obj.progress}
                        label='Прогресс, %'
                        inputProps={{
                          min: 0,
                          max: 100
                        }}
                      />
                      : <div>
                        <div>Прогресс:{obj.progress}%</div>
                        <div>Выполнено:{obj.perf} {obj.storageUnit}</div>
                      </div>
                    }

                  </Grid>

                </React.Fragment>
              }
              {(obj.typeGant === 'parent') &&
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    type="color"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => { changeObjValue('color', e.target.value) }}
                    value={obj.color || '#aaaaaa'}
                    label='Цвет'
                  />
                </Grid>}
              {obj.typeGant === 'link' && <TypeLink formName={formName} formDialog={formDialog}
                setStart={e => setStart(e)}
                setEnd={e => setEnd(e)}
                setStartPlan={e => setStartPlan(e)}
                setEndPlan={e => setEndPlan(e)}
              />}
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={numbTab} index={1}>
          <TabDependencies formName={formName} obj={obj} formDialog={formDialog} idProject={idProject} />
        </TabPanel>
      </DialogContent>
      <DialogActions>
        <Grid container direction='row' alignItems="flex-end">
          <Grid item xs={3}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<CheckIcon />}
              onClick={handleOk}
              size='small'
            >Сохранить
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<CloseIcon />}
              onClick={onClose}
              size='small'
            >Отменить
            </Button>
          </Grid>
          <Grid item container direction='row' justify="flex-end" alignItems="flex-end" xs={6}>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
                onClick={handleDelete}
                size='small'
              >Удалить
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}


function TabDependencies({ formName, obj, formDialog, idProject }) {
  const formAll = useSelector(state => state.form[formName] || state.form.default)
  const dispatch = useDispatch()
  const handleDeleteArrow = (id) => {
    dispatch(fetchDispath({
      param: {
        idWork: obj.idAPI,
        idArrow: id
      },
      request: delArrowWorkToGant(idProject),
    })).then(res => {
      const dependencies = obj.dependencies.filter(e => e.id !== id)
      dispatch(actions.formObjChangeObj({ dependencies }, { field: 'byId', id: obj.id, name: formName }))
      dispatch(actions.formChange({
        ...obj,
        dependencies
      }, { field: 'obj', name: formDialog }))
      dispatch(delGantArrowIdArrow(id))
    }).catch(err => console.log(err))
  }
  return <List>
    {obj.dependencies.filter(e => !e.parent).map((e, i) => {
      const to = formAll.values.byId[e.to]
      return <ListItem key={i}>
        <ListItemText
          primary={to.name}
          secondary={arrowType.obj[e.type]}
        />
        <ListItemSecondaryAction>
          <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteArrow(e.id)}>
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    })}
  </List>
};



function TypeLink({ formName, formDialog, setStart, setEnd, setStartPlan, setEndPlan }) {
  const projectArr = useSelector(state => state.autocomplite?.[formFacilityProject].arr || [])
  const [valueProject, setValueProject] = useState(null)
  const [valueStart, setValueStart] = useState(null)
  const [valueEnd, setValueEnd] = useState(null)
  const idProjectLink = useSelector(state => state.form?.[formDialog]?.values?.obj.idProjectLink) || 0
  const idDateEndLink = useSelector(state => state.form?.[formDialog]?.values?.obj.idDateEndLink) || 0
  const idDateStartLink = useSelector(state => state.form?.[formDialog]?.values?.obj.idDateStartLink) || 0
  const dispatch = useDispatch()
  const [arrGant, setArrGant] = useState([])
  const [date, setDate] = useState({ start: '', end: '', startPlan: '', endPlan: '' })

  useEffect(() => {
    if (idProjectLink === 0 && projectArr.length === 0) return;
    setValueProject(projectArr.find(e => e.value === idProjectLink) || null)
  }, [idProjectLink, projectArr])

  useEffect(() => {
    if (!idDateStartLink && arrGant.length === 0) return;
    const index = arrGant.find(e => e.value === idDateStartLink) || null
    setValueStart(index)
    if (index) {
      setDate(old => ({ ...old, start: dateFormat(index.start), startPlan: dateFormat(index.startPlan) }))
    }
  }, [idDateStartLink, arrGant])

  useEffect(() => {
    if (!idDateEndLink && arrGant.length === 0) return;
    const index = arrGant.find(e => e.value === idDateEndLink) || null
    setValueEnd(index)
    if (index) {
      setDate(old => ({ ...old, end: dateFormat(index.end), endPlan: dateFormat(index.endPlan) }))
    }
  }, [idDateEndLink, arrGant])

  useEffect(() => {
    if (idProjectLink === 0) return;
    dispatch(fetchDispath({
      param: {
        id: idProjectLink
      },
      progress: true,
      request: getProjectGant,
    })).then(res => {
      const arr = res.get.filter(s => s.typeGant !== 'parent').map(e => ({ value: e.id, label: e.name, start: e.start, end: e.end, startPlan: e.startPlan, endPlan: e.endPlan }));
      setArrGant(arr)
    })
  }, [dispatch, idProjectLink])
  const handleChange = (e) => {
    dispatch(actions.formObjAdd(e.value, { field: 'obj', id: 'idProjectLink', name: formDialog }))
  }
  const handleChangeDateStart = (e) => {
    dispatch(actions.formObjAdd(e.value, { field: 'obj', id: 'idDateStartLink', name: formDialog }))
    setStart(e.start)
    setStartPlan(e.startPlan)

  }
  const handleChangeDateEnd = (e) => {
    dispatch(actions.formObjAdd(e.value, { field: 'obj', id: 'idDateEndLink', name: formDialog }))
    setEnd(e.end)
    setEndPlan(e.endPlan)
  }
  return <React.Fragment>
    <Grid item xs={12}>
      <AutoComplite
        arr={projectArr}
        action={handleChange}
        value={valueProject}
        formName={formName}
        palaseHolder='Проект'
      />
    </Grid>
    <Grid item xs={12}>
      <AutoComplite
        arr={arrGant}
        action={handleChangeDateStart}
        value={valueStart}
        formName={formName}
        palaseHolder='Начальная задача'
      />
    </Grid>
    <Grid item xs={12}><Typography>{`Начало ${date.start} план ${date.startPlan}`}</Typography></Grid>
    <Grid item xs={12}>
      <AutoComplite
        arr={arrGant}
        action={handleChangeDateEnd}
        value={valueEnd}
        formName={formName}
        palaseHolder='Окончательная задача'
      />
    </Grid>
    <Grid item xs={12}><Typography>{`Окончание ${date.end} план ${date.endPlan}`}</Typography></Grid>
  </React.Fragment>
}

function dateFormat(date) {
  return date?.split('-').reverse().join('.')
}