import React, { useState, useEffect } from 'react'
import {
  Dialog, Grid, DialogActions, DialogContent, Button,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux'
import AutoComplite from '../../components/Autocomplite'
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { fetchDispath } from '../../functions/fetch'
import { updGantMany } from '../../api/form-gant.api'
import { formArrayDeleteMany } from '../../reducers/form';

export default function GantDialogParent({ formName, idProject, reload, disabled }) {
  const [open, setOpen] = useState(false)
  return (
    <React.Fragment>
      <Button onClick={() => setOpen(true)} color='primary' disabled={disabled}>Переместить в группу</Button>
      {open && <DialogParent
        formName={formName}
        idProject={idProject}
        reload={reload}
        open={open}
        onClose={() => setOpen(false)}
      />}
    </React.Fragment>
  );
}


function DialogParent({ formName, idProject, reload, open, onClose }) {
  const dispatch = useDispatch()
  const selected = useSelector(state => state.form[formName]?.values?.selected || [])
  const byId = useSelector(state => state.form[formName]?.values.byId || {})
  const [parent, setParent] = useState(null)
  const [arrParent, setArrParent] = useState()
  const gantFlat = useSelector(state => state.gant.flat)
  useEffect(() => {
    setArrParent(gantFlat.filter(e => !!e.color).map((e, i) => {
      const obj = byId['id' + e.id]
      return {
        value: obj.idAPI,
        label: e.pos + ' ' + obj.name
      }
    }))
  }, [gantFlat, byId])
  const handleOk = () => {
    dispatch(fetchDispath({
      param: {
        id: idProject
      },
      body: {
        parent: parent ? parent.value : null,
        selected: selected
      },
      request: updGantMany,
    })).then(res => {
      reload()
      onClose()
      dispatch(formArrayDeleteMany(selected, { name: formName, field: 'selected' }))
    }).catch(err => console.log(err))
  }
  return (
    <React.Fragment>
      <Dialog
        open={open}
        maxWidth='sm'
        keepMounted
        fullWidth
        scroll="paper"
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        PaperProps={{ style: { height: 450 } }}
      >
        <DialogContent >
          <Grid container
            direction='column'
            spacing={1}
            alignItems="flex-start"
          >
            <Grid item container direction="row" spacing={2}>
              <Grid item xs={12}>
                <AutoComplite
                  arr={arrParent}
                  action={e => setParent(e)}
                  value={parent}
                  formName={formName}
                  palaseHolder='Родитель'
                  selectProps={{
                    isClearable: true
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container direction='row' alignItems="flex-end">
            <Grid item xs={3}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<CheckIcon />}
                onClick={handleOk}
                size='small'
              >Сохранить
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<CloseIcon />}
                onClick={() => onClose()}
                size='small'
              >Отменить
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}