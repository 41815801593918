import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux';
import { fetchDispath } from '../../functions/fetch'
import { getRemarksOneAPI } from '../../api/remarks.api'
import { arrLocation, statusRUS, status as statusArr } from './remarks.const'
import moment from 'moment';
import {
  Typography, Button, MenuItem, Menu, Box, IconButton,
  TextField, CircularProgress, ListItemIcon, Grid,
  List, ListItem, ListItemText, Dialog, DialogContent
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import SendIcon from '@material-ui/icons/Send';
import { green, red } from '@material-ui/core/colors';
import InfiniteScroll from "react-infinite-scroll-component"

import * as actions from '../../reducers/form';
import { allRemarkStatus, updRemarksOneAPI, allRemarkFiles, allRemarkInspector, allRemarkMessage } from '../../api/remarks.api'
import { loadFileAPI } from '../../reducers/materialLoad';
import { addFile } from '../../api/list'
import FileViewer from '../../components/fileViewer/FileViever';
export default function RemarksOne({ match, history }) {
  const [status, setStatus] = useState('in_execution')
  const [stateMain, setStateMain] = useState({
    dateCreate: '',
    reason: '',
    correctionDate: '',
    creator: '',
    responsible: '',
    location: '',
    remarkText: '',
    correctionText: '',
    idCreator: null,
    idResponsible: null,
    works: '',
  })
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchDispath({
      param: {
        id: match.params.idDoc,
      },
      progress: false,
      request: getRemarksOneAPI(match.params.idProject),
    })).then(res => {
      const arr = []
      arrLocation.forEach(loc => {
        const val = res.get?.location[loc.field]
        if (val && val !== '') {
          arr.push(`${loc.label}: ${val}`)
        }
      })
      setStateMain({
        dateCreate: moment(res.get.dateCreate).format('LL'),
        reason: res.get.reason,
        correctionDate: moment(res.get?.correctionDate).format('LL'),
        creator: `${res.get?.creator?.surname} ${res.get?.creator?.name[0]}. ${res.get?.creator?.patronymic[0]}.`,
        responsible: `${res.get?.responsible?.surname} ${res.get?.responsible?.name[0]}. ${res.get?.responsible?.patronymic[0]}.`,
        remarkText: res.get?.remarkText,
        location: arr.length > 0 ? arr.join(', ') : res.get?.location.place,
        correctionText: res.get?.correctionText || '',
        idCreator: res.get?.idCreator,
        idResponsible: res.get?.idResponsible,
        works: res.get.works || '',
      })
      setStatus(res.get.status)
    }).catch(err => console.log(err))
    return () => {
    }
  }, [dispatch, match.params.idDoc, match.params.idProject])

  return <div className='App-paper'>
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="stretch"
      spacing={2}
    >
      <Grid item xs={9}>
        <RemarkStatus
          status={status}
          setStatus={e => setStatus(e)}
          idProject={match.params.idProject}
          idDoc={match.params.idDoc}
        />
        <Typography>Дата создания: {stateMain.dateCreate}</Typography>
        <Typography>Основание: {stateMain.reason}</Typography>
        <Typography>Исправить к: {stateMain.correctionDate}</Typography>
        <Typography>Создатель: {stateMain.creator}</Typography>
        <Typography>Исполнитель: {stateMain.responsible}</Typography>
        <Typography>Расположение: {stateMain.location}</Typography>
        <Typography>Работа: {stateMain.works}</Typography>
        <Typography>Замечание: {stateMain.remarkText}</Typography>
        <CorrectionText
          idProject={match.params.idProject}
          idDoc={match.params.idDoc}
          correctionText={stateMain.correctionText}
          idResponsible={stateMain.idResponsible}
        />
        <RemarkMessages idProject={match.params.idProject} idDoc={match.params.idDoc} />

      </Grid>
      <Grid item xs={3}>
        <RemarkInspectors idProject={match.params.idProject} idDoc={match.params.idDoc} />
        <FileList
          idProject={match.params.idProject}
          idDoc={match.params.idDoc}
          idCreator={stateMain.idCreator}
          idResponsible={stateMain.idResponsible}
        />
      </Grid>
    </Grid>
    <Button color='primary' component="span" onClick={() => history.goBack()}>Назад</Button>
  </div>
}

function CorrectionText({ idProject, idDoc, correctionText, idResponsible }) {
  const [correction, setCorrection] = useState('')
  useEffect(() => {
    setCorrection(correctionText)
  }, [correctionText])
  const handleChange = (event) => {
    setCorrection(event.target.value);
  };
  const dispatch = useDispatch()
  const handleSave = () => {
    const url = updRemarksOneAPI(idProject)
    dispatch(actions.formSubmitAPI({
      props: {
        correctionText: correction
      },
      url: url.path({ id: idDoc }),
      method: url.type,
      endFunc: () => {

      }
    }))
  }
  const useId = Number(localStorage.getItem('id'))
  if (idResponsible !== useId) {
    return <Typography>Комментарии к исправлению: {correctionText}</Typography>
  }
  return <div>
    <Typography variant='h6'>Исправление</Typography>
    <TextField
      label="Комментарии к исправлению"
      fullWidth
      multiline
      rows={5}
      value={correction}
      onChange={handleChange}
      variant="outlined"
    />
    <Button color='primary' component="span" onClick={handleSave}>Сохранить</Button>
  </div>
}
const LIMIT = 20

function RemarkMessages({ idProject, idDoc }) {
  const dispatch = useDispatch()
  const [msg, setMsg] = useState('')
  const [messages, setMessages] = useState([])
  const [offset, setOffset] = useState(0)
  const handleChange = (event) => {
    setMsg(event.target.value);
  };
  useEffect(() => {
    dispatch(fetchDispath({
      param: {
        idProject,
        id: idDoc
      },
      querty: {
        offset: 0,
        limit: LIMIT,
      },
      progress: false,
      request: allRemarkMessage.get,
    })).then(res => {
      setMessages(res.get)
    }).catch(err => console.log(err))
    return () => {
    }
  }, [dispatch, idDoc, idProject])
  const handleSend = () => {
    const url = allRemarkMessage.post
    dispatch(actions.formSubmitAPI({
      props: {
        message: msg
      },
      url: url.path({ id: idDoc, idProject }),
      method: url.type,
      endFunc: (res) => {
        setMessages((old) => [res.get, ...old])
        setOffset(offset + 1)
      }
    }))
  }
  const [hasMore, setHasMore] = useState(true)
  const [hasLoading, setHasLoading] = useState(false)
  const fetchMoreData = () => {
    if (hasLoading) return;
    setHasLoading(true)
    dispatch(fetchDispath({
      param: {
        idProject,
        id: idDoc
      },
      querty: {
        offset: offset + LIMIT,
        limit: LIMIT,
      },
      progress: false,
      request: allRemarkMessage.get,
    })).then(res => {
      setMessages((old) => [...old, ...res.get])
      if (res.get.length < LIMIT) setHasMore(false)
      setOffset(offset + LIMIT)
      setHasLoading(false)
    }).catch(err => console.log(err))
  }
  return <div>
    <Typography variant='h6'>Комментарии</Typography>
    <div style={{
      height: '40vh',
      backgroundColor: '#fff',
      width: '100%',
      display: 'flex',
      justify: 'flex-start',
      flexDirection: "column"
    }}
    >
      <div
        id="scrollableDiv"
        style={{
          backgroundColor: '#dbf6ff',
          height: '100%',//'calc(100% - 56px - 72px)',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column-reverse',
          scrollBehavior: 'smooth',
          overflowAnchor: 'none'
        }}
      >
        <InfiniteScroll
          dataLength={messages.length}
          next={fetchMoreData}
          style={{ display: 'flex', flexDirection: 'column-reverse' }}
          inverse={true} //
          hasMore={hasMore}
          loader={<h4>Loading...</h4>}
          scrollableTarget="scrollableDiv"
          scrollThreshold={200}
        >
          {messages.map(e => {
            return <div
              key={e.id + Math.random()}
              style={{ marginBottom: 4, transform: 'translateZ(0)' }}
            >
              <ChipMessage message={e} />
            </div>
          })}
        </InfiniteScroll>
      </div>
      <div style={{ width: '100%', display: 'flex' }}>
        <TextField
          value={msg}
          onChange={handleChange}
          variant="outlined"
          style={{ flex: 1 }}
        />
        <IconButton onClick={handleSend}><SendIcon /></IconButton>
      </div>
    </div>
  </div>
}

function ChipMessage({ message }) {
  const ourMsg = Number(localStorage.getItem('id')) === message.idUser
  return <Box sx={{
    width: 300,
    padding: 2,
    borderRadius: 2,
    marginLeft: ourMsg ? 'calc(95% - 300px)' : '2%',
    backgroundColor: ourMsg ? '#c3d1ff' : '#fff',
    wordWrap: 'break-word',
    textAlign: 'left',
    position: 'relative'
  }}>
    <div style={{ fontWeight: 'bold' }}>
      {`${message?.surname} ${message?.name[0] || ''}. ${message?.patronymic[0] || ''}.`}
    </div>
    <div>
      {message.message}
    </div>
    <div style={{ left: 169, position: 'relative', color: '#999', display: 'inline-block' }}>
      {moment(message.dateCreate).format('DD.MM.YYYY HH:mm')}
    </div>
  </Box>
}
function RemarkInspectors({ idProject, idDoc }) {
  const dispatch = useDispatch()
  const [insp, setInsp] = useState([])
  const [visa, setVisa] = useState({ access: false, visa: false })
  const load = useCallback(() => {
    dispatch(fetchDispath({
      param: {
        idProject,
        id: idDoc
      },
      progress: false,
      request: allRemarkInspector.get,
    })).then(res => {
      const useId = localStorage.getItem('id')
      const findUser = res.get.find(e => e.id === Number(useId))
      if (findUser) {
        setVisa({ access: true, visa: findUser.isChecked })
      }
      setInsp(res.get.map(e => ({ ...e, shortName: `${e.surname} ${e.name[0] || ''}. ${e.patronymic[0] || ''}.` })))
    }).catch(err => console.log(err))
  }, [dispatch, idDoc, idProject])
  useEffect(() => {
    load()
  }, [dispatch, load])
  const handleClick = () => {
    const url = allRemarkInspector.put
    dispatch(actions.formSubmitAPI({
      props: {
        checked: !visa.visa
      },
      url: url.path({ idProject, id: idDoc }),
      method: url.type,
      endFunc: () => {
        load()
      }
    }))
  }
  return <div>
    <Typography variant='h6'>Проверяющие</Typography>
    {visa.access && <Button color='primary' onClick={handleClick}>{visa.visa ? 'Снять подпись' : 'Завизировать'}</Button>}
    <List component="nav" >
      {insp.map(e => {
        return <ListItem key={e.id}>
          <ListItemIcon>
            {e.isChecked ? <CheckIcon style={{ color: green[500] }} /> : <ClearIcon style={{ color: red[500] }} />}
          </ListItemIcon>
          <ListItemText primary={e.shortName} />
        </ListItem>
      })}
    </List>
  </div>
}
function RemarkStatus({ status, setStatus, idProject, idDoc }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch()
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (key) => {
    dispatch(actions.formSubmitAPI({
      props: {
        status: key
      },
      url: allRemarkStatus.post.path({ idProject: idProject, id: idDoc }),
      method: allRemarkStatus.post.type,
      endFunc: () => {
        setAnchorEl(null);
        setStatus(key)
      }
    }))
  };
  const currentStatus = statusRUS[status]
  return <div>Статус:
    <Button
      aria-controls="customized-menu"
      aria-haspopup="true"
      variant="outlined"
      size="small"
      onClick={handleClick}
      style={{ color: currentStatus.color }}
    >
      <Typography>{currentStatus.label}</Typography>
    </Button>
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      id="customized-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={() => { setAnchorEl(null); }}
    >
      {statusArr.map(key => {
        return <MenuItem key={key} onClick={() => handleClose(key)} style={{ color: statusRUS[key].color }}>{statusRUS[key].label}</MenuItem>
      })}
    </Menu>
  </div>
}

function FileList({ idDoc, idProject, idCreator, idResponsible }) {
  const dispatch = useDispatch()
  const [filesRemark, setFilesRemark] = useState([])
  const [filesCorrect, setFilesCorrect] = useState([])
  const [dialogOpen, setDialogOpen] = useState(false)
  const [file, setFile] = useState({ filePath: '', type: '', fileName: '' })
  const useId = Number(localStorage.getItem('id'))
  const load = useCallback(() => {
    dispatch(fetchDispath({
      param: {
        idProject,
        id: idDoc
      },
      progress: false,
      request: allRemarkFiles.get,
    })).then(res => {
      setFilesRemark(res.get.filter(e => e.isRemarkFile))
      setFilesCorrect(res.get.filter(e => !e.isRemarkFile))
    }).catch(err => console.log(err))
  }, [dispatch, idDoc, idProject])
  useEffect(() => {
    load()
    return () => {
    }
  }, [dispatch, load])
  const handleClick = (obj) => {
    if (obj.name === file.fileName && dialogOpen) {
      setDialogOpen(false);
      return;
    }
    setFile({ type: (obj.type !== 'xls') ? obj.type : 'xlsx', fileName: obj.name })
    setDialogOpen(true);
    return;
  }
  return <div>
    <Typography variant='h6'>Файлы</Typography>
    <hr className='App-divider' />
    <Typography variant='body1'>Файлы замечания</Typography>
    {idCreator === useId && <ButtonLoadFile
      isRemarkFile={true}
      buttonText='Загрузить'
      idProject={idProject}
      idDoc={idDoc}
      isLoaded={load}
    />}
    <List component="nav" >
      {filesRemark.map(e => {
        return <ListItem key={e.id} button onClick={() => handleClick(e)}>
          <ListItemText primary={e.originalName} />
        </ListItem>
      })}
    </List>
    <hr className='App-divider' />
    <Typography variant='body1'>Файлы исправления</Typography>
    {idResponsible === useId && <ButtonLoadFile
      isRemarkFile={false}
      buttonText='Загрузить'
      idProject={idProject}
      idDoc={idDoc}
      isLoaded={load}
    />}
    <List component="nav" >
      {filesCorrect.map(e => {
        return <ListItem key={e.id} button onClick={() => handleClick(e)}>
          <ListItemText primary={e.originalName} />
        </ListItem>
      })}
    </List>
    <Dialog
      open={dialogOpen}
      maxWidth={'lg'}
      keepMounted
      fullWidth
      scroll="paper"
      onClose={() => setDialogOpen(false)}
    >
      <DialogContent >
        {dialogOpen && <FileViewer
          fileType={file.type}
          fileName={file.fileName}
        />}
      </DialogContent>
    </Dialog>
  </div>
}

function ButtonLoadFile({ isRemarkFile, buttonText, idProject, idDoc, isLoaded }) {
  const dispatch = useDispatch()
  const [loadingFile, setLoadingFile] = useState(false)
  const _handleImageChange = (e) => {
    e.preventDefault();
    if (typeof e.target.files[0] === 'undefined' || e.target.files[0].length === 0) return
    setLoadingFile(true)
    dispatch(loadFileAPI({ file: e.target.files[0], path: addFile.path }))
      .then(res => {
        if (res.success) {
          const url = allRemarkFiles.post
          dispatch(actions.formSubmitAPI({
            props: {
              idFile: res.id,
              isRemarkFile: isRemarkFile,
            },
            url: url.path({ idProject, id: idDoc }),
            method: url.type,
            endFunc: () => {
              isLoaded()
            }
          }))
        }
        setLoadingFile(false)
      })
      .catch()
  }
  return <React.Fragment>
    <Button disabled={loadingFile} component="label" color='primary'>
      {loadingFile && <CircularProgress size={25} />}
      <span>{buttonText}</span>
      <input
        type="file"
        style={{ display: "none" }}
        onChange={(e) => _handleImageChange(e)}
      />
    </Button>
  </React.Fragment>
}