import { actionCreatorObjMeta, actionCreatorObj } from '../functions/redux'
const MAIN = 'history/'
const ADD_TO_HISTORY_MATERIAL = MAIN + 'add_material'
const ADD_TO_HISTORY_WORK = MAIN + 'add_work'
const SELECT_HISTORY = MAIN + 'select'
const OPEN_HISTORY = MAIN + 'open'
const CLEAR_HISTORY = MAIN + 'clear'
const DEL_HISTORY = MAIN + 'del'
export const addToHistoryM = actionCreatorObjMeta(ADD_TO_HISTORY_MATERIAL)
export const addToHistoryW = actionCreatorObjMeta(ADD_TO_HISTORY_WORK)
export const openHistory = actionCreatorObj(OPEN_HISTORY)
export const selectHistory = actionCreatorObj(SELECT_HISTORY)
export const delHistory = actionCreatorObjMeta(DEL_HISTORY)
export const clearHistory = actionCreatorObj(CLEAR_HISTORY)

export const initialState = {
  default: {
    byIdM: {},
    allIdsM: [],
    byIdW: {},
    allIdsW: [],
    idM: 0,
    idW: 0
  },
  open: false,
  click: null
}
export default function historyRouter(state = initialState, action) {
  switch (action.type) {
    case ADD_TO_HISTORY_MATERIAL: return add_historyM(state, action.payload, action.meta)
    case ADD_TO_HISTORY_WORK: return add_historyW(state, action.payload, action.meta)
    case OPEN_HISTORY: return { ...state, open: action.payload }
    case SELECT_HISTORY: return { ...state, click: action.payload }
    case CLEAR_HISTORY: return { ...state, [action.payload]: initialState.default }
    case DEL_HISTORY: return delHistoryWM(state, action.payload, action.meta)
    default: return state;
  }
}

function delHistoryWM(state,id,meta){
  if(meta.type==='material'){
    const { [id]: value, ...second } = state[meta.idProject].byIdM
    return {
      ...state,
      [meta.idProject]: {
        ...state[meta.idProject],
        byIdM: second,
        allIdsM: state[meta.idProject].allIdsM.filter(key => key !== id),
      }
    }
  }
  const { [id]: value, ...second } = state[meta.idProject].byIdW
  return {
    ...state,
    [meta.idProject]: {
      ...state[meta.idProject],
      byIdW: second,
      allIdsW: state[meta.idProject].allIdsW.filter(key => key !== id),
    }
  }
}
function add_historyM(state, arr, meta) {
  let byId = {}
  let arrIds = []
  let ids = (state[meta.idProject] ? state[meta.idProject].idM : 0) + 1
  const { byIdM = {} } = state[meta.idProject] || { byIdM: {} }
  const arrById = Object.values(byIdM)
  arr.forEach(el => {
    if (arrById.findIndex(item => item.id === el.id) === -1) {
      byId['id' + ids] = el
      arrIds.push('id' + ids)
      ids += 1
    }

  });
  if (!state[meta.idProject]) {
    return ({
      ...state,
      [meta.idProject]: {
        byIdM: byId,
        allIdsM: arrIds,
        idM: ids,
        byIdW: {},
        allIdsW: [],
        idW: 0,
      }
    })
  }
  return ({
    ...state,
    [meta.idProject]: {
      ...state[meta.idProject],
      byIdM: {
        ...state[meta.idProject].byIdM,
        ...byId
      },
      allIdsM: [...state[meta.idProject].allIdsM, ...arrIds],
      idM: ids
    }
  })
}
function add_historyW(state, arr, meta) {
  let byId = {}
  let arrIds = []
  let ids = (state[meta.idProject] ? state[meta.idProject].idW : 0) + 1
  const { byIdW = {} } = state[meta.idProject] || { byIdW: {} }
  const arrById = Object.values(byIdW)
  arr.forEach(el => {
    if (arrById.findIndex(item => item.id === el.id) === -1) {
      byId['id' + ids] = el
      arrIds.push('id' + ids)
      ids += 1
    }
  });
  if (!state[meta.idProject]) {
    return ({
      ...state,
      [meta.idProject]: {
        byIdM: {},
        allIdsM: [],
        byIdW: byId,
        allIdsW: arrIds,
        idM: 0,
        idW: ids
      }
    })
  }
  return ({
    ...state,
    [meta.idProject]: {
      ...state[meta.idProject],
      byIdW: {
        ...state[meta.idProject].byIdW,
        ...byId
      },
      allIdsW: [...state[meta.idProject].allIdsW, ...arrIds],
      idM: ids
    }
  })
}