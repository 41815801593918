import React from 'react';
import { API_BO } from '../../conf'
import { Button, Grid } from '@material-ui/core'
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

export const butt = [
  {
    primary: "Объекты",
    link: '/facility/our',
  },
  {
    primary: "Справочники",
    link: '/lists',
  },
  {
    primary: "Склад",
    link: '/storagemenu',
  },
  {
    primary: "Тендер",
    link: '/tender',
  },
  {
    primary: "Другое",
    link: '/multi',
  },
]
const useStyles = makeStyles({
  root: {
    textTransform: 'capitalize',
    '&:hover': {
      color: '#000'
    }
  },
});
export default function ListButton() {
  const classes = useStyles();
  return (<>
    {butt.map(e => {
      const toLink = React.forwardRef((props, ref) => (<Link ref={ref} to={API_BO + e.link} {...props} />))
      return <Grid item key={e.primary}>
        <Button
          color='inherit'
          size='small'
          component={toLink}
          className={classes.root}
        >{e.primary}
        </Button>
      </Grid>
    })}
  </>)
}