import React, { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux'
import moment from 'moment'
import 'moment/locale/ru';
import { formViewProps, formName, } from '../gant.const'

// arrows ----------------------------------------------
export default function ArrowAll({ formName, zoom, yPadding, sizeDay, sizeWeek, sizeMonth }) {
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { byId = {}, arrFilter } = form.values
  const tree = useSelector(state => state.gant)
  const allIds = tree.flat.map(e => 'id' + e.id)
  const arrFiltered = allIds.filter(key => !arrFilter.includes(byId[key].parent))
  const arrowCrit = useSelector(state => state.gant.crit.arrow)

  const [size, setSize] = useState(0)
  const [diff, setDiff] = useState('')
  const mouseEnterRef = useRef(null);
  useEffect(() => {
    switch (zoom) {
      case 'day': {
        setSize(sizeDay);
        setDiff('days')
        break;
      }
      case 'week': {
        setSize(sizeWeek);
        setDiff('weeks')
        break;
      }
      case 'month': {
        setSize(sizeMonth * 12 / 365)
        setDiff('days')
        break;
      }
      default: break;
    }
  }, [zoom, sizeDay, sizeWeek, sizeMonth])
  return <g>
    {arrFiltered.map((key, i) => {
      const obj = byId[key]
      if (obj.typeGant === 'parent' && !arrFilter.includes(obj.idAPI)) return null
      return obj.dependencies.map(arrObj => {
        const toTask = byId[arrObj.to]
        if (toTask.typeGant === 'parent' && !arrFilter.includes(toTask.idAPI)) return null
        if (arrFilter.includes(toTask.parent)) return null
        const toIndex = arrFiltered.findIndex(s => s === arrObj.to)
        const selectedCrit = arrowCrit.includes(arrObj.id)
        return <Arrow
          id={'arrow_form' + key + ' to' + arrObj.id}
          key={'arrow_form' + key + ' to' + arrObj.id}
          from_task={{ start: obj.start, end: obj.end, index: i, typeGant: obj.typeGant }}
          to_task={{ start: toTask.start, end: toTask.end, index: toIndex, typeGant: toTask.typeGant }}
          type={arrObj.type}
          sizeDay={size}
          diff={diff}
          mouseEnterRef={mouseEnterRef}
          isCrit={selectedCrit}
          yPadding={yPadding}
        />
      })
    })}
    <use ref={mouseEnterRef} />
  </g>
}
const useStylesArrow = makeStyles(theme => ({
  pathArrow: {
    fill: 'none',
    strokeWidth: "3",
  },
}));
function Arrow({ from_task, to_task, type, sizeDay, diff, id, mouseEnterRef, isCrit, yPadding }) {
  const startDiag = useSelector(state => state.form[formViewProps]?.values.startDiag) || 1810
  const start = useSelector(state => state.form[formName]?.values.start) || moment()
  const classes = useStylesArrow()
  const [pathLine, setPathLine] = useState('')
  const [color, setColor] = useState('orange')
  useEffect(() => {
    if (isCrit) setColor('red')
    else setColor('orange')
  }, [isCrit])
  useEffect(() => {
    const getStart = (task) => {
      const startRectTo = task.start.diff(start, diff, true);
      const diffStart = task.typeGant === 'milestone' ? sizeDay / 2 : 0
      return {
        x: startDiag + Math.round(startRectTo * sizeDay - diffStart),
        y: yPadding * (task.index + 0.375)
      }
    }
    const getEnd = (task) => {
      const endRectTo = moment(task.end).add(1, 'd').diff(start, diff, true)
      const diffEnd = task.typeGant === 'milestone' ? sizeDay / 2 : 0
      return {
        x: startDiag + Math.round(endRectTo * sizeDay - diffEnd),
        y: yPadding * (task.index + 0.375)
      }
    }
    const finishStart = () => {
      const startPoint = getEnd(from_task)
      const endPoint = getStart(to_task)
      if (startPoint.x + sizeDay / 2 < endPoint.x - sizeDay / 2) {
        return `M ${startPoint.x} ${startPoint.y} h ${sizeDay / 2} V ${endPoint.y} H ${endPoint.x}  m -5 -5 l 5 5 l -5 5`
      }
      return `M ${startPoint.x} ${startPoint.y} h ${sizeDay / 2} 
        v ${(endPoint.y - startPoint.y) / 2} 
        H ${endPoint.x - sizeDay / 2} 
        V ${endPoint.y} H ${endPoint.x}  m -5 -5 l 5 5 l -5 5`
    }
    const startStart = () => {
      const startPoint = getStart(from_task)
      const endPoint = getStart(to_task)
      if (startPoint.x + sizeDay / 2 < endPoint.x - sizeDay / 2) {
        return `M ${startPoint.x} ${startPoint.y} h ${-sizeDay / 2} V ${endPoint.y} H ${endPoint.x}  m -5 -5 l 5 5 l -5 5`
      }
      return `M ${startPoint.x} ${startPoint.y} h ${-(startPoint.x - endPoint.x + sizeDay / 2)} 
        V ${endPoint.y} 
        h ${sizeDay / 2} 
        m -5 -5 l 5 5 l -5 5`
    }
    const finishFinish = () => {
      const startPoint = getEnd(from_task)
      const endPoint = getEnd(to_task)
      if (startPoint.x + sizeDay / 2 < endPoint.x - sizeDay / 2) {
        return `M ${startPoint.x} ${startPoint.y} H ${endPoint.x + sizeDay / 2} V ${endPoint.y} H ${endPoint.x}  m 5 -5 l -5 5 l 5 5`
      }
      return `M ${startPoint.x} ${startPoint.y} h ${sizeDay / 2} 
        v ${endPoint.y - startPoint.y} 
        H ${endPoint.x} 
        m 5 -5 l -5 5 l 5 5`
    }
    const startFinish = () => {
      const startPoint = getStart(from_task)
      const endPoint = getEnd(to_task)
      if (startPoint.x + sizeDay / 2 < endPoint.x - sizeDay / 2) {
        return `M ${startPoint.x} ${startPoint.y} h ${-sizeDay / 2} v ${yPadding / 2} H ${endPoint.x + sizeDay / 2} V ${endPoint.y} H ${endPoint.x} m 5 -5 l -5 5 l 5 5`
      }
      return `M ${startPoint.x} ${startPoint.y} h ${-sizeDay / 2} 
        v ${yPadding / 2} 
        H ${endPoint.x + sizeDay / 2} 
        V ${endPoint.y} H ${endPoint.x}  m 5 -5 l -5 5 l 5 5`
    }
    let d = ''
    switch (type) {
      case 'fs':
        d = finishStart()
        break;
      case 'ss':
        d = startStart()
        break;
      case 'ff':
        d = finishFinish()
        break;
      case 'sf':
        d = startFinish()
        break;
      default:
        break;
    }
    setPathLine(d)

  }, [startDiag, diff, from_task, sizeDay, start, to_task, type, yPadding])
  return <g>
    <path
      id={id + '_path'}
      onMouseEnter={() => {
        mouseEnterRef.current.setAttributeNS(null, 'href', '#' + id + '_path')
        setColor('red')
      }}
      onMouseLeave={() => {
        setTimeout(() => {
          mouseEnterRef.current.setAttributeNS(null, 'href', '')
          if (isCrit) setColor('red')
          else setColor('orange')
        }, 2 * 1000)
      }}
      className={classes.pathArrow}
      d={pathLine}
      stroke={color}
    />
  </g>
}