import template from "./template_crud";

// tender-our
const tendersOur = '/tenders_our'
export const getTenderOur = template.getList(tendersOur);
export const addTenderOur = template.addOne(tendersOur)
export const getTenderOurById = template.getById(tendersOur)
export const updTenderOur = template.updById(tendersOur)
export const deleteTenderOur = template.deleteById(tendersOur)
export const setTenderOurBid = {
    path: (obj) => `${tendersOur}/${obj.id}/bid`,
    type: 'PUT'
}
// estimate 
export const addTenderOurLot = {
    path: (obj) => `${tendersOur}/${obj.id}/estimate`,
    type: 'POST'
}
export const setTenderStatus = {
    path: (obj) => `${tendersOur}/${obj.id}/status`,
    type: 'PUT'
}
export const delTenderOurLot = {
    path: (obj) => `${tendersOur}/${obj.id}/estimate/${obj.idProject}`,
    type: 'DELETE'
}
// contacts
export const getTenderOurContact = {
    path: (obj) => `${tendersOur}/${obj.id}/contacts`,
    type: 'GET'
}
export const addTenderOurContact = {
    path: (obj) => `${tendersOur}/${obj.id}/contacts`,
    type: 'POST'
}
export const updTenderOurContact = {
    path: (obj) => `${tendersOur}/${obj.id}/contacts/${obj.idContact}`,
    type: 'PUT'
}
export const delTenderOurContact = {
    path: (obj) => `${tendersOur}/${obj.id}/contacts/${obj.idContact}`,
    type: 'DELETE'
}
// tender
const tenders = '/tenders'
const tendersOpen = tenders + '/open'
const tendersClose = tenders + '/close'
const tendersMy = tenders + '/my-requests'

export const getOpenTendersConfirmStatus = template.getList(tendersOpen);
export const getOneTenders = template.getById(tendersOpen)
export const setTenderOpenBid = {
    path: (obj) => `${tendersOpen}/${obj.id}/request`,
    type: 'POST'
}
export const getCloseTender = template.getById(tendersClose)
export const setTenderCloseBid = {
    path: (obj) => `${tendersClose}/${obj.id}/request`,
    type: 'POST'
}
export const getMyRequestTender = template.getList(tendersMy);
export const getMyTenderById = template.getById(tendersMy)
export const delMyTenderRequest = template.deleteById(tendersMy)
export const updTendersEstimate = {
    path: (obj) => `${tendersMy}/${obj.id}/estimate/${obj.idEstimate}`,
    type: 'PUT'
}
export const createObjectFromTender = {
    path: (obj) => `${tenders}/${obj.id}/create_object`,
    type: 'POST'
}