import React, { useState } from 'react'
import { Button, IconButton, Tooltip,
  Popover, ClickAwayListener, MenuList, MenuItem
 } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux'
import ZoomOutOutlinedIcon from '@material-ui/icons/ZoomOutOutlined';
import ZoomInOutlinedIcon from '@material-ui/icons/ZoomInOutlined';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { formViewProps } from '../../gant/gant.const'
import * as actions from '../../../reducers/form';

const arrZoom = [
  { value: 'day', label: 'День' },
  { value: 'week', label: 'Неделя' },
  { value: 'month', label: 'Месяц' },
]

export default function ContractorGantHeader({ zoom, setZoom, setPercent, setFixTable, COLUMNS_ARR }) {
  var ContainerElements = ["svg", "g"];
  var RelevantStyles = {
    "rect": ["fill", "stroke", "stroke-width", "visibility"],
    "path": ["fill", "stroke", "stroke-width", "visibility"],
    "circle": ["fill", "stroke", "stroke-width", "visibility"],
    "line": ["stroke", "stroke-width", "visibility"],
    "text": ["fill", "font-size", "text-anchor"],
    "polygon": ["stroke", "fill", "visibility"]
  };
  function read_Element(ParentNode, OrigData) {
    var Children = ParentNode.childNodes;
    var OrigChildDat = OrigData.childNodes;

    for (var cd = 0; cd < Children.length; cd++) {
      var Child = Children[cd];

      var TagName = Child.tagName;
      if (ContainerElements.indexOf(TagName) !== -1) {
        read_Element(Child, OrigChildDat[cd])
      } else if (TagName in RelevantStyles) {
        var StyleDef = window.getComputedStyle(OrigChildDat[cd]);

        var StyleString = "";
        for (var st = 0; st < RelevantStyles[TagName].length; st++) {
          StyleString += RelevantStyles[TagName][st] + ":" + StyleDef.getPropertyValue(RelevantStyles[TagName][st]) + "; ";
        }
        Child.setAttribute("style", StyleString);
      }
    }
  }
  const printP = () => {
    const svg = document.getElementById('svg-full')
    var oDOM = svg.cloneNode(true)
    read_Element(oDOM, svg)
    const svgString = new XMLSerializer().serializeToString(oDOM);
    const file = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });
    if (window.navigator.msSaveOrOpenBlob) // IE10+
      window.navigator.msSaveOrOpenBlob(file, 'gant.svg');
    else {
      var a = document.createElement("a"),
        url = URL.createObjectURL(file);
      a.href = url;
      a.download = 'gant.svg';
      document.body.appendChild(a);
      a.click();
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  }
  return <>
    <ButtonDivider />
    <ButtonSimple title="Уменьшить размер" ariaLabel="down size" Icon={ZoomOutOutlinedIcon} handleClick={() => setPercent(old => old + 5)} />
    <ButtonSimple title="Увеличить размер" ariaLabel="up size" Icon={ZoomInOutlinedIcon} handleClick={() => setPercent(old => old - 5)} />
    <ButtonDivider />
    {arrZoom.map(e => <Button key={e.value} color={zoom === e.value ? 'primary' : 'secondary'} onClick={() => setZoom(e.value)}>{e.label}</Button>)}
    <ButtonDivider />
    <ButtonSimple title="Фиксировать таблицу" ariaLabel="fix table" Icon={AttachFileIcon} handleClick={() => setFixTable(e => !e)} />
    <ButtonHideVisiblePlan />
    <ButtonDivider />
    <ButtonSimpleText title="Сохранить как SVG" ariaLabel="save as svg" text='SVG' handleClick={printP} />
    <ButtonDivider />
    <GantHideColumn COLUMNS_ARR={COLUMNS_ARR}/>
  </>
}

function ButtonSimpleText({ title, ariaLabel, handleClick, text }) {
  return <Tooltip title={title} aria-label={ariaLabel}>
    <Button color='primary' onClick={handleClick}>{text}</Button>
  </Tooltip>
}

function ButtonSimple({ title, ariaLabel, handleClick, Icon }) {
  return <Tooltip title={title} aria-label={ariaLabel}>
    <IconButton color='primary' onClick={handleClick}><Icon /></IconButton>
  </Tooltip>
}

function ButtonHideVisiblePlan() {
  const dispatch = useDispatch()
  const planVisible = useSelector(state => state.form[formViewProps]?.values?.planVisible) || true
  return <Tooltip title="Скрыть план" aria-label="add">
    <Button
      color={planVisible ? 'primary' : 'secondary'}
      onClick={() => {
        dispatch(actions.formChange(!planVisible, { field: 'planVisible', name: formViewProps }))
      }}
    >План</Button>
  </Tooltip>
}

function ButtonDivider() {
  const style = {
    borderLeft: '2px solid #9391a22e',
    height: '32px',
    verticalAlign: 'middle',
    display: 'inline-block',
  }
  return <div style={style} />
}

function GantHideColumn({ COLUMNS_ARR }) {
  const dispatch = useDispatch()
  const [autoOpen, setAutoOpen] = useState(false)
  const anchorRef = React.useRef(null);
  const form2 = useSelector(state => state.form[formViewProps] || state.form.default)
  const { columnsObj = {}, startDiag = 0 } = form2.values
  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setAutoOpen(false);
    }
  }
  const handleAutoClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setAutoOpen(false);
  };
  const handleHide = (column)=>{
    if(column.hide){
      dispatch(actions.formObjChangeObj({
        hide: false,
      }, { field: 'columnsObj', id: column.value, name: formViewProps }))
      dispatch(actions.formChange(startDiag +  column.width, { field: 'startDiag', name: formViewProps }))
      dispatch(actions.formArrayDelete(column.value, { field: 'columnsArrHide', name: formViewProps }))
    }
    else{
      dispatch(actions.formObjChangeObj({
        hide: true,
      }, { field: 'columnsObj', id: column.value, name: formViewProps }))
      dispatch(actions.formChange(startDiag -  column.width, { field: 'startDiag', name: formViewProps }))
      dispatch(actions.formArrayAdd(column.value, { field: 'columnsArrHide', name: formViewProps }))
    }
  }
  return <React.Fragment>
    <Button color='primary' onClick={() => { setAutoOpen((prevOpen) => !prevOpen); }} ref={anchorRef}>Скрыть колонки</Button>
    <Popover
      open={autoOpen}
      anchorEl={anchorRef.current}
      role={undefined}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      onClose={handleAutoClose}
    >
      <div className='App-paper'>
        <ClickAwayListener onClickAway={handleAutoClose}>
          <MenuList autoFocusItem={autoOpen} id="menu-list-grow" onKeyDown={handleListKeyDown}>
          {COLUMNS_ARR.map(key=>{
            const column = columnsObj[key]
            if(!column) return null
            return <MenuItem key={key} onClick={(e) => {  handleHide(column) }} selected={column.hide}>{column.name}</MenuItem>
          })}
          </MenuList>
        </ClickAwayListener>
      </div>
    </Popover>
  </React.Fragment>
}