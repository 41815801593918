import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux'

import moment from 'moment'
import 'moment/locale/ru';
import { formViewProps, formName } from '../gant.const'

const useStyles = makeStyles(theme => ({
  sticky: {
    position: 'sticky',
    top: 0,
  },
}));
// шапка с датами ---------------------------------------
export default function DateLine({ zoom, sizeDay, sizeWeek, sizeMonth, heightDateLine }) {
  const start = useSelector(formSelector(formName, 'start')) || moment()
  const end = useSelector(formSelector(formName, 'end')) || moment()
  const [arr, setArr] = useState({})
  const [countDay, setCountDay] = useState(10)
  const [daysToWeekend, setDaysToWeekend] = useState(0)
  const classes = useStyles()
  useEffect(() => {
    const countDay = end.diff(start, 'days') + 10
    let date = moment(start)
    let res = {
      [date.format('MMMM YYYY')]: [{ d: date.format('D'), day: date.day(), m: date.format('MMM') }]
    }
    for (let i = 0; i < countDay; i++) {
      date.add(1, 'd')
      if (res[date.format('MMMM YYYY')]) {
        res[date.format('MMMM YYYY')].push({ d: date.format('D'), day: date.day(), m: date.format('MMM') })
      }
      else {
        res[date.format('MMMM YYYY')] = [{ d: date.format('D'), day: date.day(), m: date.format('MMM') }]
      }
    }
    setArr(res)
    setCountDay(countDay)
    const startWeekend = moment(start)
    startWeekend.day(7)
    setDaysToWeekend((startWeekend.diff(start, 'days')) % 7 - 1)
  }, [start, end])
  const startDiag = useSelector(formSelector(formViewProps, 'startDiag')) || 1810
  let gantComponent = null
  switch (zoom) {
    case 'day': {
      gantComponent = <DateLineDay
        arr={arr}
        countDay={countDay}
        daysToWeekend={daysToWeekend}
        heightDateLine={heightDateLine}
        startDiag={startDiag}
        sizeDay={sizeDay}
      />
      break;
    }
    case 'week': {
      gantComponent = <DateLineWeek
        arr={arr}
        heightDateLine={heightDateLine}
        startDiag={startDiag}
        sizeWeek={sizeWeek}
        sizeDay={sizeDay}
      />
      break;
    }
    case 'month': {
      gantComponent = <DateLineMonth
        arr={arr}
        heightDateLine={heightDateLine}
        sizeDay={sizeDay}
        sizeMonth={sizeMonth}
      />
      break;
    }
    default: { break; }
  }
  return <g id="sticky-rect" className={classes.sticky}  >
    {gantComponent}
  </g>
}
function DateLineMonth({ arr, heightDateLine, sizeDay, sizeMonth }) {
  const startDiag = useSelector(formSelector(formViewProps, 'startDiag')) || 1810
  let offset = startDiag
  const sizeD = sizeMonth * 12 / 365
  const yearArr = {}
  Object.keys(arr).forEach(m => {
    const mSplit = m.split(' ')
    if (yearArr[mSplit[1]]) yearArr[mSplit[1]].push({ label: mSplit[0], size: arr[m].length })
    else yearArr[mSplit[1]] = [{ label: mSplit[0], size: arr[m].length }]
  })
  return <>
    {Object.keys(yearArr).map((year) => {
      const months = yearArr[year]
      let sizeYear = 0
      months.forEach(e => { sizeYear += e.size })
      offset += sizeD * sizeYear
      let offsetMonth = 0
      return <g key={year} transform={`translate(${offset - sizeD * sizeYear})`}>
        <rect
          x={0}
          y={0}
          stroke="#a6a6a6"
          strokeWidth="1"
          fill="white"
          width={sizeD * sizeYear}
          height={heightDateLine - sizeDay}
        />
        <text
          dominantBaseline="middle"
          textAnchor="middle"
          x={sizeD * sizeYear / 2}
          y={(heightDateLine - sizeDay) / 2}
        >{year}</text>
        {months.map((m, i) => {
          const sizeMonthD = m.size * sizeD
          offsetMonth += sizeMonthD
          return <React.Fragment key={i}>
            <rect
              x={offsetMonth - sizeMonthD}
              y={sizeDay}
              stroke="#a6a6a6"
              strokeWidth="1"
              fill="white"
              width={sizeMonthD}
              height={sizeDay}
            />
            <text
              x={offsetMonth - sizeMonthD + sizeMonthD / 2}
              y={heightDateLine - sizeDay + sizeDay / 2}
              dominantBaseline="middle"
              textAnchor="middle"
            >{m.label}</text>
          </React.Fragment>
        })}
      </g>
    })}
  </>
}
function DateLineWeek({ arr, heightDateLine, startDiag, sizeWeek, sizeDay }) {
  let offset = startDiag
  return <>
    {Object.keys(arr).map((month) => {
      const days = arr[month]
      const week = days.filter(e => e.day === 1)
      const incWeek = (week.length > 0) ? sizeWeek * week.length : days.length * sizeWeek / 7
      offset += incWeek
      return <g key={month} transform={`translate(${offset - incWeek})`}>
        <rect
          x={0}
          y={0}
          stroke="#a6a6a6"
          strokeWidth="1"
          fill="white"
          width={incWeek}
          height={heightDateLine - sizeDay}
        />
        <text
          dominantBaseline="middle"
          textAnchor="middle"
          x={sizeWeek * week.length / 2}
          y={(heightDateLine - sizeDay) / 2}
        >{days.length > 1 ? month : moment().month(month).format('MMM')}</text>
        {week.length === 0 && days.length > 0 &&
          <React.Fragment >
            <rect
              x={0}
              y={sizeDay}
              stroke="#a6a6a6"
              strokeWidth="1"
              fill="white"
              width={days.length * sizeWeek / 7}
              height={sizeDay}
            />
          </React.Fragment>
        }
        {week.map((day, i) => {
          return <React.Fragment key={i}>
            <rect
              x={i * sizeWeek}
              y={sizeDay}
              stroke="#a6a6a6"
              strokeWidth="1"
              fill="white"
              width={sizeWeek}
              height={sizeDay}
            />
            <text
              x={i * sizeWeek + sizeWeek / 2}
              y={heightDateLine - sizeDay + sizeDay / 2}
              dominantBaseline="middle"
              textAnchor="middle"
            >{day.d + ' ' + day.m}</text>
          </React.Fragment>
        })}
      </g>
    })}
  </>
}
function DateLineDay({ arr, countDay, daysToWeekend, heightDateLine, startDiag, sizeDay }) {
  let offset = startDiag
  return <>
    <g transform={`translate(${startDiag})`}>
      <rect
        x={0}
        y={sizeDay}
        fill="white"
        width={sizeDay * (countDay + 1)}
        height={sizeDay}
        stroke='#a6a6a6'
        strokeWidth={1}
      />
      <rect
        x={0}
        y={sizeDay}
        fill="url(#patternHeaderGanttDay)"
        width={sizeDay * countDay}
        height={sizeDay}
        transform={`translate(${sizeDay / 2})`}
      />
      <rect
        x={0}
        y={sizeDay}
        fill="url(#patternHeaderGanttDayWeekend)"
        width={sizeDay * countDay}
        height={sizeDay}
        transform={`translate(${sizeDay * daysToWeekend},0)`}
        stroke='none'
      />

    </g>
    {Object.keys(arr).map((month) => {
      const days = arr[month]
      offset += sizeDay * days.length
      return <g key={month} transform={`translate(${offset - sizeDay * days.length})`}>
        <rect
          x={0}
          y={0}
          stroke="#a6a6a6"
          strokeWidth="1"
          fill="white"
          width={sizeDay * days.length}
          height={heightDateLine - sizeDay}
        />
        <text
          dominantBaseline="middle"
          textAnchor="middle"
          x={sizeDay * days.length / 2}
          y={(heightDateLine - sizeDay) / 2}
        >{days.length > 1 ? month : moment().month(month).format('MMM')}</text>
        {days.map((day, i) => {
          return <React.Fragment key={i}>
            <text
              x={i * sizeDay + sizeDay / 2}
              y={heightDateLine - sizeDay + sizeDay / 2}
              dominantBaseline="middle"
              textAnchor="middle"
            >{day.d}</text>
          </React.Fragment>
        })}
      </g>
    })}
  </>
}
function formSelector(formName, field) {
  return state => state.form?.[formName]?.values[field]
}