import React, { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../../reducers/form';
import { typeGant } from '../../../enum/gant.enum'
import moment from 'moment'
import 'moment/locale/ru';
import {
  COLUMNS_OBJ, formViewProps,
  formName, columnsObjTransform, columnsObjTransformParent,
  columnsObjTransformView
} from '../../gant/gant.const'

const useStyles = makeStyles(theme => ({
  sticky: {
    position: 'sticky',
    top: 0,
  },
  grid_row: {
    fill: '#ffffff',
    "&:nth-child(2n)": {
      fill: "#f5f5f5"
    },
  },
  textProps: {
    pointerEvents: "none"
  },
  rectProps: {
    fill: '#ffffff',
    fillOpacity: '0.0',
    "&:hover": {
      fill: "#C0C0C0",
      fillOpacity: '1'
    }
  },

  grid_rect_props: {
    cursor: 'pointer'
  },
  unselectable: {
    '-webkit-touch-callout': 'none', /* iOS Safari */
    '-webkit-user-select': 'none',   /* Chrome/Safari/Opera */
    '-khtml-user-select': 'none',    /* Konqueror */
    '-moz-user-select': 'none',      /* Firefox */
    '-ms-user-select': 'none',       /* Internet Explorer/Edge */
    'user-select': 'none'           /* Non-prefixed version, currently
                                    not supported by any browser */
  },
  arrow: {
    zIndex: 1
  },
}));
let svgProps = {
  nameWidth: 300,
  startWidth: 150,
  endWidth: 150,
  sizeDay: 30,
  sizeWeek: 70,
  sizeMonth: 140,
  startDiag: 300,
  yOffset: 85,
  yPadding: 50,
  heightRec: 25
}
const moveTypes = {
  progress: 'progress',
  date: 'date',
  dateParent: 'date_parent',
  dateMilestone: 'date_milestone',
  endDate: 'endDate',
  startDate: 'startDate',
  arrowStart: 'arrowStart',
  arrowEnd: 'arrowEnd'
}
const listener = (evt) => {
  const offsetY = 57 + 96
  const y = screenYtoSVGUnits(window.scrollY > offsetY ? window.scrollY - offsetY : 0)
  document.getElementById("sticky-rect")
    .setAttributeNS(null, "transform", `translate(0,${y})`)
  document.getElementById("sticky-rect-table")
    .setAttributeNS(null, "transform", `translate(0,${y})`)
}
const listenerTable = (evt) => {
  const offsetY = 57 + 96
  const offsetX = 0
  const y = screenYtoSVGUnits(window.scrollY > offsetY ? window.scrollY - offsetY : 0)
  const x = screenXtoSVGUnits(window.scrollX > offsetX ? window.scrollX - offsetX : 0)
  document.getElementById("sticky-rect")
    .setAttributeNS(null, "transform", `translate(0,${y})`)
  document.getElementById("sticky-rect-table")
    .setAttributeNS(null, "transform", `translate(${x},${y})`)
  document.getElementById("sticky-work")
    .setAttributeNS(null, "transform", `translate(${x},0)`)
}
function screenYtoSVGUnits(val) {
  const svg = document.getElementById("svg-full");
  let pt = svg.createSVGPoint();
  pt.x = 0;
  pt.y = val;
  pt = pt.matrixTransform(svg.getCTM().inverse());
  return pt.y;
}
function screenXtoSVGUnits(val) {
  const svg = document.getElementById("svg-full");
  let pt = svg.createSVGPoint();
  pt.x = val;
  pt.y = 0;
  pt = pt.matrixTransform(svg.getCTM().inverse());
  return pt.x;
}
function formSelector(formName, field) {
  return state => state.form?.[formName]?.values[field]
}
function formSelectorLength(formName, field) {
  return state => state.form?.[formName]?.values[field].length
}
const viewBoxWidth = 1000;
const viewBoxHeight = 400;
export default function GrandView({ fixTable, zoom, percent, formDialog, formName, formView, COLUMNS_ARR }) {
  const classes = useStyles()
  useEffect(() => {
    if (fixTable) {
      window.removeEventListener("scroll", listenerTable);
      window.addEventListener("scroll", listener);
      document.getElementById("sticky-rect-table")
        .setAttributeNS(null, "transform", `translate(0,0)`)
      document.getElementById("sticky-work")
        .setAttributeNS(null, "transform", `translate(0,0)`)
    }
    else {
      window.removeEventListener("scroll", listener);
      window.addEventListener("scroll", listenerTable);
    }
    return () => {
      if (fixTable) {
        window.removeEventListener("scroll", listener);
      }
      else {
        window.removeEventListener("scroll", listenerTable);
      }
    }
  }, [fixTable])

  const count = useSelector(formSelector(formName, 'count')) || 1
  const length = useSelector(formSelectorLength(formName, 'allIds')) || 0
  const widthSVG = viewBoxWidth + svgProps.sizeDay * (count + 10)
  const heightSVG = viewBoxHeight + (length + 1) * svgProps.yPadding
  return <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id='svg-full'
      width={widthSVG}
      height={heightSVG}
      viewBox={`0 0 ${widthSVG * (percent / 100)} ${heightSVG * (percent / 100)}`}
      style={{ overflowX: 'auto', overflowY: 'auto' }}
    >
      <defs>
        <pattern id="patternGridDay"
          x="0" y="0" width={svgProps.sizeDay} height={svgProps.yPadding}
          patternUnits="userSpaceOnUse" >
          <path d={`M ${svgProps.sizeDay / 2},0 V ${svgProps.yPadding} Z M 0,${svgProps.sizeDay / 2} H ${svgProps.yPadding} Z`} stroke="#000000ff" fill="none" />
        </pattern>
        <pattern id="patternGridWeek"
          x="0" y="0" width={svgProps.sizeWeek / 7} height={svgProps.yPadding}
          patternUnits="userSpaceOnUse" >
          <path d={`M ${svgProps.sizeWeek / 14},0 V ${svgProps.yPadding} Z M 0,${svgProps.sizeWeek / 14} H ${svgProps.yPadding} Z`} stroke="#000000ff" fill="none" />
        </pattern>
      </defs>
      <g id='ds' transform={`translate(0,60)`} >
        <BlockRect formName={formName} classes={classes} /> {/* поперечные строки */}
      </g>
      <GridTable heightSVG={heightSVG} zoom={zoom} /> {/* сетка */}
      <g>
        <g id='ds' transform={`translate(0,60)`} >
          <ClipPathName COLUMNS_ARR={COLUMNS_ARR} /> {/* скрываем выход за колонку наименования */}
          <ArrowAll formName={formName} zoom={zoom} /> {/* стрелки */}
          <BlocView
            formName={formName}
            classes={classes}
            formDialog={formDialog}
            formView={formView}
            zoom={zoom}
          /> {/* прямоугольники на сетке  */}
          <PropsView formView={formView} /> {/* всплывашка при наведении  */}
          <BlockTable
            formName={formName}
            classes={classes}
            COLUMNS_ARR={COLUMNS_ARR}
          /> {/* таблица с данными  */}
        </g>
      </g>
      <DateLine zoom={zoom} />  {/* шапка с датами */}
      <TableName COLUMNS_ARR={COLUMNS_ARR} />  {/* шапка таблицы с данными */}
    </svg>
  </div>
}

// всплывашка при наведении ------------------------- 
function PropsView({ formView }) {
  const form = useSelector(state => state.form[formView] || state.form.default)
  const { obj, open = false, x, y, height } = form.values
  if (!open) return null
  const widthB = 240
  const xView = 0
  const yView = svgProps.heightRec
  var loc = cursorPoint({ clientX: x, clientY: y });
  const props = {
    startPlan: { name: 'Дата начала план' },
    endPlan: { name: 'Дата окончания план' },
    progress: { name: 'Прогресс' },
    perf: { name: 'Выполнено' }
  }
  const objView = columnsObjTransformView(obj)
  const objLabel = { ...COLUMNS_OBJ, ...props }
  let arr = []
  switch (obj.typeGant) {
    case 'material':
    case 'work':
    case 'textField':
      arr = ['startPlan', 'endPlan', 'start', 'end', 'progress']
      break;
    case 'parent':
      arr = ['start', 'end']
      break;
    case 'milestone':
      arr = ['start']
      break;
    case 'link':
      arr = ['start', 'end', 'progress']
      break;
    default:
      break;
  }
  const heightB = 30 * (arr.length + 1)

  return <g transform={`translate(${loc.x},${height + svgProps.heightRec * 2})`} visibility={open ? 'visible' : 'hidden'}>
    <rect
      x={xView}
      y={yView}
      width={widthB}
      height={heightB}
      fill="#F0F8FF"// "#909bd7"
      fillOpacity={0.8}
      stroke='black'
    />
    <text
      x={5}
      y={yView + 15 + 0 + 30 * 0}
      dominantBaseline="middle"
      clipPath='url(#nameChip2)'
    >{obj.name}
    </text>
    {arr.map((key, i) => {
      const value = objView[key]
      const label = objLabel[key]?.name
      return <text
        key={key}
        x={5}
        y={yView + 15 + 30 * (1 + i)}
        dominantBaseline="middle"
        clipPath='url(#nameChip2)'
      >{`${label}: ${value}`}
      </text>
    })}
    <clipPath id="nameChip2">
      <rect x={xView} y={yView} width={widthB} height={heightB} />
    </clipPath>
  </g>
}
// Get point in global SVG space
function cursorPoint(e) {
  // pt.x = evt.clientX; pt.y = evt.clientY;
  var svg = document.getElementById('svg-full')
  var point = svg.createSVGPoint();
  point.x = e.clientX;
  point.y = e.clientY;
  var ctm = svg.getScreenCTM();
  var inverse = ctm.inverse();
  return point.matrixTransform(inverse);
}
// Отсекает область наименование ---------------------
function ClipPathName({ COLUMNS_ARR }) {
  const length = useSelector(state => state.form?.[formName]?.values.allIds.length) || 0
  const columnsObj = useSelector(state => state.form[formViewProps]?.values.columnsObj) || {}
  const columnsArrHide = useSelector(state => state.form[formViewProps]?.values.columnsArrHide) || []
  if (!columnsObj.name || columnsArrHide.includes('name')) return null;
  const arrFilter = COLUMNS_ARR.filter(e => !columnsArrHide.includes(e))
  if (arrFilter.length === 0) return null
  let width = 0
  let i = 0
  let condition = arrFilter[i]
  while (condition !== 'name' && i < arrFilter.length) {
    width += columnsObj[condition].width
    i += 1;
    condition = arrFilter[i]
  };
  return <clipPath id="nameChip">
    <rect x={width} y={- 15} width={columnsObj.name.width} height={svgProps.yPadding * length} />
  </clipPath>
}

// поперечные строки ---------------------------------------------
function BlockRect({ formName, classes }) {
  const allIdsLength = useSelector(state => state.form[formName]?.values.allIds.length) || 0
  const count = useSelector(state => state.form[formName]?.values.count) || 1
  const startDiag = useSelector(state => state.form[formViewProps]?.values.startDiag) || 1810
  return Array.apply(null, Array(allIdsLength)).map((key, i) => {
    return <g key={i} transform={`translate(0,${svgProps.yPadding * i})`} className={classes.unselectable}>
      <rect key={i} x={0} y={0}
        width={startDiag + (count + 1) * svgProps.sizeDay}
        height={svgProps.yPadding}
        className={classes.grid_row}
        fill={(i % 2 > 0) ? '#ffffff' : "#f5f5f5"}
      />
      <line
        x1={0}
        y1={svgProps.yPadding * i}
        x2={startDiag + svgProps.sizeDay * (count + 14)}
        y2={svgProps.yPadding * i}
        stroke="#e0e0e0"
      />
      <line
        x1={0}
        y1={svgProps.yPadding}
        x2={startDiag + svgProps.sizeDay * (count + 14)}
        y2={svgProps.yPadding}
        stroke="#e0e0e0"
      />
    </g>
  })
}

// таблица с данными --------------------------------------------
function BlockTable({ formName, classes, COLUMNS_ARR }) {
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { byId = {}, arrFilter = [] } = form.values
  const startDiag = useSelector(state => state.form[formViewProps]?.values.startDiag) || 1810
  const tree = useSelector(state => state.gant)
  const allIds = tree.flat.map(e => 'id' + e.id)
  const dispatch = useDispatch()
  const posFind = (parent) => {
    const res = []
    const find = (par) => {
      allIds.forEach(row => {
        if (byId[row].parent === par && byId[row].typeGant === 'parent') {
          res.push(byId[row].idAPI)
          find(byId[row].idAPI)
        }
      })
    }
    find(parent)
    return res
  }
  const hideParent = (idAPI) => {
    const arr = posFind(idAPI)
    if (arrFilter.includes(idAPI)) {
      dispatch(actions.formArrayDelete(idAPI, { name: formName, field: 'arrFilter' }))
      arr.forEach(key => dispatch(actions.formArrayDelete(key, { name: formName, field: 'arrFilter' })))
    }
    else {
      dispatch(actions.formArrayAddMany([idAPI, ...arr], { name: formName, field: 'arrFilter' }))
    }
  }
  const arrFiltered = allIds.filter(key => !arrFilter.includes(byId[key]?.parent))
  return <g id="sticky-work">
    {arrFiltered.map((key, i) => {
      const obj = byId[key]
      const posFlat = tree.flat.find(e => key === 'id' + e.id);
      return <g
        key={key}
        id={obj.id}
        transform={`translate(0,${svgProps.yPadding * i})`} className={classes.unselectable}
      >
        <rect key={i} x={0} y={0}
          width={startDiag}
          height={svgProps.yPadding}
          fill={(i % 2 > 0) ? '#ffffff' : "#f5f5f5"}
        />
        <WorkBlock
          obj={obj}
          classes={classes}
          pos={posFlat?.pos || ''}
          color={posFlat?.colorParent || '#fff'}
          arrFilter={arrFilter}
          hideParent={hideParent}
          COLUMNS_ARR={COLUMNS_ARR}
        />
      </g>
    })}
  </g>
}
function WorkBlock({ obj, classes, pos, color, arrFilter, hideParent, COLUMNS_ARR }) {
  const startDiag = useSelector(state => state.form[formViewProps]?.values.startDiag) || 1810
  const columnsObj = useSelector(state => state.form[formViewProps]?.values.columnsObj) || {}
  const columnsArrHide = useSelector(state => state.form[formViewProps]?.values.columnsArrHide) || []
  const handleChange = () => {
    hideParent(obj.idAPI)
  }
  let x = 0
  const objView = obj.typeGant === 'parent' ? columnsObjTransformParent({ ...obj, pos }) : columnsObjTransform({ ...obj, pos })
  return <g className={classes.grid_rect_props}>
    <g>
      {obj.typeGant === 'parent' && <rect x={0} y={0} width={startDiag} height={svgProps.yPadding} fill={obj.color} />}
      {obj.typeGant !== 'parent' && <rect x={0} y={0} width={columnsObj.selected.width} height={svgProps.yPadding} fill={color} />}
      <rect x={0} y={0} width={startDiag} height={svgProps.yPadding} className={classes.rectProps} fill='#ffffff' fillOpacity={'0.0'} />
      {COLUMNS_ARR.filter(e => !columnsArrHide.includes(e)).map(key => {
        const column = columnsObj[key]
        const { width } = column
        x += width
        const props = (key === 'name') ? { clipPath: 'url(#nameChip)' } : {}
        return <text
          key={key}
          x={x - ((column.align === 'center') ? width / 2 : (width - 4))}
          y={svgProps.yPadding / 2}
          dominantBaseline="middle"
          textAnchor={column.align === 'center' ? 'middle' : 'start'}
          className={classes.textProps}
          fontWeight={obj.typeGant === 'parent' ? 'bold' : 'normal'}
          {...props}
        >{objView[key]}
        </text>
      })}
    </g>
    {obj.typeGant === 'parent' &&
      <g onClick={handleChange}>
        <g transform={`translate(${columnsObj.selected.width / 2 - 14},${svgProps.yPadding / 2 - 16}) scale(1.2) `} >
          {arrFilter.includes(obj.idAPI) && <path fill='#555555' d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path>}
          {!arrFilter.includes(obj.idAPI) && <path fill='#555555' d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z"></path>}
        </g>
        <rect x={0} y={0} width={columnsObj.selected.width} height={svgProps.yPadding} fillOpacity={0.0} />
      </g>
    }
    {obj.typeGant !== 'parent' &&
      <g>
        <rect
          x={0}
          y={0}
          width={columnsObj.selected.width}
          height={svgProps.yPadding}
          fillOpacity={0.0}
        />
      </g>
    }
  </g>
}

// прямоугольники на сетке -------------------------------------------
function BlocView({ formName, classes, formDialog, zoom, formView }) {
  const form = useSelector(state => state.form[formName] || state.form.default)
  const viewProps = useSelector(state => state.form[formViewProps] || state.form.default)
  const { byId = {}, start = moment(), arrFilter = [] } = form.values
  const tree = useSelector(state => state.gant)
  const allIds = tree.flat.map(e => 'id' + e.id)
  const { planVisible = true, startDiag = 1810 } = viewProps.values

  return (
    <g id='svg-drag'>
      {allIds.filter(key => !arrFilter.includes(byId[key].parent)).map((key, i) => {
        const obj = byId[key]
        const planValid = obj.startPlan?.isValid() && obj.endPlan?.isValid()
        const isCrit = tree.crit.path.includes(key)
        return (
          <g key={key} transform={`translate(0,${svgProps.yPadding * i})`} className={classes.unselectable}>
            {planVisible && planValid && <ViewBlockPlan
              obj={obj} start={start}
              classes={classes}
              startDiag={startDiag}
              zoom={zoom}
              formView={formView}
              height={svgProps.yPadding * i}
              id={key}
              formDialog={formDialog}
              formName={formName}
            />}
            <ViewBlock
              startDiag={startDiag}
              zoom={zoom}
              formView={formView}
              height={svgProps.yPadding * i}
              id={key}
              formName={formName}
              isCrit={isCrit}
            />
          </g>
        )
      })}
      <path
        id='arrow-add-all'
        startpoint='m 0 0' d='m 0 0'
        stroke="orange"
        strokeWidth={3}
        strokeDasharray="10,10"
        fill="none"
      />
    </g>
  )
}

//  прямоугольники факт
function ViewBlock({ startDiag, zoom, formView, height, id, isCrit }) {
  const start = useSelector(formSelector(formName, 'start'))
  const obj = useSelector(state => state.form?.[formName]?.values.byId[id])
  const [startRect, setStartRect] = useState(0)
  const [widthRect, setWidthRect] = useState(0)
  const [size, setSize] = useState(0)
  useEffect(() => {
    let startRectNew = obj.start.diff(start, 'days');
    let widthRectNew = obj.duration + 1
    let sizeNew = svgProps.sizeDay
    switch (zoom) {
      case 'day': {
        sizeNew = svgProps.sizeDay;
        startRectNew = obj.start.diff(start, 'days');
        widthRectNew = obj.end.diff(obj.start, 'days') + 1
        break;
      }
      case 'week': {
        sizeNew = svgProps.sizeWeek;
        startRectNew = obj.start.diff(start, 'weeks', true);
        widthRectNew = moment(obj.end).add(1, 'd').diff(obj.start, 'weeks', true)
        break;
      }
      case 'month': {
        sizeNew = svgProps.sizeMonth * 12 / 365;
        startRectNew = obj.start.diff(start, 'days', true);
        widthRectNew = obj.end.diff(obj.start, 'days', true) + 1
        break;
      }
      default: break;
    }
    setStartRect(startRectNew)
    setWidthRect(widthRectNew)
    setSize(sizeNew)
  }, [obj.duration, obj.start, obj.end, zoom, start,])
  const dispatch = useDispatch()
  const handleMouseOver = (e) => {
    dispatch(actions.formChangeAsObj({ obj, open: true, x: e.clientX, y: e.clientY, height }, { name: formView }))
  }
  const handleMoueOut = (e) => {
    dispatch(actions.formChangeAsObj({ obj: null, open: false }, { name: formView }))
  }
  const handleChangeRow = () => { }
  switch (obj.typeGant) {
    case 'parent':
      return <ViewBlockParent
        id={obj.id}
        idAPI={obj.idAPI}
        startDiag={startDiag}
        startRect={startRect}
        size={size}
        handleMouseOver={handleMouseOver}
        handleMoueOut={handleMoueOut}
        widthRect={widthRect}
      />
    case 'milestone':
      return <ViewBlockMilestone
        id={obj.id}
        idAPI={obj.idAPI}
        startDiag={startDiag}
        startRect={startRect}
        size={size}
        handleMouseOver={handleMouseOver}
        handleMoueOut={handleMoueOut}
        handleChangeRow={handleChangeRow}
        widthRect={widthRect}
        isCrit={isCrit}
      />
    case 'link':
      return <ViewBlockLink
        id={obj.id}
        idAPI={obj.idAPI}
        progress={obj.progress}
        startDiag={startDiag}
        startRect={startRect}
        size={size}
        handleMouseOver={handleMouseOver}
        handleMoueOut={handleMoueOut}
        handleChangeRow={handleChangeRow}
        widthRect={widthRect}
        isCrit={isCrit}
      />
    default:
      return <ViewBlockMain
        obj={obj}
        startDiag={startDiag}
        startRect={startRect}
        size={size}
        handleMouseOver={handleMouseOver}
        handleMoueOut={handleMoueOut}
        handleChangeRow={handleChangeRow}
        widthRect={widthRect}
        isCrit={isCrit}
      />
  }
}
const useStylesViewBlock = makeStyles(theme => ({
  triangle: {
    cursor: 'ew-resize',
    visibility: 'hidden',
    fill: "#ddd"
  },
  arrow_circle: {
    cursor: 'pointer',
    visibility: 'hidden',
    fill: "#ddd",
    '&:hover': {
      fill: 'yellow',
      stroke: 'black',
      strokeWidth: "2"
    }
  },
  block: {
    '&:hover $arrow_circle': {
      visibility: 'visible',
    },
    '&:hover $triangle': {
      visibility: 'visible',
    }
  },
}));
function ViewBlockParent({ id, idAPI, startDiag, startRect, size, handleMouseOver, handleMoueOut, widthRect }) {
  const heightPar = Math.round(svgProps.heightRec / 2)
  const type = typeGant.find(e => e.value === 'parent')
  return <g transform={`translate(${startDiag + startRect * size},10)`}
    onMouseOver={handleMouseOver}
    onMouseOut={handleMoueOut}
  >
    <rect id={id + '_rect_main'} x={0} y={0}
      width={size * widthRect}
      height={heightPar}
      fill={type.color}
    />
    <rect id={id + '_rect'} x={0} y={0}
      width={size * widthRect}
      height={heightPar}
      draggable
      droppable='true'
      style={{ fillOpacity: '.05', cursor: 'grabbing' }}
      idapi={idAPI}
      idrow={id}
      typemove={moveTypes.dateParent}
      fillOpacity='0.05'
    />
    <polygon
      id={id + '_rect_tangle_start'}
      points={`0,${svgProps.heightRec}   0,${heightPar}  ${heightPar},${heightPar}`}
      fill={type.color}
      stroke={type.color}
      strokeWidth={0}
    />
    <polygon
      id={id + '_rect_tangle_end'}
      points={`
      ${size * widthRect},${svgProps.heightRec} 
      ${size * widthRect},${heightPar} 
      ${size * widthRect - heightPar},${heightPar}
      `}
      stroke={type.color}
      strokeWidth={0}
      fill={type.color}
    />
  </g>
}
function ViewBlockMilestone({ id, idAPI, startDiag, startRect, size, handleMouseOver, handleMoueOut, handleChangeRow, widthRect, isCrit }) {
  const classes = useStylesViewBlock()
  const type = { ...typeGant.find(e => e.value === 'milestone') }
  if (isCrit) {
    type.color = '#AA0000'
    type.colorPercent = '#DD0000'
  }
  return <g
    transform={`translate(${startDiag + startRect * size},6.125)`}
    className={classes.block}
    onMouseOver={handleMouseOver}
    onMouseOut={handleMoueOut}
    onDoubleClick={handleChangeRow}
  >
    <rect
      x={-size * widthRect / 2 - svgProps.heightRec - svgProps.heightRec / 3}
      y={0}
      width={size * widthRect + 2 * svgProps.heightRec + 2 * svgProps.heightRec / 3}
      height={svgProps.heightRec}
      fillOpacity='0.0'
    />
    <polygon id={id + '_rect_main'}
      points={`
        0,0, 
        ${size * widthRect / 2},${svgProps.heightRec / 2}, 
        0,${svgProps.heightRec}, 
        ${-size * widthRect / 2},${svgProps.heightRec / 2}`}
      fill={type.color}
      stroke={type.color}
      strokeWidth={0}
      draggable
      droppable='true'
      style={{ cursor: 'grabbing' }}
      idapi={idAPI}
      idrow={id}
      typemove={moveTypes.dateMilestone}
    />
  </g>
}
function ViewBlockLink({ id, idAPI, progress, startDiag, startRect, size, handleMouseOver, handleMoueOut, handleChangeRow, widthRect, isCrit }) {
  const classes = useStylesViewBlock()
  const type = { ...typeGant.find(e => e.value === 'link') }
  if (isCrit) {
    type.color = '#AA0000'
    type.colorPercent = '#DD0000'
  }
  return <g
    transform={`translate(${startDiag + startRect * size},10)`}
    className={classes.block}
    onMouseOver={handleMouseOver}
    onMouseOut={handleMoueOut}
    onDoubleClick={handleChangeRow}
  >
    <rect
      x={-svgProps.heightRec}
      y={0}
      width={svgProps.heightRec}
      height={svgProps.heightRec / 1.5}
      fillOpacity='0.0'
    />
    <rect
      x={size * widthRect}
      y={0}
      width={svgProps.heightRec}
      height={svgProps.heightRec / 1.5}
      fillOpacity='0.0'
    />
    <rect id={id + '_rect_main'} x={0} y={0}
      width={size * widthRect}
      height={svgProps.heightRec / 1.5}
      fill={type.color}
      idapi={idAPI}
      idrow={id}
      typemove={moveTypes.date}
      fillOpacity='0.8'
    />
    <rect id={id + '_rect_progress'} x={0} y={0}
      width={size * Math.round(widthRect * progress / 100)}
      height={svgProps.heightRec / 1.5}
      fill={type.colorPercent}
      fillOpacity='1'
    />
  </g>
}
function ViewBlockMain({ obj, startDiag, startRect, size, handleMouseOver, handleMoueOut, handleChangeRow, widthRect, isCrit }) {
  const classes = useStylesViewBlock()
  const type = { ...typeGant.find(e => e.value === obj.typeGant) }
  if (isCrit) {
    type.color = '#AA0000'
    type.colorPercent = '#DD0000'
  }
  return <g
    transform={`translate(${startDiag + startRect * size},10)`}
    className={classes.block}
    onMouseOver={handleMouseOver}
    onMouseOut={handleMoueOut}
    onDoubleClick={handleChangeRow}
  >
    <rect
      x={-svgProps.heightRec}
      y={0}
      width={svgProps.heightRec}
      height={svgProps.heightRec / 1.5}
      fillOpacity='0.0'
    />
    <rect
      x={size * widthRect}
      y={0}
      width={svgProps.heightRec}
      height={svgProps.heightRec / 1.5}
      fillOpacity='0.0'
    />
    <rect id={obj.id + '_rect_main'} x={0} y={0}
      width={size * widthRect}
      height={svgProps.heightRec / 1.5}
      fill={type.color}
      idapi={obj.idAPI}
      idrow={obj.id}
      typemove={moveTypes.date}
      fillOpacity='0.8'
    />
    <rect id={obj.id + '_rect_progress'} x={0} y={0}
      width={size * Math.round(widthRect * obj.progress / 100)}
      height={svgProps.heightRec / 1.5}
      fill={type.colorPercent}
      fillOpacity='1'
    />
    <rect id={obj.id + '_rect'} x={0} y={-10}
      width={size * widthRect}
      height={svgProps.heightRec + 10}
      draggable
      droppable='true'
      style={{ fillOpacity: '.00', cursor: 'grabbing' }}
      idapi={obj.idAPI}
      idrow={obj.id}
      typemove={moveTypes.date}
      fillOpacity='0.00'
    />
    <text visibility="hidden"
      id={obj.id + '_text_start'}
      x={- 60}
      y={svgProps.heightRec / 1.5}
      dominantBaseline="middle"
      textAnchor="middle" >{obj.start.format('DD.MM.YYYY')}</text>
    <text visibility="hidden"
      id={obj.id + '_text_end'}
      x={size * widthRect + 60}
      y={0}
      dominantBaseline="middle"
      textAnchor="middle">{obj.end.format('DD.MM.YYYY')}</text>
    <rect id={obj.id + '_rect_end'} x={size * widthRect - size / 5} y={0}
      width={size / 5}
      height={svgProps.heightRec / 1.5}
      rx='3' ry='3'
      fill="yellow"
      fillOpacity='0.1'
      draggable
      idapi={obj.idAPI}
      idrow={obj.id}
      typemove={moveTypes.endDate}
      style={{ cursor: 'e-resize' }}
    />
    <rect id={obj.id + '_rect_start'} x={0} y={0}
      width={size / 5}
      height={svgProps.heightRec / 1.5}
      rx='3' ry='3'
      fill="yellow"
      fillOpacity='0.1'
      draggable
      idapi={obj.idAPI}
      idrow={obj.id}
      typemove={moveTypes.startDate}
      style={{ cursor: 'w-resize' }}
    />
  </g>
}
//  прямоугольники план
function ViewBlockPlan({ obj, startDiag, zoom, start, formView, height, id, formDialog, formName }) {
  const classes = useStylesViewBlock()
  const dispatch = useDispatch()
  const [startRect, setStartRect] = useState(0)
  const [widthRect, setWidthRect] = useState(0)
  const [size, setSize] = useState(0)
  useEffect(() => {
    let sizeNew = svgProps.sizeDay
    let startRectSet = obj.startPlan.diff(start, 'days') || 0;
    let widthRectSet = (obj.endPlan.diff(obj.startPlan, 'days') + 1) || 0
    switch (zoom) {
      case 'day': {
        sizeNew = svgProps.sizeDay;
        startRectSet = obj.startPlan.diff(start, 'days');
        widthRectSet = obj.endPlan.diff(obj.startPlan, 'days') + 1
        break;
      }
      case 'week': {
        sizeNew = svgProps.sizeWeek;
        startRectSet = obj.startPlan.diff(start, 'weeks', true);
        widthRectSet = moment(obj.endPlan).add(1, 'd').diff(obj.startPlan, 'weeks', true)
        break;
      }
      case 'month': {
        sizeNew = svgProps.sizeMonth * 12 / 365;
        startRectSet = obj.startPlan.diff(start, 'days', true);
        widthRectSet = obj.endPlan.diff(obj.startPlan, 'days', true) + 1
        break;
      }
      default: break;
    }
    setSize(sizeNew)
    setStartRect(startRectSet)
    setWidthRect(widthRectSet)
  }, [start, obj.startPlan, obj.endPlan, zoom])

  const handleDoubleClick = () => {
    dispatch(actions.formChangeAsObj({ obj, id, open: true, newRow: false }, { name: formDialog }))
  }
  if (obj.typeGant === 'parent' || obj.typeGant === 'milestone') return null;
  const handleMouseOver = (e) => {
    dispatch(actions.formChangeAsObj({ obj, open: true, x: e.clientX, y: e.clientY, height }, { name: formView }))
  }
  const handleMoueOut = (e) => {
    dispatch(actions.formChangeAsObj({ obj: null, open: false }, { name: formView }))
  }
  return <g
    transform={`translate(${startDiag + startRect * size},10)`}
    className={classes.block}
    onDoubleClick={handleDoubleClick}
    onMouseOver={handleMouseOver}
    onMouseOut={handleMoueOut}
  >
    <rect id={obj.id + '_rect_main_plan'} x={0} y={svgProps.heightRec / 1.4}
      width={size * widthRect}
      height={svgProps.heightRec / 3}
      fill={'#808080'}
    />
  </g>
}

// arrows ----------------------------------------------
function ArrowAll({ formName, zoom }) {
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { byId = {}, arrFilter } = form.values
  const tree = useSelector(state => state.gant)
  const allIds = tree.flat.map(e => 'id' + e.id)
  const arrFiltered = allIds.filter(key => !arrFilter.includes(byId[key].parent))
  const arrowCrit = useSelector(state => state.gant.crit.arrow)

  const [size, setSize] = useState(0)
  const [diff, setDiff] = useState('')
  const mouseEnterRef = useRef(null);
  useEffect(() => {
    switch (zoom) {
      case 'day': {
        setSize(svgProps.sizeDay);
        setDiff('days')
        break;
      }
      case 'week': {
        setSize(svgProps.sizeWeek);
        setDiff('weeks')
        break;
      }
      case 'month': {
        setSize(svgProps.sizeMonth * 12 / 365)
        setDiff('days')
        break;
      }
      default: break;
    }
  }, [zoom])
  return <g>
    {arrFiltered.map((key, i) => {
      const obj = byId[key]
      if (obj.typeGant === 'parent' && !arrFilter.includes(obj.idAPI)) return null
      return obj.dependencies.map((arrObj, j) => {
        const toTask = byId[arrObj.to]
        if (toTask.typeGant === 'parent' && !arrFilter.includes(toTask.idAPI)) return null
        if (arrFilter.includes(toTask.parent)) return null
        const toIndex = arrFiltered.findIndex(s => s === arrObj.to)
        const selectedCrit = arrowCrit.includes(arrObj.id)
        return <Arrow
          id={'arrow_form' + key + ' to' + arrObj.id}
          key={'arrow_form' + key + ' to' + arrObj.id + '_' + i + '_' + j}
          from_task={{ start: obj.start, end: obj.end, index: i, typeGant: obj.typeGant }}
          to_task={{ start: toTask.start, end: toTask.end, index: toIndex, typeGant: toTask.typeGant }}
          type={arrObj.type}
          sizeDay={size}
          diff={diff}
          mouseEnterRef={mouseEnterRef}
          isCrit={selectedCrit}
        />
      })
    })}
    <use ref={mouseEnterRef} />
  </g>
}
const useStylesArrow = makeStyles(theme => ({
  pathArrow: {
    fill: 'none',
    strokeWidth: "3",
  },
}));
function Arrow({ from_task, to_task, type, sizeDay, diff, id, mouseEnterRef, isCrit }) {
  const startDiag = useSelector(state => state.form[formViewProps]?.values.startDiag) || 1810
  const start = useSelector(state => state.form[formName]?.values.start) || moment()
  const classes = useStylesArrow()
  const [pathLine, setPathLine] = useState('')
  const [color, setColor] = useState('orange')
  useEffect(() => {
    if (isCrit) setColor('red')
    else setColor('orange')
  }, [isCrit])
  useEffect(() => {
    const { yPadding } = svgProps
    const getStart = (task) => {
      const startRectTo = task.start.diff(start, diff, true);
      const diffStart = task.typeGant === 'milestone' ? sizeDay / 2 : 0
      return {
        x: startDiag + Math.round(startRectTo * sizeDay - diffStart),
        y: yPadding * (task.index + 0.375)
      }
    }
    const getEnd = (task) => {
      const endRectTo = moment(task.end).add(1, 'd').diff(start, diff, true)
      const diffEnd = task.typeGant === 'milestone' ? sizeDay / 2 : 0
      return {
        x: startDiag + Math.round(endRectTo * sizeDay - diffEnd),
        y: yPadding * (task.index + 0.375)
      }
    }
    const finishStart = () => {
      const startPoint = getEnd(from_task)
      const endPoint = getStart(to_task)
      if (startPoint.x + sizeDay / 2 < endPoint.x - sizeDay / 2) {
        return `M ${startPoint.x} ${startPoint.y} h ${sizeDay / 2} V ${endPoint.y} H ${endPoint.x}  m -5 -5 l 5 5 l -5 5`
      }
      return `M ${startPoint.x} ${startPoint.y} h ${sizeDay / 2} 
      v ${(endPoint.y - startPoint.y) / 2} 
      H ${endPoint.x - sizeDay / 2} 
      V ${endPoint.y} H ${endPoint.x}  m -5 -5 l 5 5 l -5 5`
    }
    const startStart = () => {
      const startPoint = getStart(from_task)
      const endPoint = getStart(to_task)
      if (startPoint.x + sizeDay / 2 < endPoint.x - sizeDay / 2) {
        return `M ${startPoint.x} ${startPoint.y} h ${-sizeDay / 2} V ${endPoint.y} H ${endPoint.x}  m -5 -5 l 5 5 l -5 5`
      }
      return `M ${startPoint.x} ${startPoint.y} h ${-(startPoint.x - endPoint.x + sizeDay / 2)} 
      V ${endPoint.y} 
      h ${sizeDay / 2} 
      m -5 -5 l 5 5 l -5 5`
    }
    const finishFinish = () => {
      const startPoint = getEnd(from_task)
      const endPoint = getEnd(to_task)
      if (startPoint.x + sizeDay / 2 < endPoint.x - sizeDay / 2) {
        return `M ${startPoint.x} ${startPoint.y} H ${endPoint.x + sizeDay / 2} V ${endPoint.y} H ${endPoint.x}  m 5 -5 l -5 5 l 5 5`
      }
      return `M ${startPoint.x} ${startPoint.y} h ${sizeDay / 2} 
      v ${endPoint.y - startPoint.y} 
      H ${endPoint.x} 
      m 5 -5 l -5 5 l 5 5`
    }
    const startFinish = () => {
      const startPoint = getStart(from_task)
      const endPoint = getEnd(to_task)
      if (startPoint.x + sizeDay / 2 < endPoint.x - sizeDay / 2) {
        return `M ${startPoint.x} ${startPoint.y} h ${-sizeDay / 2} v ${yPadding / 2} H ${endPoint.x + sizeDay / 2} V ${endPoint.y} H ${endPoint.x} m 5 -5 l -5 5 l 5 5`
      }
      return `M ${startPoint.x} ${startPoint.y} h ${-sizeDay / 2} 
      v ${yPadding / 2} 
      H ${endPoint.x + sizeDay / 2} 
      V ${endPoint.y} H ${endPoint.x}  m 5 -5 l -5 5 l 5 5`
    }
    let d = ''
    switch (type) {
      case 'fs':
        d = finishStart()
        break;
      case 'ss':
        d = startStart()
        break;
      case 'ff':
        d = finishFinish()
        break;
      case 'sf':
        d = startFinish()
        break;
      default:
        break;
    }
    setPathLine(d)

  }, [startDiag, diff, from_task, sizeDay, start, to_task, type])
  return <g>
    <path
      id={id + '_path'}
      onMouseEnter={() => {
        mouseEnterRef.current.setAttributeNS(null, 'href', '#' + id + '_path')
        setColor('red')
      }}
      onMouseLeave={() => {
        setTimeout(() => {
          mouseEnterRef.current.setAttributeNS(null, 'href', '')
          if (isCrit) setColor('red')
          else setColor('orange')
        }, 2 * 1000)
      }}
      className={classes.pathArrow}
      d={pathLine}
      stroke={color}
    />
  </g>

}

// increase column width -------------------------------
function TableName({ COLUMNS_ARR }) {
  const form2 = useSelector(state => state.form[formViewProps] || state.form.default)
  const { columnsObj = {}, startDiag = 0, columnsArrHide = [] } = form2.values
  const dispatch = useDispatch()
  let x = 0
  let xs = 0
  const [selectMove, setSelectMove] = useState(null)
  const [posX, setPosX] = useState(0)
  const [move, setMove] = useState(0)
  const [typeMove, setTypeMove] = useState(null)
  const handleDragStart = (e) => {
    if (e.target.getAttributeNS(null, 'draggable')) {
      const name = e.target.getAttributeNS(null, 'type')
      setSelectMove(e.target)
      setPosX(e.clientX)
      setTypeMove(name)
    }
  }
  const handleDrag = (e) => {
    if (!selectMove) return;
    setMove(move + e.clientX - posX)
    setPosX(e.clientX)
  }
  const handleDraEnd = () => {
    if (!selectMove) return;
    setSelectMove(null)
    const column = columnsObj[typeMove]
    let newWidth = column.width + move
    if (column && column.widthMin >= newWidth) { newWidth = column.widthMin }
    dispatch(actions.formObjChangeObj({
      width: newWidth,
    }, { field: 'columnsObj', id: typeMove, name: formViewProps }))
    dispatch(actions.formChange(startDiag + newWidth - column.width, { field: 'startDiag', name: formViewProps }))
    setMove(0)
    setTypeMove(false)
  }
  return <g id="sticky-rect-table">
    {COLUMNS_ARR.filter(e => !columnsArrHide.includes(e)).map(key => {
      const column = columnsObj[key]
      if (!column) return null // ВРЕМЕННО!!!!!!!!!!!!!!!!!!
      const width = column.width
      x += width
      return <OneRect key={key} x={x - width} y={0} width={width} name={column.name} />
    })}
    <g
      onMouseDown={handleDragStart}
      onMouseMove={handleDrag}
      onMouseUp={handleDraEnd}
      onMouseLeave={handleDraEnd}
    >
      <rect x={0} y={0} height={60} width={x} fill='#ffffff' fillOpacity='0.1' />
      {COLUMNS_ARR.filter(e => !columnsArrHide.includes(e)).map(key => {
        const column = columnsObj[key]
        if (!column) return null // ВРЕМЕННО!!!!!!!!!!!!!!!!!!
        const width = column.width
        xs += width
        return <line
          key={key}
          x1={xs + ((typeMove === key) ? move : 0)}
          y1={0}
          x2={xs + ((typeMove === key) ? move : 0)}
          y2={60}
          stroke="#a6a6a6"
          strokeWidth="2"
          draggable
          type={key}
          style={{ cursor: 'ew-resize' }}
        />
      })}
    </g>
  </g>
}
function OneRect({ x, y, width, name }) {
  return <g transform={`translate(${x},${y})`}>
    <rect x={0} y={0} stroke="#a6a6a6" strokeWidth="1" fill="white" width={width} height={60} />
    <text dominantBaseline="middle" textAnchor="middle" x={width / 2} y={30}  >{name}</text>
  </g>
}

// шапка с датами ---------------------------------------
function DateLine({ zoom }) {
  const start = useSelector(formSelector(formName, 'start')) || moment()
  const end = useSelector(formSelector(formName, 'end')) || moment()
  const [arr, setArr] = useState({})
  useEffect(() => {
    const countDay = end.diff(start, 'days') + 10
    let date = moment(start)
    let res = {
      [date.format('MMMM YYYY')]: [{ d: date.format('D'), day: date.day(), m: date.format('MMM') }]
    }
    for (let i = 0; i < countDay; i++) {
      date.add(1, 'd')
      if (res[date.format('MMMM YYYY')]) {
        res[date.format('MMMM YYYY')].push({ d: date.format('D'), day: date.day(), m: date.format('MMM') })
      }
      else {
        res[date.format('MMMM YYYY')] = [{ d: date.format('D'), day: date.day(), m: date.format('MMM') }]
      }
    }
    setArr(res)
  }, [start, end])
  switch (zoom) {
    case 'day':
      return <DateLineDay arr={arr} />
    case 'week':
      return <DateLineWeek arr={arr} />
    case 'month':
      return <DateLineMonth arr={arr} />
    default:
      return null;
  }
}
function DateLineMonth({ arr }) {
  const classes = useStyles()
  const startDiag = useSelector(formSelector(formViewProps, 'startDiag')) || 1810
  let offset = startDiag
  const sizeD = svgProps.sizeMonth * 12 / 365
  const yearArr = {}
  const heightDateLine = 60  // ??????????? change to const
  Object.keys(arr).forEach(m => {
    const mSplit = m.split(' ')
    if (yearArr[mSplit[1]]) yearArr[mSplit[1]].push({ label: mSplit[0], size: arr[m].length })
    else yearArr[mSplit[1]] = [{ label: mSplit[0], size: arr[m].length }]
  })
  return <g id="sticky-rect" className={classes.sticky}>
    {Object.keys(yearArr).map((year) => {
      const months = yearArr[year]
      let sizeYear = 0
      months.forEach(e => { sizeYear += e.size })
      offset += sizeD * sizeYear
      let offsetMonth = 0
      return <g key={year} transform={`translate(${offset - sizeD * sizeYear})`}>
        <rect
          x={0}
          y={0}
          stroke="#a6a6a6"
          strokeWidth="1"
          fill="white"
          width={sizeD * sizeYear}
          height={heightDateLine - svgProps.sizeDay}
        />
        <text
          dominantBaseline="middle"
          textAnchor="middle"
          x={sizeD * sizeYear / 2}
          y={(heightDateLine - svgProps.sizeDay) / 2}
        >{year}</text>
        {months.map((m, i) => {
          const sizeMonth = m.size * sizeD
          offsetMonth += sizeMonth
          return <React.Fragment key={i}>
            <rect
              x={offsetMonth - sizeMonth}
              y={svgProps.sizeDay}
              stroke="#a6a6a6"
              strokeWidth="1"
              fill="white"
              width={sizeMonth}
              height={svgProps.sizeDay}
            />
            <text
              x={offsetMonth - sizeMonth + sizeMonth / 2}
              y={heightDateLine - svgProps.sizeDay + svgProps.sizeDay / 2}
              dominantBaseline="middle"
              textAnchor="middle"
            >{m.label}</text>
          </React.Fragment>
        })}
      </g>
    })}
  </g>
}
function DateLineWeek({ arr }) {
  const classes = useStyles()
  const startDiag = useSelector(formSelector(formViewProps, 'startDiag')) || 1810
  let offset = startDiag
  const heightDateLine = 60  // ??????????? change to const
  return <g id="sticky-rect" className={classes.sticky}  >
    {Object.keys(arr).map((month) => {
      const days = arr[month]
      const week = days.filter(e => e.day === 1)
      const incWeek = (week.length > 0) ? svgProps.sizeWeek * week.length : days.length * svgProps.sizeWeek / 7
      offset += incWeek
      return <g key={month} transform={`translate(${offset - incWeek})`}>
        <rect
          x={0}
          y={0}
          stroke="#a6a6a6"
          strokeWidth="1"
          fill="white"
          width={incWeek}
          height={heightDateLine - svgProps.sizeDay}
        />
        <text
          dominantBaseline="middle"
          textAnchor="middle"
          x={svgProps.sizeWeek * week.length / 2}
          y={(heightDateLine - svgProps.sizeDay) / 2}
        >{days.length > 1 ? month : moment().month(month).format('MMM')}</text>
        {week.length === 0 && days.length > 0 &&
          <React.Fragment >
            <rect
              x={0}
              y={svgProps.sizeDay}
              stroke="#a6a6a6"
              strokeWidth="1"
              fill="white"
              width={days.length * svgProps.sizeWeek / 7}
              height={svgProps.sizeDay}
            />
          </React.Fragment>
        }
        {week.map((day, i) => {
          return <React.Fragment key={i}>
            <rect
              x={i * svgProps.sizeWeek}
              y={svgProps.sizeDay}
              stroke="#a6a6a6"
              strokeWidth="1"
              fill="white"
              width={svgProps.sizeWeek}
              height={svgProps.sizeDay}
            />
            <text
              x={i * svgProps.sizeWeek + svgProps.sizeWeek / 2}
              y={heightDateLine - svgProps.sizeDay + svgProps.sizeDay / 2}
              dominantBaseline="middle"
              textAnchor="middle"
            >{day.d + ' ' + day.m}</text>
          </React.Fragment>
        })}
      </g>

    })}
    {/* <rect x={startDiag} y={0} stroke="#a6a6a6" strokeWidth="1" fill="white" fillOpacity={0} width={svgProps.sizeDay * (end.diff(start, 'days') + 10)} height={heightDateLine} /> */}
  </g>
}
function DateLineDay({ arr }) {
  const classes = useStyles()
  const startDiag = useSelector(formSelector(formViewProps, 'startDiag')) || 1810
  let offset = startDiag
  const heightDateLine = 60  // ??????????? change to const
  const [nowDate] = useState({
    month: moment().format('MMMM YYYY'),
    day: moment().format('D')
  })
  return <g id="sticky-rect" className={classes.sticky}  >
    {Object.keys(arr).map((month) => {
      const days = arr[month]
      offset += svgProps.sizeDay * days.length
      return <g key={month} transform={`translate(${offset - svgProps.sizeDay * days.length})`}>
        <rect
          x={0}
          y={0}
          stroke="#a6a6a6"
          strokeWidth="1"
          fill="white"
          width={svgProps.sizeDay * days.length}
          height={heightDateLine - svgProps.sizeDay}
        />
        <text
          dominantBaseline="middle"
          textAnchor="middle"
          x={svgProps.sizeDay * days.length / 2}
          y={(heightDateLine - svgProps.sizeDay) / 2}
        >{days.length > 1 ? month : moment().month(month).format('MMM')}</text>
        {days.map((day, i) => {
          const isNowDay = month === nowDate.month && day === nowDate.day
          return <React.Fragment key={i}>
            <rect
              x={i * svgProps.sizeDay}
              y={svgProps.sizeDay}
              stroke="#a6a6a6"
              strokeWidth="1"
              fill={isNowDay ? "yellow" : (day.day === 0 || day.day === 6) ? "#fc9292" : "white"}
              width={svgProps.sizeDay}
              height={svgProps.sizeDay}
            />
            <text
              x={i * svgProps.sizeDay + svgProps.sizeDay / 2}
              y={heightDateLine - svgProps.sizeDay + svgProps.sizeDay / 2}
              dominantBaseline="middle"
              textAnchor="middle"
            >{day.d}</text>
          </React.Fragment>
        })}
      </g>

    })}
    {/* <rect x={startDiag} y={0} stroke="#a6a6a6" strokeWidth="1" fill="white" fillOpacity={0} width={svgProps.sizeDay * (end.diff(start, 'days') + 10)} height={heightDateLine} /> */}
  </g>
}

// сетка------------------------------------------------
function GridTable({ heightSVG, zoom }) {
  const start = useSelector(formSelector(formName, 'start')) || moment()
  const end = useSelector(formSelector(formName, 'end')) || moment()
  const [arr, setArr] = useState({})
  const [countDay, setCountDay] = useState(10)
  useEffect(() => {
    const countDay = end.diff(start, 'days') + 10
    let date = moment(start)
    let res = {
      [date.format('MMMM YYYY')]: [{ d: date.format('D'), day: date.day(), m: date.format('MMM') }]
    }
    for (let i = 0; i < countDay; i++) {
      date.add(1, 'd')
      if (res[date.format('MMMM YYYY')]) {
        res[date.format('MMMM YYYY')].push({ d: date.format('D'), day: date.day(), m: date.format('MMM') })
      }
      else {
        res[date.format('MMMM YYYY')] = [{ d: date.format('D'), day: date.day(), m: date.format('MMM') }]
      }
    }
    setArr(res)
    setCountDay(countDay)
  }, [start, end])
  switch (zoom) {
    case 'day':
      return <GridTableZoomDay arr={arr} heightSVG={heightSVG} countDay={countDay} />
    case 'week':
      return <GridTableZoomWeek heightSVG={heightSVG} countDay={countDay} />
    case 'month':
      return <GridTableZoomMonth arr={arr} heightSVG={heightSVG} />
    default:
      return null;
  }
}
function GridTableZoomMonth({ arr, heightSVG }) {
  const startDiag = useSelector(formSelector(formViewProps, 'startDiag')) || 1810
  let offset = startDiag
  const sizeD = svgProps.sizeMonth * 12 / 365
  const yearArr = {}
  Object.keys(arr).forEach(m => {
    const mSplit = m.split(' ')
    if (yearArr[mSplit[1]]) yearArr[mSplit[1]].push({ label: mSplit[0], size: arr[m].length })
    else yearArr[mSplit[1]] = [{ label: mSplit[0], size: arr[m].length }]
  })
  return <g>
    {Object.keys(yearArr).map((year) => {
      const months = yearArr[year]
      let sizeYear = 0
      months.forEach(e => { sizeYear += e.size })
      offset += sizeD * sizeYear
      let offsetMonth = 0
      return <g key={year} transform={`translate(${offset - sizeD * sizeYear})`}>
        {months.map((m, i) => {
          const sizeMonth = m.size * sizeD
          offsetMonth += sizeMonth
          return <React.Fragment key={i}>
            <line
              key={m.label}
              x1={offsetMonth - sizeMonth}
              y1={60}
              x2={offsetMonth - sizeMonth}
              y2={heightSVG}
              stroke="#e0e0e0"
            />
          </React.Fragment>
        })}
      </g>
    })}
  </g>
}
function GridTableZoomWeek({ heightSVG, countDay }) {
  const startDiag = useSelector(formSelector(formViewProps, 'startDiag')) || 1810
  let offset = startDiag
  const heightDateLine = 60
  return <g transform={`translate(${offset})`}>
    <rect
      x={0}
      y={heightDateLine}
      fill="url(#patternGridWeek)"
      fillOpacity={0.1}
      width={svgProps.sizeWeek * countDay / 7}
      height={heightSVG}
      transform={`translate(${-svgProps.sizeWeek / 14},${-svgProps.yPadding + 5})`}
      stroke='none'
    />
  </g>
}
function GridTableZoomDay({ arr, heightSVG, countDay }) {
  const startDiag = useSelector(formSelector(formViewProps, 'startDiag')) || 1810
  let offset = startDiag
  const heightDateLine = 60
  return <g>
    <g transform={`translate(${offset})`}>
      <rect
        x={0}
        y={heightDateLine}
        fill="url(#patternGridDay)"
        fillOpacity={0.1}
        width={svgProps.sizeDay * countDay}
        height={heightSVG}
        transform={`translate(${-svgProps.sizeDay / 2},${-svgProps.yPadding - 5})`}
        stroke='none'
      />
    </g>
    {Object.keys(arr).map((month) => {
      const days = arr[month]
      offset += svgProps.sizeDay * days.length
      return <g key={month} transform={`translate(${offset - svgProps.sizeDay * days.length})`}>
        {days.map((day, i) => {
          return <React.Fragment key={i}>
            {(day.day === 0 || day.day === 6) && <rect
              x={i * svgProps.sizeDay}
              y={heightDateLine - svgProps.sizeDay}
              fill="#fc9292"
              fillOpacity={0.1}
              width={svgProps.sizeDay}
              height={heightSVG}
            />}
          </React.Fragment>
        })}
      </g>
    })}
  </g>
}