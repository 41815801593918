import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Table, TableBody, TableHead, TableRow, TableCell, TableSortLabel } from '@material-ui/core'
import * as actionsTable from '../../reducers/formTable';

const tableHead = [
  { name: 'Наименование', id: 'name' },
  { name: 'Тип, марка', id: 'typeMark' },
  { name: 'Артикул', id: 'vendorCode' },
  { name: 'Ед.изм.', id: 'storageUnit' },
  { name: 'Количество', align: 'right', id: 'storage' },
]
export default function ContractorStorage({ formStorage }) {
  const tableStorage = useSelector(state => state.formTable[formStorage]) || { byId: {}, allIds: [], sortBy: 'name', sortDirection: 'asc' }
  const dispatch = useDispatch()
  const handleRequestSort = (sortByNew) => {
    /** @type {"desc" | "asc"} */
    let sortDirectionNew = 'desc';
    if (tableStorage.sortBy === sortByNew && tableStorage.sortDirection === 'desc') {
      sortDirectionNew = 'asc';
    }
    dispatch(actionsTable.setSortFormTable({ sortBy: sortByNew, sortDirection: sortDirectionNew }, { name: formStorage }))
  };
  return <div>
    <Table size='small'>
      <TableHead>
        <TableRow>
          {tableHead.map(obj => {
            const align = obj.align || 'inherit'
            const padding = obj.padding || 'default'
            return (<TableCell
              key={obj.id}
              sortDirection={tableStorage.sortBy === obj.id ? tableStorage.sortDirection : false}
              align={align}
              padding={padding}
            >
              <TableSortLabel
                active={tableStorage.sortBy === obj.id}
                direction={tableStorage.sortDirection}
                onClick={() => handleRequestSort(obj.id)}
              >
                {obj.name}
              </TableSortLabel>
            </TableCell>);
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {tableStorage.allIds.map(key => {
          const obj = tableStorage.byId[key]
          return <TableRow key={key} >
            {tableHead.map(e => {
              const padding = e.padding || 'default'
              return <TableCell key={e.id} align={e.align} padding={padding}>{obj[e.id]}</TableCell>
            })}
          </TableRow>
        })}
      </TableBody>
    </Table>
  </div>
}