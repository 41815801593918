import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Input } from '@material-ui/core';
import classNames from 'classnames'
const useStyle = makeStyles(theme => {
  console.log(theme)
  return ({
    card: {
      ...theme.typography.body1,
      color: theme.palette.text.primary,
      lineHeight: '1.1876em',
      padding: `6px 0 7px`,
    },
    deleteBtn: {
      color: '#f0f'
    }
  })
})
export default function Test() {
  const classes = useStyle()
  console.log(classes)
  return <div style={{ fontSize: '2rem', padding: 10 }}>
    <div className={classNames(classes.deleteBtn, 'MuiInputBase-root')}>one</div>
    <div className={classes.card}>two</div>
    <Input>asd</Input>
  </div>
}

