import React, { useState, useEffect } from 'react'
import AutoCompliteWithLoad from '../../components/AutocompliteWithLoad'
import { getProjectContractor, getProjectContractorById } from '../../api/projects'
import { Tabs, Tab, } from '@material-ui/core';
import { useDispatch, batch } from 'react-redux';
import * as actions from '../../reducers/form';
import * as actionsTable from '../../reducers/formTable';
import { loadGantArrow, loadGantTree, clearGantThree } from '../../reducers/gant'

import { fetchDispath } from '../../functions/fetch'
import ContractorAnalytic from './ContractorAnalytic';
import ContractorEstimate from './ContractorEstimate';
import ContractorGant from './ContractorGant';
import ContractorStorage from './ContractorStorage';
import ContractorProgress from './ContractorProgress';
import 'moment/locale/ru';
import moment from 'moment'
import { typeGant } from '../../enum/gant.enum'
import { COLUMNS_OBJ, formViewProps, } from '../gant/gant.const'
export const COLUMNS_ARR = ['selected', 'pos', 'estNumber', 'name', 'start', 'end', 'count', 'storageUnit']

const formGName = 'groupEstimate'
const formRName = 'groupResEstimate'
const formStorage = 'contractorStorage'
const formGant = 'form-gant'
const formDialog = 'form-dialog'
const formView = 'form-view'

const projectAccessModuleInit = {
  estimate: true,
  gant: false,
  storage: false,
  detailed_progress: false
}
const oneRow = {
  id: '',
  idAPI: '',
  start: moment(new Date()),
  end: moment(new Date()).add(10, 'd'),
  startPlan: moment(new Date()),
  endPlan: moment(new Date()).add(10, 'd'),
  progress: 0,
  dependencies: [],
  name: '',
  idWork: null,
  idMaterial: null,
  duration: 1,
  pos: 0,
  idGroup: null,
  count: 0,
  textField: '',
  typeGant: typeGant[0].value,
  parent: null,
  parentValue: null,
  child: [],
  color: null,
  selected: false,
  idDateEndLink: null,
  idDateStartLink: null,
  idProjectLink: 0
}
export default function ContractorMain({ match, history, location }) {
  const [tabsId, setTabsId] = useState(0)
  const [accessModule, setAccessModule] = useState(projectAccessModuleInit)
  const { idProject } = match.params
  const [contractor, setContractor] = useState(null)
  const dispatch = useDispatch()
  useEffect(() => {

    dispatch(actionsTable.initFormTable({ name: formGName }))
    dispatch(actionsTable.initFormTable({ name: formRName }))
    dispatch(actionsTable.initFormTable({ name: formStorage }))

    const columnsArrHide = [];
    let startDiag = 0;
    COLUMNS_ARR.forEach(key => {
      const obj = COLUMNS_OBJ[key]
      if (!obj) return;
      if (!obj.hide) {
        startDiag += obj.width
      }
      else {
        columnsArrHide.push(key)
      }
    })

    dispatch(actions.formInitializeMulti({
      [formGant]: {
        byId: {},
        allIds: [],
        arrFilter: [],
        start: moment(),
        end: moment(),
        count: 0,
        idRow: 0
      },
      [formDialog]: {
        obj: oneRow,
        id: '',
        open: false,
        newRow: false,
        autocompliteParent: [],
      },
      [formView]: {
        obj: oneRow,
        id: '',
        open: false,
        x: 1000,
        y: 1000,
        height: 0
      },
      [formViewProps]: {
        columnsObj: COLUMNS_OBJ,
        columnsArrHide: columnsArrHide,
        startDiag: startDiag,
        planVisible: true,
      }
    }, { nameArr: [formGant, formDialog, formView, formViewProps] }))
    return () => {
      dispatch(actions.formDestroy({ name: formGant }))
      dispatch(actions.formDestroy({ name: formViewProps }))
      dispatch(actions.formDestroy({ name: formDialog }))
      dispatch(actions.formDestroy({ name: formView }))
      dispatch(actionsTable.clearFormTable(formGName))
      dispatch(actionsTable.clearFormTable(formRName))
      dispatch(actionsTable.clearFormTable(formStorage))

      dispatch(clearGantThree())
    }
  }, [dispatch])
  const handleSetContractor = (e) => {
    setContractor(e.value)
    dispatch(fetchDispath({
      progress: true,
      request: getProjectContractorById,
      param: {
        idProject: match.params.idProject,
        idParticipant: e.value
      }
    })).then(res => {
      const { access, analytic, estimate, gant, storage, detailedProgress } = res.get
      //estimate
      const group = {}
      estimate.group.forEach((obj, i) => {
        group[obj.id] = i
        obj.child = []
      })
      estimate.list.forEach((obj, i) => {
        estimate.group[group[obj.idGroup]].child.push('id' + obj.idParent + '_' + i)
      })
      dispatch(actionsTable.loadFormTable({ count: estimate.list.length, get: estimate.list }, { name: formRName, id: 'idParent' }))
      dispatch(actionsTable.loadFormTable({ count: estimate.group.length, get: estimate.group }, { name: formGName, id: 'id' }))
      if (access.storage) {
        dispatch(actionsTable.loadFormTable({ count: storage.length, get: storage }, { name: formStorage, id: 'idParent' }))
      }
      if (access.gant) {
        let byId = {}
        const arrStartDate = []
        const arrEndDate = []
        let parentChild = {}
        const dependencies = []
        const autocompliteParent = []
        let allIds = gant.get.map((e, i) => {
          const id = 'id' + e.id

          if (e.typeGant === 'parent') {
            if (parentChild[e.id]) {
              parentChild[e.id].row = id
              parentChild[e.id].name = e.name
            }
            else {
              parentChild[e.id] = { row: id, name: e.name, arr: [], start: null, end: null }
            }
          };

          let obj = {
            ...oneRow,
            ...e,
            id: id,
            idAPI: e.id,
            dependencies: e.dependencies || [],
            start: moment(e.start, 'YYYY-MM-DD'),
            end: moment(e.end, 'YYYY-MM-DD'),
            startPlan: moment(e.startPlan, 'YYYY-MM-DD'),
            endPlan: moment(e.endPlan, 'YYYY-MM-DD'),
            duration: 0,
          }
          obj.duration = obj.end.diff(obj.start, 'days')
          dependencies.push(...obj.dependencies.map(e => ({ fromId: obj.idAPI, ...e })))

          if (obj.parent) {
            if (!parentChild[obj.parent]) {
              parentChild[obj.parent] = {
                row: null,
                name: '',
                arr: [],
                start: moment(obj.start),
                end: moment(obj.end)
              }
            }
            parentChild[obj.parent].arr.push(id)
            const startP = (obj.startPlan.isValid()) ? moment.min([obj.startPlan, obj.start]) : moment(obj.start)
            const endP = (obj.endPlan.isValid()) ? moment.max([obj.endPlan, obj.end]) : moment(obj.end)
            if (!parentChild[e.parent].start || parentChild[obj.parent].start > startP) {
              parentChild[obj.parent].start = moment(startP)
            }
            if (!parentChild[e.parent].end || parentChild[obj.parent].end < endP) {
              parentChild[obj.parent].end = moment(endP)
            }
          }
          if (obj.typeGant !== 'parent') {
            arrStartDate.push(obj.start)
            arrStartDate.push(obj.startPlan)
            arrEndDate.push(obj.end)
            arrEndDate.push(obj.endPlan)
          }
          byId[id] = obj
          return id
        })
        allIds = gant.arraySort.map(e => 'id' + e)
        allIds.forEach(key => {
          const obj = byId[key]
          if (obj.parent) {
            const par = byId[parentChild[obj.parent].row]
            byId[key].parentValue = {
              value: par.id,
              label: par.pos + ' ' + par.name
            }
          }
          if (obj.typeGant === 'parent') {
            autocompliteParent.push({ value: obj.idAPI, label: obj.pos + ' ' + obj.name })
            const parent = parentChild[obj.idAPI]
            if (!parent || parent.arr.length === 0) { return; }
            byId[key].child = parent.arr
            byId[key].start = parent.start
            byId[key].end = parent.end
            byId[key].duration = parent.end.diff(parent.start, 'days')
            return;
          };
        })
        const allReverse = [...allIds].reverse()
        allReverse.forEach(key => {
          const obj = byId[key]
          const child = byId[key].child
          if (obj.typeGant === 'parent' && child.length > 0) {
            const arrStart = [...child.map(e => byId[e].start), ...child.map(e => byId[e].startPlan)].filter(e => e.isValid())
            const endStart = [...child.map(e => byId[e].end), ...child.map(e => byId[e].endPlan)].filter(e => e.isValid())
            const startP = moment.min(arrStart)
            const endP = moment.max(endStart)
            obj.start = moment(startP)
            obj.startPlan = moment(startP)
            obj.end = moment(endP)
            obj.endPlan = moment(endP)
          }
        })
        allIds.forEach(key => {
          const obj = byId[key]
          if (obj.dependencies.length === 0) return;
          const dependencies = []
          obj.dependencies.forEach(e => {
            const find = allIds.findIndex(s => byId[s].idAPI === e.to)
            const objTo = byId[allIds[find]]
            if (objTo.parent && obj.parent !== objTo.parent) {
              const findTo = allIds.findIndex(s => s === 'id' + objTo.parent)
              dependencies.push({
                ...e,
                id: objTo.parent,
                to: 'id' + objTo.parent,
                toIndex: findTo,
                parent: true
              });
            }
            dependencies.push({
              ...e,
              to: objTo.id,
              toIndex: find,
              parent: false
            });
          })
          if (obj.parent) {
            byId['id' + obj.parent].dependencies.push(...dependencies)
          }
          byId[key].dependencies = dependencies
        })
        let startNew = moment(moment.min(arrStartDate.filter(e => e.isValid())))
        let endNew = moment(moment.max(arrEndDate.filter(e => e.isValid())))
        if (!startNew.isValid()) startNew = moment()
        if (!endNew.isValid()) endNew = moment()
        startNew.add(-7, 'd')
        endNew.add(7, 'd')
        batch(() => {
          dispatch(actions.formChangeAsObj({
            byId, allIds,
            start: startNew,
            end: endNew,
            count: endNew.diff(startNew, 'days'),
            idRow: allIds.length + 1
          }, { name: formGant }))
          dispatch(loadGantTree(gant.tree))
          dispatch(loadGantArrow(dependencies))
          dispatch(actions.formChangeAsObj({ autocompliteParent }, { name: formDialog }))
        })
      }
      setAccessModule(old => ({ ...old, ...access }))
    }).catch(err => console.log(err))
  }
  return <div className='App-paper'>
    <AutoCompliteWithLoad
      classes={{}}
      field='idContractor'
      name='Подрядчик'
      path={getProjectContractor.path({ idProject: idProject })}
      meta={{
        field: 'idContractor',
        value: 'id',
        labelFunc: (obj) => obj.name
      }}
      form={false}
      change={handleSetContractor}
    />
    <Tabs
      value={tabsId}
      onChange={(e, v) => setTabsId(v)}
      indicatorColor='primary'
      style={{ borderBottom: '1px solid #e8e8e8', }}
    >
      <Tab disabled={!contractor} label='Аналитика' />
      <Tab disabled={!contractor || !accessModule.estimate} label='Смета' />
      <Tab disabled={!contractor || !accessModule.gant} label='Диаграмма ганта' />
      <Tab disabled={!contractor || !accessModule.storage} label='Склад' />
      <Tab disabled={!contractor || !accessModule.detailed_progress} label='Прогресс' />
    </Tabs>
    <TabSwitcher
      selected={tabsId}
      tabs={[
        { id: 'analytic', content: <ContractorAnalytic /> },
        { id: 'estimate', content: <ContractorEstimate formGName={formGName} formRName={formRName} /> },
        {
          id: 'gant', content: <ContractorGant COLUMNS_ARR={COLUMNS_ARR}
            formGant={formGant} formDialog={formDialog} formView={formView} />
        },
        { id: 'storage', content: <ContractorStorage formStorage={formStorage} /> },
        { id: 'progress', content: <ContractorProgress /> },
      ]}
    />
  </div>
}

function TabSwitcher({ tabs, selected }) {
  return <>{tabs[selected].content}</>
}