import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import {
  Table, TableBody, TableHead, TableRow, Typography,
  TableCell, TableSortLabel,
} from '@material-ui/core'

const tableHead = [
  { name: 'Номер', id: 'number', padding: 'checkbox' },
  { name: 'Тип', id: 'type', padding: 'checkbox' },
  { name: 'Наименование', id: 'name' },
  { name: 'Тип, марка', id: 'typeMark' },
  { name: 'Артикул', id: 'vendorCode' },
  { name: 'Ед.изм.', id: 'storageUnit' },
  { name: 'Количество', align: 'right', id: 'count' },
]
export default function ContractorEstimate({ formGName, formRName }) {
  const tableGroup = useSelector(state => state.formTable[formGName]) || { byId: {}, allIds: [] }
  const tableResource = useSelector(state => state.formTable[formRName]) || { byId: {}, allIds: [] }
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('number')
  const handleRequestSort = (event, property) => {
    let orderNew = 'desc';
    if (orderBy === property && order === 'desc') {
      orderNew = 'asc';
    }
    setOrder(orderNew)
    setOrderBy(property)
  };
  return <div>
    <Table size='small'>
      <TableHead>
        <TableRow>
          {tableHead.map(obj => {
            const align = obj.align || 'inherit'
            const padding = obj.padding || 'default'
            return (<TableCell
              key={obj.id}
              align={align}
              padding={padding}
              sortDirection={orderBy === obj.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === obj.id}
                direction={order}
                onClick={(e) => handleRequestSort(e, obj.id)}
              >
                {obj.name}
              </TableSortLabel>
            </TableCell>);
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {tableGroup.allIds.map(id => {
          return <Row
            key={id}
            tableHead={tableHead}
            tableResource={tableResource}
            group={tableGroup.byId[id]}
            order={order}
            orderBy={orderBy}
          />
        })}
      </TableBody>
    </Table>
  </div>
}

function desc(a, b) {
  if (b < a) {
    return -1;
  }
  if (b > a) {
    return 1;
  }
  return 0;
}
function stableSort(array, objs, orderBy, order) {
  const stabilizedThis = array.map(key => ([key, objs[key][orderBy]]));
  const ord = order === 'desc' ? 1 : -1
  stabilizedThis.sort((a, b) => {
    return ord * desc(a[1], b[1]);
  });
  return stabilizedThis.map(el => el[0]);
}

function Row({ tableHead, tableResource, group, order, orderBy, }) {
  const [show, changeShow] = useState(true)
  return (
    <>
      <TableRow>
        <TableCell onClick={() => changeShow(!show)} colSpan={8}>
          <Typography variant='body1'>{group.number + '. ' + group.name}</Typography>
        </TableCell>
      </TableRow>
      {
        show && stableSort(group.child, tableResource.byId, orderBy, order)
          .map(id => {
            const obj = tableResource.byId[id];
            return <TableRow key={id} >
              {tableHead.map(e => {
                const padding = e.padding || 'default'
                return <TableCell key={e.id} align={e.align} padding={padding}>{obj[e.id]}</TableCell>
              })}
            </TableRow>
          })}
    </>
  );
}

