import React from 'react'
import { Breadcrumbs } from '@material-ui/core';
import { useSelector, } from 'react-redux';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
  link: {
    textDecoration: 'none',
    color: '#fff',
    '&:hover': {
      color: '#000'
    },
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  ol: {
    flexWrap: 'nowrap'
  }
});
export default function ProjectPath() {
  const classes = useStyles();
  const header = useSelector(state => state.header)
  const arr = []
  if (header.facilityId !== 0) {
    arr.push({
      id: 'facility',
      href: '/facility/our/' + header.facilityId,
      label: header.facilityName
    })
  }
  if (header.projectId !== 0) {
    arr.push({
      id: 'project',
      href: '/projects/' + header.projectId,
      label: header.projectName
    })
  }
  return <>
    <Breadcrumbs 
    classes={{
      ol: classes.ol
    }}
    separator={<NavigateNextIcon fontSize="small" style={{ color: '#fff' }} />} 
    aria-label="breadcrumb"
    >
      {arr.map(obj => {
        return <Link key={obj.id} to={obj.href} className={classes.link}>
          {obj.label}
        </Link>
      })}
    </Breadcrumbs>
  </>
}