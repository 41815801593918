import React, { useEffect, useState } from 'react';
import {
  Button, Tabs, Tab, Typography, Table, DialogActions,
  Dialog, DialogTitle, DialogContent, TextField, IconButton,
  TableBody, TableCell, TableHead, TableRow, List, ListItem, ListItemText
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../reducers/form';
import { fetchDispath } from '../../functions/fetch'
import {
  getTenderOurById, delTenderOurLot, setTenderStatus, getTenderOurContact,
  addTenderOurContact, updTenderOurContact, delTenderOurContact, setTenderOurBid
} from '../../api/tender.api'
import moment from 'moment'
import { TENDER_STATUS } from './TenderOurList'
import { TENDER_STATUS_BID } from '../tender/TenderMyRequestOne'
import { Link } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import ResultTender from './tenderOurOne/ResultTender'
import AssignmentIcon from '@material-ui/icons/Assignment';

export const formName = 'TenderOurOneHeader'
export const formContacts = 'TenderContact'
export const formLots = 'TenderLots'
export const formBid = 'TenderBid'

export default function TenderOurOne({ match, history }) {
  const dispatch = useDispatch()
  const [tabsId, setTabsId] = useState(0)
  useEffect(() => {
    dispatch(actions.formInitializeMulti({
      [formName]: {
        name: '',
        statusRus: '',
        facilityName: '',
        typeTenderRus: '',
        dateConfirmStart: '',
        dateConfirmEnd: '',
        dateOfferEnd: '',
        dateSummingEnd: '',
        accessKey: '',
        uuid: '',
      },
      [formContacts]: { byId: {}, allIds: [], },
      [formBid]: { byId: {}, allIds: [], },
      [formLots]: { projectsArr: [], projectsObj: {}, estimateObj: {} },
    }, { nameArr: [formName, formContacts, formBid, formLots] }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
      dispatch(actions.formDestroy({ name: formContacts }))
      dispatch(actions.formDestroy({ name: formLots }))
      dispatch(actions.formDestroy({ name: formBid }))
    }
  }, [dispatch])
  useEffect(() => {
    dispatch(fetchDispath({
      progress: true,
      request: getTenderOurById,
      param: {
        id: match.params.idDoc
      }
    })).then(res => {
      dispatch(actions.formChangeAsObj({
        name: res.get.name,
        facilityName: res.get.facilityName,
        dateConfirmStart: moment(res.get.dateConfirmStart).format('LLL'),
        dateConfirmEnd: moment(res.get.dateConfirmEnd).format('LLL'),
        dateOfferEnd: moment(res.get.dateOfferEnd).format('LLL'),
        dateSummingEnd: moment(res.get.dateSummingEnd).format('LLL'),
        typeTenderRus: res.get.typeTender === 'open' ? 'открытый' : 'закрытый',
        statusRus: TENDER_STATUS.find(s => s.value === res.get.status).label,
        regionsArr: res.get.regions,
        regions: res.get.regions.join(', '),
        specializationsArr: res.get.specializations,
        specializations: res.get.specializations.join(', '),
        accessKey: res.get.accessKey,
        uuid: res.get.uuid,
      }, { name: formName }))
      //contacts
      let byId = {}
      const allIds = res.get.contacts.map(e => {
        const newId = 'id' + e.id;
        byId[newId] = { ...e, fio: [e.surname, e.name, e.patronymic].join(' ') }
        return newId
      });
      dispatch(actions.formChangeAsObj({ byId, allIds }, { name: formContacts }))
      //bid
      let byIdBid = {}
      const allIdsBid = res.get.bid.map(e => {
        const newId = 'id' + e.id;
        byIdBid[newId] = { ...e, statusRus: TENDER_STATUS_BID.find(s => s.value === e.status).label, }
        return newId
      });
      dispatch(actions.formChangeAsObj({ byId: byIdBid, allIds: allIdsBid }, { name: formBid }))

      const { estimate, projects } = res.get.estimate;
      const projectsArr = []
      let projectsObj = {}
      projects.forEach(obj => {
        projectsArr.push(obj.id)
        let groupObj = {}
        const groupArr = obj.group.map(e => {
          groupObj[e.id] = { ...e, child: [] };
          return e.id
        })
        projectsObj[obj.id] = {
          id: obj.id,
          dateStart: moment(obj.dateStart).format('DD.MM.YY'),
          dateEnd: moment(obj.dateEnd).format('DD.MM.YY'),
          name: obj.name,
          group: {
            arr: groupArr,
            obj: groupObj,
          }
        }
      })
      let estimateObj = {}
      estimate.forEach(e => {
        estimateObj[e.id] = {
          ...e,
          dateStart: moment(e.dateStart).format('DD.MM.YY'),
          dateEnd: moment(e.dateEnd).format('DD.MM.YY'),
          typeEstimate: e.typeEstimate === 'material' ? 'М' : 'Р'
        }
        const groupObj = projectsObj[e.idProject]?.group.obj[e.idGroup]
        if (groupObj) {
          groupObj?.child.push(e.id);
          groupObj.price = sumPriceGroup(groupObj?.price, e.price, e.count)
        }
      })
      dispatch(actions.formChangeAsObj({ projectsArr, projectsObj, estimateObj }, { name: formLots }))

    }).catch(err => console.log(err))
  }, [dispatch, match.params.idDoc])
  return <div className='App-paper'>
    <HederProps idDoc={match.params.idDoc} />
    <Tabs
      value={tabsId}
      onChange={(e, v) => setTabsId(v)} indicatorColor='primary'
      style={{ borderBottom: '1px solid #e8e8e8', }}
    >
      <Tab label='Контакты' />
      <Tab label='Лоты' />
      <Tab label='Подали заявки' />
      <Tab label='Отправили заявку' />
    </Tabs>
    {tabsId === 0 && <Contacts idDoc={match.params.idDoc} />}
    {tabsId === 1 && <Lots match={match} />}
    {tabsId === 2 && <AccessTender idDoc={match.params.idDoc} />}
    {tabsId === 3 && <ResultTender formLots={formLots} formBid={formBid} />}
    <Button color="primary" onClick={() => history.goBack()}>Назад </Button>
  </div>
}

function sumPriceGroup(objLast = {}, objNew = {}, count) {
  const objResult = {}
  const accounts = [...new Set([...Object.keys(objLast), ...Object.keys(objNew)])];
  accounts.forEach(key => {
    const iter = (Math.round((objNew[key] || 0) * count * 100) / 100)
    objResult[key] = (objLast[key] || 0) + iter
  })
  return objResult
}

const headerCol = [
  { id: 'name', name: 'Название' },
  { id: 'statusRus', name: 'Статус' },
  { id: 'facilityName', name: 'Объект' },
  { id: 'typeTenderRus', name: 'Тип тендера' },
  { id: 'dateConfirmStart', name: 'Дата начала подтверждения участия', },
  { id: 'dateConfirmEnd', name: 'Дата окончания подтверждения участия', },
  { id: 'dateOfferEnd', name: 'Дата окончания предложения', },
  { id: 'dateSummingEnd', name: 'Дата подведения итогов', },
  { id: 'regions', name: 'Регионы', },
  { id: 'specializations', name: 'Специализация', },
]
function HederProps({ idDoc }) {
  const values = useSelector(state => state.form[formName]?.values) || {}
  const handleClick = (value) => {
    navigator.clipboard.writeText(value)
  }
  return <div>
    {headerCol.map(obj => {
      return <Typography variant='body1' key={obj.id}>{obj.name}:  {values[obj.id]}</Typography>
    })}
    <Typography variant='body1'>Доступ к тендеру</Typography>
    <div>
      <Typography variant='body1' style={{ display: 'inline' }}>UUID: {values.uuid}</Typography>
      <IconButton color="default" size='small' onClick={() => handleClick(values.uuid)}>
        <AssignmentIcon />
      </IconButton>
    </div>
    <div>
      <Typography variant='body1' style={{ display: 'inline' }}>ключ: {values.accessKey}</Typography>
      <IconButton size='small' onClick={() => handleClick(values.accessKey)}>
        <AssignmentIcon />
      </IconButton>
    </div>
    <SetStatus idDoc={idDoc} />

  </div>
}
const contactsCol = [
  { id: 'surname', type: 'text', name: 'Фамилия' },
  { id: 'name', type: 'text', name: 'Имя' },
  { id: 'patronymic', type: 'text', name: 'Отчество' },
  { id: 'email', type: 'text', name: 'Почта' },
  { id: 'phone', type: 'text', name: 'Телефон' },
  { id: 'jobTitle', type: 'text', name: 'Должность' },
  { id: 'additionalContacts', type: 'text', name: 'Доп. контакт' },
]
function Contacts({ idDoc }) {
  const [open, setOpen] = useState(false)
  const [keyContacts, setKeyContacts] = useState(null)
  const values = useSelector(state => state.form[formContacts]?.values) || { byId: {}, allIds: [] }
  const dispatch = useDispatch()
  const handleSelectContact = (key) => {
    setKeyContacts(key)
    setOpen(true)
  }
  const handleCloseDialog = () => {
    setOpen(false)
    setKeyContacts(null)
  }
  const updContacts = () => {
    dispatch(fetchDispath({
      progress: true,
      request: getTenderOurContact,
      param: {
        id: idDoc
      }
    })).then(res => {
      let byId = {}
      const allIds = res.get.map(e => {
        const newId = 'id' + e.id;
        byId[newId] = { ...e, fio: [e.surname, e.name, e.patronymic].join(' ') }
        return newId
      });
      dispatch(actions.formChangeAsObj({ byId, allIds }, { name: formContacts }))

    }).catch(err => console.log(err))
  }
  const handleDelete = (idContact) => {
    dispatch(fetchDispath({
      progress: true,
      request: delTenderOurContact,
      param: {
        id: idDoc,
        idContact: idContact
      },
    })).then(() => {
      updContacts()
    })
  }
  return <div>
    <ContactsDialog
      idDoc={idDoc}
      open={open}
      onClose={handleCloseDialog}
      updContacts={() => { updContacts() }}
      keyContacts={keyContacts}
    />
    <Button color="primary" onClick={() => setOpen(true)}>Добавить </Button>
    <Table size='small'>
      <TableHead>
        <TableRow>
          <TableCell padding='checkbox'>№</TableCell>
          {contactsCol.map(obj => {
            return <TableCell key={obj.id}>{obj.name}</TableCell>
          })}
          <TableCell>Удалить</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {values.allIds.map((key, i) => {
          return <TableRow key={key} style={{ cursor: 'pointer' }} >
            <TableCell padding='checkbox'>{i + 1}</TableCell>
            {contactsCol.map(obj => {
              return <TableCell key={obj.id} onClick={() => handleSelectContact(key)}>{values.byId[key][obj.id]}</TableCell>
            })}
            <TableCell>
              <IconButton size='small' onClick={(e) => { e.preventDefault(); handleDelete(values.byId[key].id) }}>
                <DeleteIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        })}
      </TableBody>
    </Table>
  </div>
}
const contactsInit = {
  surname: '',
  name: '',
  patronymic: '',
  email: '',
  phone: '',
  jobTitle: '',
  additionalContacts: '',
}
function ContactsDialog({ idDoc, open, onClose, keyContacts, updContacts }) {
  const values = useSelector(state => state.form[formContacts]?.values) || { byId: {}, allIds: [] }
  const [contact, setContact] = useState(contactsInit)
  const dispatch = useDispatch()
  useEffect(() => {
    if (!open) return
    if (!keyContacts) return
    const obj = values.byId[keyContacts]
    setContact({
      surname: obj.surname,
      name: obj.name,
      patronymic: obj.patronymic,
      email: obj.email,
      phone: obj.phone,
      jobTitle: obj.jobTitle,
      additionalContacts: obj.additionalContacts,
    })
  }, [open, keyContacts, values.byId])
  const handleChangeValue = (value, key) => {
    setContact(old => ({ ...old, [key]: value }))
  }
  const handleClose = () => {
    onClose()
    setContact(contactsInit)
  }
  const handleUpdContacts = () => {
    const obj = values.byId[keyContacts]
    dispatch(fetchDispath({
      progress: true,
      request: updTenderOurContact,
      param: {
        id: idDoc,
        idContact: obj.id
      },
      body: {
        surname: contact.surname,
        name: contact.name,
        patronymic: contact.patronymic,
        email: contact.email,
        phone: contact.phone,
        jobTitle: contact.jobTitle,
        additionalContacts: contact.additionalContacts,
      }
    })).then(() => {
      updContacts()
      handleClose()
    })
  }
  const handleAddContacts = () => {
    dispatch(fetchDispath({
      progress: true,
      request: addTenderOurContact,
      param: {
        id: idDoc,
      },
      body: {
        surname: contact.surname,
        name: contact.name,
        patronymic: contact.patronymic,
        email: contact.email,
        phone: contact.phone,
        jobTitle: contact.jobTitle,
        additionalContacts: contact.additionalContacts,
      }
    })).then(() => {
      updContacts()
      handleClose()
    })
  }

  return <Dialog
    open={open}
    // maxWidth='xl'
    keepMounted
    fullWidth
    scroll="paper"
    onClose={() => handleClose()}
    aria-labelledby="alert-dialog-title"
    closeAfterTransition={false}
  >
    <DialogTitle id="alert-dialog-title">{keyContacts ? 'Изменить контакт' : 'Добавить контакт'}</DialogTitle>
    <DialogContent>
      {contactsCol.map(col => {
        return <TextField
          key={col.id}
          label={col.name}
          value={contact[col.id]}
          type="text"
          onChange={(e) => handleChangeValue(e.target.value, col.id)}
          fullWidth
        />
      })}
    </DialogContent>
    <DialogActions>
      <Button onClick={() => handleClose()} color="primary">
        Отмена
      </Button>
      <Button onClick={() => keyContacts ? handleUpdContacts() : handleAddContacts()} color="primary" autoFocus>
        {keyContacts ? 'Изменить' : 'Добавить'}
      </Button>
    </DialogActions>
  </Dialog>
}

const tableHead = [
  { name: 'Номер', id: 'number', padding: 'checkbox' },
  { name: 'Тип', id: 'typeEstimate', padding: 'checkbox' },
  { name: 'Наименование', id: 'name', padding: 'default' },
  { name: 'Ед.изм.', id: 'storageUnit', padding: 'default' },
  { name: 'Количество', align: 'right', id: 'count', padding: 'default' },
  { name: 'Дата начала', align: 'right', id: 'dateStart', padding: 'default' },
  { name: 'Дата окончания', align: 'right', id: 'dateEnd', padding: 'default' },
]
function Lots({ match }) {
  const values = useSelector(state => state.form[formLots]?.values) || { projectsArr: [], projectsObj: {}, estimateObj: {} }
  const toLinkAdd = React.forwardRef((propsRef, ref) => (<Link to={match.url + '/lots/add'} {...propsRef} />))
  const dispatch = useDispatch()
  const handleLotDelete = (id) => {
    dispatch(fetchDispath({
      progress: true,
      request: delTenderOurLot,
      param: {
        id: match.params.idDoc,
        idProject: id
      }
    })).then((res) => {
      if (res.success) {
        const { [id]: value, ...other } = values.projectsObj
        dispatch(actions.formArrayDelete(id, { name: formLots, field: 'projectsArr' }))
        dispatch(actions.formChangeAsObj({ projectsObj: other }, { name: formLots }))
      }
    })
  }
  return <div>
    <Button component={toLinkAdd} color="primary" >Добавить Лот</Button>
    {values.projectsArr.map(key => {
      const project = values.projectsObj[key]
      return <div key={key} style={{ marginBottom: 20 }}>
        <Typography variant='h6'>{project.name}</Typography>
        <div>Дата работ с:  {project.dateStart} по:  {project.dateEnd}</div>
        <Button onClick={() => handleLotDelete(project.id)} color="primary">Удалить</Button>
        <Table size='small'>
          <TableHead>
            <TableRow>
              {tableHead.map(obj => {
                const align = obj.align || 'inherit'
                return (<TableCell key={obj.id} align={align} padding={obj.padding}>{obj.name}</TableCell>);
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {project.group.arr.map(groupKey => {
              return <Row
                key={groupKey}
                obj={project.group.obj[groupKey]}
                child={project.group.obj[groupKey].child}
                estimate={values.estimateObj}
              />
            })}
          </TableBody>
        </Table>
      </div>
    })}
  </div>
}

function Row({ obj, child, estimate }) {
  const [show, changeShow] = useState(true)
  return (
    <React.Fragment>
      <TableRow style={{ backgroundColor: '#eee' }} onClick={() => changeShow(!show)}>
        <TableCell padding='checkbox'>{obj.numberPos}</TableCell>
        <TableCell colSpan={6}>{obj.name}</TableCell>
      </TableRow>
      {
        show && child.map((id, i) => {
          const est = { ...estimate[id], number: obj.numberPos + '.' + (i + 1) };
          return <TableRow key={id} >
            {tableHead.map(e => {
              return <TableCell key={e.id} align={e.align} padding={e.padding}>{est[e.id]}</TableCell>
            })}
          </TableRow>
        })}
    </React.Fragment>
  );
}
const tableBidColumn = [
  { name: 'Наименование', id: 'accountName' },
  { name: 'Статус', id: 'statusRus' },
  { name: 'Контакты', id: 'contacts' },
  { name: 'Почта', id: 'email' },
  { name: 'ИНН', id: 'inn' },
]
function AccessTender({ idDoc }) {
  const [open, setOpen] = useState(false)
  const [dialogProps, setDialogProps] = useState({ id: '', status: 'none', key: '' })
  const values = useSelector(state => state.form[formBid]?.values) || { byId: {}, allIds: [] }
  const handleOpenStatus = (id, status, key) => {
    setDialogProps({ id, status, key })
    setOpen(true)
  }
  const dispatch = useDispatch()
  const handleSetStatus = (status) => {
    dispatch(fetchDispath({
      progress: true,
      request: setTenderOurBid,
      param: {
        id: idDoc,
      },
      body: {
        status: status,
        idBid: dialogProps.id
      }
    })).then(() => {
      dispatch(actions.formObjChangeObj({
        status: status,
        statusRus: TENDER_STATUS_BID.find(s => s.value === status)?.label
      }, { field: 'byId', id: dialogProps.key, name: formBid }))
      setOpen(false)
    })
  }
  return <div>
    <Table size='small'>
      <TableHead>
        <TableRow>
          <TableCell padding='checkbox'>№</TableCell>
          {tableBidColumn.map(obj => {
            const align = obj.align || 'inherit'
            return (<TableCell key={obj.id} align={align}>{obj.name}</TableCell>);
          })}
          <TableCell>Установить статус</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {values.allIds.map((key, i) => {
          const bid = values.byId[key]
          return <TableRow key={key}>
            <TableCell padding='checkbox'>{i + 1}</TableCell>
            {tableBidColumn.map(obj => {
              return <TableCell key={bid.id + '_' + obj.id}>{bid[obj.id]}</TableCell>
            })}
            <TableCell>
              <Button size='small' color="primary" onClick={() => handleOpenStatus(bid.id, bid.status, key)}>Установить</Button>
            </TableCell>
          </TableRow>
        })}
      </TableBody>
    </Table>
    <Dialog
      open={open}
      // maxWidth='xl'
      keepMounted
      fullWidth
      scroll="paper"
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
    >
      <DialogTitle id="alert-dialog-title">Установить статус</DialogTitle>
      <DialogContent>
        <List>
          {TENDER_STATUS_BID.filter(e => e.value !== 'none').map(obj => {
            return <ListItem
              key={obj.value}
              button
              onClick={() => handleSetStatus(obj.value)}
              disabled={dialogProps.status === obj.value}
            >
              <ListItemText inset primary={obj.label} />
            </ListItem>
          })}
        </List>
      </DialogContent>
    </Dialog>
  </div>
}

function SetStatus({ idDoc }) {
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()
  const handleSet = (status) => {
    dispatch(fetchDispath({
      progress: true,
      request: setTenderStatus,
      param: {
        id: idDoc,
      },
      body: {
        status: status,
      }
    })).then(() => {
      dispatch(actions.formChangeAsObj({
        statusRus: TENDER_STATUS.find(s => s.value === status).label
      }, { name: formName }))
      setOpen(false)
    })
  }
  return <div>
    <Button color="primary" onClick={() => setOpen(true)}>Установить статус</Button>
    <Dialog
      open={open}
      // maxWidth='xl'
      keepMounted
      fullWidth
      scroll="paper"
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
    // PaperProps={{ style: { minHeight: 500 } }}
    >
      <DialogTitle id="alert-dialog-title">Установить статус</DialogTitle>
      <DialogContent>
        <List>
          {TENDER_STATUS.map(obj => {
            return <ListItem key={obj.value} button onClick={() => handleSet(obj.value)}>
              <ListItemText inset primary={obj.label} />
            </ListItem>
          })}
        </List>
      </DialogContent>
    </Dialog>
  </div>
}

