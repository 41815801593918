import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Table, TableBody, TableCell, TableRow, TableHead, IconButton } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import AddProjectToUser from './AddProjectToUser'

export default function ByIdProjectList({ deleteProject, idUser, formName }) {
  const [addProject, setAddProject] = useState(false)
  const formTable = useSelector(state => state.formTable[formName] || state.formTable.default)
  const handleAddProject = () => setAddProject(!addProject)
  const { allIds, byId } = formTable
  let prevFacility = ''
  return <div>
    {addProject &&
      <AddProjectToUser
        open={addProject}
        hangleOpen={handleAddProject}
        idUser={idUser}
      />
    }
    <Table>
      <TableHead>
        <TableRow>
          <TableCell style={{ width: 10 }} padding={'none'}>
            <IconButton onClick={handleAddProject}  ><AddIcon /></IconButton>
          </TableCell>
          <TableCell>Объект</TableCell>
          <TableCell>Проект</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {allIds.map(id => {
          const obj = byId[id];
          let facilityName = obj.facility;
          if (prevFacility === facilityName) {
            facilityName = ''
          }
          else {
            prevFacility = facilityName
          }
          return <TableRow key={id}>
            <TableCell style={{ width: 10 }} padding={'none'}>
              <IconButton
                aria-label="Delete"
                onClick={(e) => { e.stopPropagation(); deleteProject(id, obj); }}
              ><DeleteIcon /></IconButton>
            </TableCell>
            <TableCell>{facilityName}</TableCell>
            <TableCell>{obj.project}</TableCell>
          </TableRow>
        })}
      </TableBody>
    </Table>
  </div>
}
