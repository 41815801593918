import React, { useEffect, useState } from 'react';
import {
  Button, Tabs, Tab, Typography, Table,
  TableBody, TableCell, TableHead, TableRow,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../reducers/form';
import { fetchDispath } from '../../functions/fetch'
import { getOneTenders, setTenderOpenBid } from '../../api/tender.api'
import moment from 'moment'
import { ContactsTenderAll, TENDER_STATUS_BID } from './TenderMyRequestOne'

const formName = 'TenderOurOneHeader'
const formContacts = 'TenderContact'
const formLots = 'TenderLots'
export default function TenderOpenById({ match, history }) {
  const dispatch = useDispatch()
  const [tabsId, setTabsId] = useState(0)
  useEffect(() => {
    dispatch(actions.formInitializeMulti({
      [formName]: {
        name: '',
        statusRus: '',
        facilityName: '',
        typeTenderRus: '',
        dateConfirmStart: '',
        dateConfirmEnd: '',
        dateOfferEnd: '',
        dateSummingEnd: '',
        statusBidRus:'',
      },
      [formContacts]: { byId: {}, allIds: [], },
      [formLots]: { projectsArr: [], projectsObj: {}, estimateObj: {} }
    }, { nameArr: [formName, formContacts, formLots] }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
      dispatch(actions.formDestroy({ name: formContacts }))
      dispatch(actions.formDestroy({ name: formLots }))
    }
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchDispath({
      progress: true,
      request: getOneTenders,
      param: {
        id: match.params.idDoc
      }
    })).then(res => {
      dispatch(actions.formChangeAsObj({
        name: res.get.name,
        facilityName: res.get.facilityName,
        dateConfirmStart: moment(res.get.dateConfirmStart).format('LLL'),
        dateConfirmEnd: moment(res.get.dateConfirmEnd).format('LLL'),
        dateOfferEnd: moment(res.get.dateOfferEnd).format('LLL'),
        dateSummingEnd: moment(res.get.dateSummingEnd).format('LLL'),
        typeTenderRus: res.get.typeTender === 'open' ? 'открытый' : 'закрытый',
        regionsArr: res.get.regions,
        regions: res.get.regions.join(', '),
        specializationsArr: res.get.specializations,
        specializations: res.get.specializations.join(', '),
        statusBidRus: TENDER_STATUS_BID.find(s => s.value === res.get.statusBid).label,
      }, { name: formName }))
      let byId = {}
      const allIds = res.get.contacts.map(e => {
        const newId = 'id' + e.id;
        byId[newId] = { ...e, fio: [e.surname, e.name, e.patronymic].join(' ') }
        return newId
      });
      dispatch(actions.formChangeAsObj({ byId, allIds }, { name: formContacts }))
      const { estimate, projects } = res.get.estimate;
      const projectsArr = []
      let projectsObj = {}
      projects.forEach(obj => {
        projectsArr.push(obj.id)
        let groupObj = {}
        const groupArr = obj.group.map(e => {
          groupObj[e.id] = { ...e, child: [] };
          return e.id
        })
        projectsObj[obj.id] = {
          id: obj.id,
          dateStart: moment(obj.dateStart).format('DD.MM.YYYY'),
          dateEnd: moment(obj.dateEnd).format('DD.MM.YYYY'),
          name: obj.name,
          group: {
            arr: groupArr,
            obj: groupObj,
          }
        }
      })
      let estimateObj = {}
      estimate.forEach(e => {
        estimateObj[e.id] = {
          ...e,
          dateStart: moment(e.dateStart).format('DD.MM.YYYY'),
          dateEnd: moment(e.dateEnd).format('DD.MM.YYYY'),
          typeEstimate: e.typeEstimate === 'material' ? 'М' : 'Р',
        }
        projectsObj[e.idProject]?.group.obj[e.idGroup]?.child.push(e.id);
      })
      dispatch(actions.formChangeAsObj({ projectsArr, projectsObj, estimateObj }, { name: formLots }))

    }).catch(err => console.log(err))
  }, [dispatch, match.params.idDoc])

  return <div className='App-paper'>
    <HederProps
      history={history}
      request={{
        request: setTenderOpenBid,
        param: {
          id: match.params.idDoc,
        },
      }}
      urlSuccess={`${match.url.replace('/open', '/my-request')}`}
    />
    <Tabs
      value={tabsId}
      onChange={(e, v) => setTabsId(v)}
      indicatorColor='primary'
      style={{ borderBottom: '1px solid #e8e8e8', }}
    >
      <Tab label='Контакты' />
      <Tab label='Лоты' />
    </Tabs>
    {tabsId === 0 && <ContactsTenderAll />}
    {tabsId === 1 && <Lots />}
    <Button color="primary" onClick={() => history.goBack()}>Назад </Button>
  </div>
}

const headerCol = [
  { id: 'name', name: 'Название' },
  { id: 'facilityName', name: 'Объект' },
  { id: 'typeTenderRus', name: 'Тип тендера' },
  { id: 'dateConfirmStart', name: 'Дата начала подтверждения участия', },
  { id: 'dateConfirmEnd', name: 'Дата окончания подтверждения участия', },
  { id: 'dateOfferEnd', name: 'Дата окончания предложения', },
  { id: 'dateSummingEnd', name: 'Дата подведения итогов', },
  { id: 'regions', name: 'Регионы', },
  { id: 'specializations', name: 'Специализация', },
]
export function HederProps({ history, request, urlSuccess }) {
  const values = useSelector(state => state.form[formName]?.values) || {}
  const dispatch = useDispatch()
  const handleSetOrder = () => {
    dispatch(fetchDispath({
      progress: true,
      ...request,

    })).then(() => {
      history.replace(urlSuccess)
    })
  }
  return <div>
    {headerCol.map(obj => {
      return <div key={obj.id}>{obj.name}:  {values[obj.id]}</div>
    })}
    <Typography style={{ fontWeight: 'bold' }}>Статус заявки: {values.statusBidRus}</Typography>
    <Button color="primary" onClick={handleSetOrder}>Подать заявку</Button>
  </div>
}

const tableHead = [
  { name: 'Номер', id: 'number' },
  { name: 'Тип', id: 'typeEstimate' },
  { name: 'Наименование', id: 'name' },
  { name: 'Ед.изм.', id: 'storageUnit' },
  { name: 'Количество', align: 'right', id: 'count' },
  { name: 'Дата начала', align: 'right', id: 'dateStart' },
  { name: 'Дата окончания', align: 'right', id: 'dateEnd' },
]
export function Lots() {
  const values = useSelector(state => state.form[formLots]?.values) || { projectsArr: [], projectsObj: {}, estimateObj: {} }
  const handleDownload = () => {
    const data = ['\ufeff __ \n']
    const column = [
      ...tableHead.map(e => e.name),
      'Цена', 'Сумма', 'Дата начала', 'Дата окончания'
    ].join(';') + '\n'
    values.projectsArr.forEach(key => {
      const project = values.projectsObj[key]
      data.push(`;${project.name}\n`)
      data.push([';Дата работ с', project.dateStart, 'по', project.dateEnd].join(';') + '\n')
      data.push(column)
      project.group.arr.forEach(groupKey => {
        const obj = project.group.obj[groupKey]
        data.push(`${obj.numberPos};${obj.name}\n`)
        const child = project.group.obj[groupKey].child
        child.forEach((id, i) => {
          const est = { ...values.estimateObj[id], number: obj.numberPos + '.' + (i + 1) };
          const str = [
            ...tableHead.map(e => {
              if (e.id === 'name') {
                return est[e.id].replace(/;/g, ' ')
              }
              return est[e.id]
            }),
            est.choosePrice,
            (Math.round(est.choosePrice * est.count * 100) / 100),
            est.chooseDateStart,
            est.chooseDateEnd,
          ]
          data.push(str.join(';') + '\n')
        })
      })
    })
    const file = new Blob(data, { type: 'text/csv' });
    if (window.navigator.msSaveOrOpenBlob) // IE10+
      window.navigator.msSaveOrOpenBlob(file, 'tender.csv');
    else { // Others
      var a = document.createElement("a"),
        url = URL.createObjectURL(file);
      a.href = url;
      a.download = 'tender.csv';
      document.body.appendChild(a);
      a.click();
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  }
  return <div>
    <Button color="primary" onClick={handleDownload}>Скачать </Button>
    {values.projectsArr.map(key => {
      const project = values.projectsObj[key]
      return <div key={key} style={{ marginBottom: 20 }}>
        <Typography variant='h6'>{project.name}</Typography>
        <div>Дата работ с:  {project.dateStart} по:  {project.dateEnd}</div>
        <Table size='small'>
          <TableHead>
            <TableRow>
              {tableHead.map(obj => {
                const align = obj.align || 'inherit'
                return (<TableCell key={obj.id} align={align}>{obj.name}</TableCell>);
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {project.group.arr.map(groupKey => {
              return <Row
                key={groupKey}
                obj={project.group.obj[groupKey]}
                child={project.group.obj[groupKey].child}
                estimate={values.estimateObj}
              />
            })}
          </TableBody>
        </Table>
      </div>
    })}
  </div>
}

function Row({ obj, child, estimate }) {
  const [show, changeShow] = useState(true)
  return <React.Fragment>
    <TableRow style={{ backgroundColor: '#eee', cursor: 'pointer' }} onClick={() => changeShow(!show)}>
      <TableCell>{obj.numberPos}</TableCell>
      <TableCell colSpan={10}>{obj.name}</TableCell>
    </TableRow>
    {
      show && child.map((id, i) => {
        const est = { ...estimate[id], number: obj.numberPos + '.' + (i + 1) };
        return <TableRow key={id} >
          {tableHead.map(e => {
            return <TableCell key={e.id} align={e.align}>{est[e.id]}</TableCell>
          })}
        </TableRow>
      })}
  </React.Fragment>
}