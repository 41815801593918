import template from "./template_crud";
const tariff = '/tariff'

const plans = '/plans'
export const getPlanListAPI = template.getList(tariff + plans);
export const addPlanAPI = template.addOne(tariff + plans);
export const getPlanByIdAPI = template.getById(tariff + plans);
export const updPlanByIdAPI = template.updById(tariff + plans);
export const delPlanByIdAPI = template.deleteById(tariff + plans);

const payments = '/payments'
export const getPaymentsListAPI = template.getList(tariff + payments);
export const addPaymentsAPI = template.addOne(tariff + payments);
export const delPaymentsByIdAPI = template.deleteById(tariff + payments);

const accounts = '/accounts'
export const getAccountsTListAPI = template.getList(tariff + accounts);
export const addAccountsTAPI = template.addOne(tariff + accounts);
export const updAccountsTById = template.updById(tariff + accounts)
export const getAccountsTByIdAPI = template.getById(tariff + accounts);
export const updAccountsTByIdAPI = {
    path: (obj) => `${tariff + accounts}/${obj.idDoc}/${obj.idTariff}`,
    type: 'PUT'
  }
