import React from 'react'
import PageList from '../../../components/PageListAPI'
import { getAccountsTListAPI } from '../../../api/tariff.api'

export default function AccountList(props) {
  return <div className='App-paper'>
    <PageList
      name='account-list'
      title='Список аккаунтов'
      columns={[
        { id: 'name', name: 'Аккаунт' },
        { id: 'shortName', name: 'Компания' },
        { id: 'inn', name: 'ИНН' },
      ]}
      createItem={true}
      deleteItem={false}
      loadAPI={{
        request: getAccountsTListAPI,
      }}
      {...props}
    />
  </div>
}