import React, { useState } from 'react'
import { Button, Popover, ClickAwayListener, MenuList, MenuItem } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../reducers/form';
import { COLUMNS_ARR, formViewProps } from './gant.const'

export default function GantHideColumn() {
  const dispatch = useDispatch()
  const [autoOpen, setAutoOpen] = useState(false)
  const anchorRef = React.useRef(null);
  const columnsObj = useSelector(state => state.form[formViewProps]?.values.columnsObj||{})
  const startDiag = useSelector(state => state.form[formViewProps]?.values.startDiag||0)
  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setAutoOpen(false);
    }
  }
  const handleAutoClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setAutoOpen(false);
  };
  const handleHide = (column) => {
    let localStorageHide = []
    try {
      localStorageHide = JSON.parse(localStorage.getItem('gantColumn') || "[]")
    } catch (error) {

    }
    if (column.hide) {
      dispatch(actions.formObjChangeObj({
        hide: false,
      }, { field: 'columnsObj', id: column.value, name: formViewProps }))
      dispatch(actions.formChange(startDiag + column.width, { field: 'startDiag', name: formViewProps }))
      dispatch(actions.formArrayDelete(column.value, { field: 'columnsArrHide', name: formViewProps }))
      localStorage.setItem('gantColumn', JSON.stringify(localStorageHide.filter(c => c !== column.value)))
    }
    else {
      dispatch(actions.formObjChangeObj({
        hide: true,
      }, { field: 'columnsObj', id: column.value, name: formViewProps }))
      dispatch(actions.formChange(startDiag - column.width, { field: 'startDiag', name: formViewProps }))
      dispatch(actions.formArrayAdd(column.value, { field: 'columnsArrHide', name: formViewProps }))
      localStorage.setItem('gantColumn', JSON.stringify([...localStorageHide, column.value]))
    }
  }
  return <React.Fragment>
    <Button color='primary' onClick={() => { setAutoOpen((prevOpen) => !prevOpen); }} ref={anchorRef}>Скрыть колонки</Button>
    <Popover
      open={autoOpen}
      anchorEl={anchorRef.current}
      role={undefined}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      onClose={handleAutoClose}
    >
      <div className='App-paper'>
        <ClickAwayListener onClickAway={handleAutoClose}>
          <MenuList autoFocusItem={autoOpen} id="menu-list-grow" onKeyDown={handleListKeyDown}>
            {COLUMNS_ARR.map(key => {
              const column = columnsObj[key]
              if (!column) return null
              return <MenuItem key={key} onClick={(e) => { handleHide(column) }} selected={column.hide}>{column.name}</MenuItem>
            })}
          </MenuList>
        </ClickAwayListener>
      </div>
    </Popover>
  </React.Fragment>
}