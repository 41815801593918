import React from 'react'
import { useSelector } from 'react-redux'
import 'moment/locale/ru';
import { COLUMNS_OBJ, columnsObjTransformView } from '../gant.const'

const mwt = ['startPlan', 'endPlan', 'start', 'end', 'progress', 'perf', 'sum', 'sumPerf', 'avgCount', 'avgCountFact']
const fields = {
  material: mwt,
  work: mwt,
  textField: mwt,
  parent: ['start', 'end', 'sum', 'sumPerf'],
  milestone: ['start'],
  link: ['start', 'end', 'progress']
};
const objLabel = {
  ...COLUMNS_OBJ,
  startPlan: { name: 'Дата начала план' },
  endPlan: { name: 'Дата окончания план' },
  progress: { name: 'Прогресс' },
  perf: { name: 'Выполнено' }
}

// всплывающее окно при наведении ------------------------- 
export default function PropsView({ formView, heightRec }) {
  const form = useSelector(state => state.form[formView] || state.form.default)
  const { obj, open = false, x, y, height } = form.values
  if (!open) return null
  const widthB = 240
  const xView = 0
  const yView = heightRec
  const loc = cursorPoint({ clientX: x, clientY: y });
  const objView = columnsObjTransformView(obj)
  const arr = fields[obj.typeGant] || []
  const heightB = 30 * (arr.length + 1)
  return <g transform={`translate(${loc.x},${height + heightRec * 2})`} visibility={open ? 'visible' : 'hidden'}>
    <rect
      x={xView}
      y={yView}
      width={widthB}
      height={heightB}
      fill="#F0F8FF"// "#909bd7"
      fillOpacity={0.8}
      stroke='black'
    />
    <text
      x={5}
      y={yView + 15 + 0 + 30 * 0}
      dominantBaseline="middle"
      clipPath='url(#nameChip2)'
    >{obj.name}
    </text>
    {arr.map((key, i) => {
      const value = objView[key]
      const label = objLabel[key]?.name
      return <text
        key={key}
        x={5}
        y={yView + 15 + 30 * (1 + i)}
        dominantBaseline="middle"
        clipPath='url(#nameChip2)'
      >{`${label}: ${value}`}
      </text>
    })}
    <clipPath id="nameChip2">
      <rect x={xView} y={yView} width={widthB} height={heightB} />
    </clipPath>
  </g>
}

// Get point in global SVG space
function cursorPoint(e) {
  // pt.x = evt.clientX; pt.y = evt.clientY;
  var svg = document.getElementById('svg-full')
  var point = svg.createSVGPoint();
  point.x = e.clientX;
  point.y = e.clientY;
  var ctm = svg.getScreenCTM();
  var inverse = ctm.inverse();
  return point.matrixTransform(inverse);
}