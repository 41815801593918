import React, { useEffect, useState } from 'react'
import { TextField, Button, Tabs, Tab, Grid, Typography, } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../reducers/form';
import { fetchDispath } from '../../functions/fetch'
import moment from 'moment'
import { getCloseTender, setTenderCloseBid } from '../../api/tender.api'
import { ContactsTenderAll, TENDER_STATUS_BID } from './TenderMyRequestOne'
import { HederProps, Lots } from './TenderOpenById'
import { enqueueSnackbar } from '../../reducers/notifier'

const formName = 'TenderOurOneHeader'
const formContacts = 'TenderContact'
const formLots = 'TenderLots'
export function TenderCloseOne({ location, match, history }) {
  const [uuid, setUUID] = useState()
  const [accessKey, setAccessKey] = useState()
  const [isLoad, setLoad] = useState(false)
  const [id, setId] = useState(null)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions.formInitializeMulti({
      [formName]: {
        name: '',
        statusRus: '',
        facilityName: '',
        typeTenderRus: '',
        dateConfirmStart: '',
        dateConfirmEnd: '',
        dateOfferEnd: '',
        dateSummingEnd: '',
        statusBidRus: '',
      },
      [formContacts]: { byId: {}, allIds: [], },
      [formLots]: { projectsArr: [], projectsObj: {}, estimateObj: {} }
    }, { nameArr: [formName, formContacts, formLots] }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
      dispatch(actions.formDestroy({ name: formContacts }))
      dispatch(actions.formDestroy({ name: formLots }))
    }
  }, [dispatch])
  const handleLoad = () => {
    dispatch(fetchDispath({
      progress: true,
      request: getCloseTender,
      param: {
        id: uuid
      },
      querty: {
        access: accessKey
      }
    })).then(res => {
      if (!res.success) {
        dispatch(enqueueSnackbar({
          message: `Тендер не найден`,
          options: { variant: 'error' }
        }))
        return;
      }
      dispatch(actions.formChangeAsObj({
        name: res.get.name,
        facilityName: res.get.facilityName,
        dateConfirmStart: moment(res.get.dateConfirmStart).format('LLL'),
        dateConfirmEnd: moment(res.get.dateConfirmEnd).format('LLL'),
        dateOfferEnd: moment(res.get.dateOfferEnd).format('LLL'),
        dateSummingEnd: moment(res.get.dateSummingEnd).format('LLL'),
        typeTenderRus: res.get.typeTender === 'open' ? 'открытый' : 'закрытый',
        regionsArr: res.get.regions,
        regions: res.get.regions.join(', '),
        specializationsArr: res.get.specializations,
        specializations: res.get.specializations.join(', '),
        statusBidRus: TENDER_STATUS_BID.find(s => s.value === res.get.statusBid).label,
      }, { name: formName }))
      setId(res.get.id)
      let byId = {}
      const allIds = res.get.contacts.map(e => {
        const newId = 'id' + e.id;
        byId[newId] = { ...e, fio: [e.surname, e.name, e.patronymic].join(' ') }
        return newId
      });
      dispatch(actions.formChangeAsObj({ byId, allIds }, { name: formContacts }))
      const { estimate, projects } = res.get.estimate;
      const projectsArr = []
      let projectsObj = {}
      projects.forEach(obj => {
        projectsArr.push(obj.id)
        let groupObj = {}
        const groupArr = obj.group.map(e => {
          groupObj[e.id] = { ...e, child: [] };
          return e.id
        })
        projectsObj[obj.id] = {
          id: obj.id,
          dateStart: moment(obj.dateStart).format('DD.MM.YYYY'),
          dateEnd: moment(obj.dateEnd).format('DD.MM.YYYY'),
          name: obj.name,
          group: {
            arr: groupArr,
            obj: groupObj,
          }
        }
      })
      let estimateObj = {}
      estimate.forEach(e => {
        estimateObj[e.id] = {
          ...e,
          dateStart: moment(e.dateStart).format('DD.MM.YYYY'),
          dateEnd: moment(e.dateEnd).format('DD.MM.YYYY'),
          typeEstimate: e.typeEstimate === 'material' ? 'М' : 'Р',
        }
        projectsObj[e.idProject]?.group.obj[e.idGroup]?.child.push(e.id);
      })
      dispatch(actions.formChangeAsObj({ projectsArr, projectsObj, estimateObj }, { name: formLots }))
      setLoad(true)
    }).catch(err => console.log(err))
  }
  return <div className='App-paper'>
    <Typography variant='h6'>Получить закрытый тендер</Typography>
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-end"
      spacing={2}
    >
      <Grid item sm={6} md={4}>
        <TextField id="standard-basic" fullWidth label="UUID" value={uuid} onChange={(e) => setUUID(e.target.value)} />
      </Grid>
      <Grid item sm={3} md={2}>
        <TextField id="standard-basic" fullWidth label="ключ  доступа" value={accessKey} onChange={(e) => setAccessKey(e.target.value)} />
      </Grid>
      <Grid item >
        <Button color="primary" onClick={handleLoad}>Получить</Button>
      </Grid>
    </Grid>
    {isLoad && <TenderClose id={id} match={match} history={history} access={accessKey} uuid={uuid} />}
    <Button color="primary" onClick={() => history.goBack()}>Назад</Button>
  </div>
}

function TenderClose({ id, match, history, access, uuid }) {
  const [tabsId, setTabsId] = useState(0)
  return <>
    <HederProps
      history={history}
      request={{
        request: setTenderCloseBid,
        param: {
          id: uuid,
        },
        body: {
          access: access
        }
      }}
      urlSuccess={`${match.url.replace('/close', '/my-request')}/${id}`}
    />
    <Tabs
      value={tabsId}
      onChange={(e, v) => setTabsId(v)}
      indicatorColor='primary'
      style={{ borderBottom: '1px solid #e8e8e8', }}
    >
      <Tab label='Контакты' />
      <Tab label='Лоты' />
    </Tabs>
    {tabsId === 0 && <ContactsTenderAll />}
    {tabsId === 1 && <Lots />}
  </>
}