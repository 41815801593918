import React from 'react'
/** 
 * defs fill patterns
 * @param {import("./types/DefsPattern").DefsPattern} param0 
 */
export default function DefsPattern({ heightSVG, sizeDay, sizeWeek, yPadding }) {
  return <defs>
    <pattern id="patternGridDay"
      x="0" y="0" width={sizeDay} height={yPadding}
      patternUnits="userSpaceOnUse" >
      <path
        d={`M ${sizeDay / 2},0 V ${yPadding} Z M 0,${sizeDay / 2} H ${yPadding} Z`}
        stroke="#000000ff"
        fill="none"
        strokeWidth={0.1}
      />
    </pattern>
    <pattern id="patternGridWeek"
      x="0" y="0" width={sizeWeek / 7} height={yPadding}
      patternUnits="userSpaceOnUse" >
      <path
        d={`M ${sizeWeek / 14},0 V ${yPadding} Z M 0,${sizeWeek / 14} H ${yPadding} Z`}
        stroke="#000000ff"
        fill="none"
        strokeWidth={0.5}
      />
    </pattern>
    <pattern id="patternGridWeekend"
      x="0" y="0" width={sizeDay * 7} height={heightSVG}
      patternUnits="userSpaceOnUse" >
      <rect
        x={0}
        y={0}
        fill="#fc9292"
        fillOpacity={1}
        width={sizeDay * 2}
        height={heightSVG}
      />
    </pattern>
    <pattern id="patternGridYear"
      x="0" y="0" width={sizeDay} height={yPadding}
      patternUnits="userSpaceOnUse" >
      <path
        d={`M 0,${yPadding / 2} H ${yPadding} Z`}
        stroke="#000000ff"
        fill="none"
        strokeWidth={0.1}
      />
    </pattern>
    <pattern id="patternTableRows"
      x="0" y="0" width={sizeDay} height={yPadding * 2}
      patternUnits="userSpaceOnUse" >
      <rect x={0} y={0}
        width={sizeDay}
        height={yPadding}
        fill="#f2f2f2"
      />
    </pattern>
    <pattern id="patternHeaderGanttDay"
      x="0" y="0" width={sizeDay} height={sizeDay}
      patternUnits="userSpaceOnUse" >
      <path
        d={`M ${sizeDay / 2},0 V ${sizeDay} Z `}
        stroke="#a6a6a6"
        fill="none"
        strokeWidth={1}
      />
    </pattern>
    <pattern id="patternHeaderGanttDayWeekend"
      x="0" y="0" width={sizeDay * 7} height={sizeDay}
      patternUnits="userSpaceOnUse" >
      <rect
        x={0}
        y={0}
        fill="#fc9292"
        fillOpacity={1}
        width={sizeDay * 2}
        height={sizeDay}
        strokeWidth="1"
      />
    </pattern>
  </defs>
}