import React from 'react'
import { useSelector } from 'react-redux'
import PageList from '../../../components/PageListAPI'
import { getAccountsTByIdAPI } from '../../../api/tariff.api'
import { Button, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom';

const formName = 'account-one'

export default function AccountOne(props) {
  return <div className='App-paper'>
    <PageList
      name={formName}
      title='Карточка аккаунта'
      columns={[
        { id: 'planName', name: 'Тариф' },
        { id: 'isActive', name: 'Активность', type: 'component', component: ({ obj }) => <>{obj.isActive ? 'Да' : 'Нет'}</> },
        { id: 'dateEnd', name: 'Срок окончания', type: 'date', formatDate: 'DD.MM.YYYY' },
        { id: 'remainingDays', name: 'Оставшиеся дни' },
        { id: 'creditDays', name: 'Дни в кредит' },
      ]}
      idName='idPlan'
      createItem={false}
      deleteItem={false}
      loadAPI={{
        param: {
          id: props.match.params.idDoc
        },
        request: getAccountsTByIdAPI,
      }}
      headerInit={{
        sortBy: 'dateEnd',
        sortDirection: 'desc',
        obj: { name: '' }
      }}
      headerLink={<HederAccount match={props.match} />}
      pagination={false}
      tableProps={{
        pagination: false,
      }}
      {...props}
    />
  </div>
}

function HederAccount({ match }) {
  const obj = useSelector(state => state.formTable[formName]?.obj)
  const toLinkChange = React.forwardRef((props3, ref) => (<Link ref={ref} to={match.url + '/edit'} {...props3} />))
  return <div>
    <Typography variant='h6'>{obj?.name || ''}</Typography>
    <Typography>компания {obj?.fullName || ''}</Typography>
    <Typography>ИНН {obj?.inn || ''}</Typography>
    <Button color="primary" component={toLinkChange}>Изменить </Button>
  </div>
}