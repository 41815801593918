import React, { useState } from 'react';
import {
  Button, TextField,
  Typography, Grid,
} from '@material-ui/core';
import { useDispatch, } from 'react-redux';
import { fetchDispath } from '../../../functions/fetch'
import { createObjectFromTender } from '../../../api/tender.api'
import AutoCompliteWithLoad from '../../../components/AutocompliteWithLoad'
import { getFacility } from '../../../api/facility'

export default function ProjectsCreate({ idDoc }) {
  const [objectName, setObjectName] = useState('')
  const [facilitySelect, setFacilitySelect] = useState(null)
  const [isNewObject, setIsNewObject] = useState(true)
  const dispatch = useDispatch()
  const handleCreate = () => {
    const body = {
      idObject: null,
      objectName: ''
    }
    if (isNewObject) {
      body.objectName = objectName
    }
    else {
      body.idObject = facilitySelect
    }
    dispatch(fetchDispath({
      progress: true,
      request: createObjectFromTender,
      param: {
        id: idDoc
      },
      body: body
    })).then((res) => {
    })
  }
  return <>
    <Typography>Создать лоты</Typography>
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems='stretch'
    >
      <Grid>
        <Button
          color={isNewObject ? "primary" : "default"}
          onClick={() => setIsNewObject(true)}
        >
          Создать новый объект
        </Button>
        <Button
          color={isNewObject ? "default" : "primary"}
          onClick={() => setIsNewObject(false)}
        >
          Добавить в существующий объект
        </Button>
      </Grid>
      <Grid item md={6} lg={6} >
        {isNewObject && <TextField
          fullWidth
          label="Наименование объекта"
          onChange={(e) => setObjectName(e.target.value)}
        />}
        {!isNewObject && <AutoCompliteWithLoad
          classes={{}}
          field='idFacility'
          name='Объект'
          path={getFacility.path}
          meta={{
            field: 'idFacility',
            value: 'id',
            labelFunc: (obj) => obj.name
          }}
          form={false}
          change={e => { setFacilitySelect(e.value) }}
        />}
      </Grid>
      <Grid>
        <Button color="primary" onClick={handleCreate}>Создать </Button>
      </Grid>
    </Grid>
  </>
}