import React, {useState} from 'react'
import GantHeader from './gant/ContractorGantHeader'
import GrandView from './gant/ContractorGrandView'

export default function ContractorGant({ formGant, formDialog, formView, COLUMNS_ARR }) {
  const [zoom, setZoom] = useState('day')
  const [percent, setPercent] = useState(130)
  const [fixTable, setFixTable] = useState(true)
  return <div>
    <GantHeader
      zoom={zoom}
      setZoom={setZoom}
      setPercent={setPercent}
      setFixTable={setFixTable}
      COLUMNS_ARR={COLUMNS_ARR}
    />
    <GrandView
      formDialog={formDialog}
      formView={formView}
      formName={formGant}
      zoom={zoom}
      percent={percent}
      fixTable={fixTable}
      COLUMNS_ARR={COLUMNS_ARR}
    />
  </div>
}