import React from 'react'
import { useSelector } from 'react-redux'
import { formViewProps } from '../gant.const'
// поперечные строки таблицы---------------------------------------------
export default function GridTable({ heightSVG, heightDateLine }) {
  const startDiag = useSelector(state => state.form[formViewProps]?.values.startDiag) || 1810
  return <g id="sticky-table-row">
    <g transform={`translate(0,${heightDateLine})`} >
      <rect
        x={0}
        y={0}
        fill="url(#patternTableRows)"
        fillOpacity={1}
        width={startDiag}
        height={heightSVG}
        stroke='none'
      />
    </g>
  </g>
}