import template from "./template_crud";
const projects = '/projects'
export const loadProjectById = template.getById(projects);
export const loadProject = template.getList(projects);
export const deleteProject = template.deleteById(projects);
export const addProject = template.addOne(projects);
export const loadProjectUsers = template.getById(projects, '/users');
export const updProjectById = template.updById(projects);
export const getProjectOur = template.getList(projects + '/our')
export const updStarProject = template.updById(projects, '/star')

export const copyProject = template.postProjectId('/copy')

export const getProjectMachine = template.getProjectId('/machine')
export const getProjectMachineProject = template.getProjectId('/machine/project')
export const getProjectMachineDay = template.getProjectId('/machine/day')
export const getProjectMachineObject = template.getProjectId('/machine/object')
export const addProjectMachine = template.postProjectId('/machine')
export const updProjectMachine = {
  path: (obj) => `/projects/${obj.idProject}/machine/${obj.id}`,
  type: 'PUT'
}
export const getProjectMachineId = template.getProjectIdById('/machine')
export const delProjectMachine = template.deleteProjectById('/machine')

export const getProjectWayBill = template.getProjectId('/waybill')
export const getProjectWayBillTaskOld = template.getProjectId('/waybill/task-old')
export const addProjectWayBill = template.postProjectId('/waybill')
export const delProjectWayBill = template.deleteProjectById('/waybill')
export const getProjectWayBillById = {
  path: (obj) => `/projects/${obj.idProject}/waybill/${obj.id}`,
  type: 'GET'
}
export const getProjectWayBillByIdPrint = {
  path: (obj) => `/projects/${obj.idProject}/waybill/${obj.id}/print`,
  type: 'GET'
}
export const updProjectWayBillById = {
  path: (obj) => `/projects/${obj.idProject}/waybill/${obj.id}`,
  type: 'PUT'
}
export const updProjectWayBillByIdFixed = {
  path: (obj) => `/projects/${obj.idProject}/waybill/${obj.id}/fixed`,
  type: 'PUT'
}
export const updProjectOur = template.updById(projects, '/our')
// waybilltask

export const getWayBillTask = {
  path: (obj) => `/projects/${obj.idProject}/waybill/${obj.id}/task`,
  type: 'GET'
}

export const addWayBillTask = {
  path: (obj) => `/projects/${obj.idProject}/waybill/${obj.id}/task`,
  type: 'POST'
}

export const updWayBillTask = {
  path: (obj) => `/projects/${obj.idProject}/waybill/${obj.id}/task/${obj.idTask}`,
  type: 'PUT'
}

export const deleteWayBillTask = {
  path: (obj) => `/projects/${obj.idProject}/waybill/${obj.id}/task/${obj.idTask}`,
  type: 'DELETE'
}

export const addWayBillTaskResult = {
  path: (obj) => `/projects/${obj.idProject}/waybill/${obj.id}/task_result`,
  type: 'POST'
}

//project contractor 
export const getProjectContractor = {
  path: (obj) => `/projects/${obj.idProject}/contractor`,
  type: 'GET'
}
export const getProjectContractorById = {
  path: (obj) => `/projects/${obj.idProject}/contractor/${obj.idParticipant}`,
  type: 'GET'
}
export const updProjectContractor = {
  path: (obj) => `/projects/${obj.idProject}/contractor/access`,
  type: 'PUT'
}

export const deleteProjectContractor = {
  path: (obj) => `/projects/${obj.idProject}/contractor/${obj.childOrParent}`,
  type: 'DELETE'
}