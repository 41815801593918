import { actionCreatorObj, actionCreatorObjMeta, actionCreatorEmptyMeta } from '../functions/redux'
const MAIN = 'formTable/'
const CLEAR_FORM_TABLE = MAIN + 'CLEAR'
const LOAD_FORM_TABLE = MAIN + 'LOAD'
const SET_FORM_TABLE = MAIN + 'SET'
const INIT_FORM_TABLE = MAIN + 'INIT'
const CHANGE_PAGE_FORM_TABLE = MAIN + 'CHANGE_PAGE'
const CHANGE_LIMIT_FORM_TABLE = MAIN + 'CHANGE_LIMIT'
const RELOAD_FORM_TABLE = MAIN + 'RELOAD'
const DELETE_ROW_FORM_TABLE = MAIN + 'DELETE_ROW'
const DELETE_ROWS_FORM_TABLE = MAIN + 'DELETE_ROWS'
const ADD_ROW_FORM_TABLE = MAIN + 'ADD_ROW'
const EDIT_FIELD_TABLE = MAIN + 'EDIT_FIELD'
const SET_SORT_BY = MAIN + 'SET_SORT_BY'
const SET_SORT_DIRECTION = MAIN + 'SET_SORT_DIRECTION'
const SET_SORT = MAIN + 'SET_SORT'
export const loadFormTable = actionCreatorObjMeta(LOAD_FORM_TABLE)
//meta:{
//	name: table name,
//	id: name ids
//}
export const clearFormTable = actionCreatorObj(CLEAR_FORM_TABLE)
export const initFormTable = actionCreatorObj(INIT_FORM_TABLE)
export const setFormTable = actionCreatorObjMeta(SET_FORM_TABLE)
export const changePageFormTable = actionCreatorObjMeta(CHANGE_PAGE_FORM_TABLE)
export const changeLimitFormTable = actionCreatorObjMeta(CHANGE_LIMIT_FORM_TABLE)
export const reloadFormTable = actionCreatorEmptyMeta(RELOAD_FORM_TABLE)
export const deleteFormTable = actionCreatorObjMeta(DELETE_ROW_FORM_TABLE)
export const deleteRowsFormTable = actionCreatorObjMeta(DELETE_ROWS_FORM_TABLE)
export const addRowFormTable = actionCreatorObjMeta(ADD_ROW_FORM_TABLE)
export const editFieldTable = actionCreatorObjMeta(EDIT_FIELD_TABLE)
export const setSortByFormTable = actionCreatorObjMeta(SET_SORT_BY)
export const setSortFormTable = actionCreatorObjMeta(SET_SORT)
export const setSortDirectionFormTable = actionCreatorObjMeta(SET_SORT_DIRECTION)
export const directionSortConst = {
  ASC: 'asc',
  DESC: 'desc',
};
export const initialState = {
  default: {
    byId: {},
    allIds: [],
    count: 10,
    reload: false,
    obj: {},
    sortBy: 'name',
    sortDirection: directionSortConst.ASC
  }
}
export default function redRouter(state = initialState, action) {
  switch (action.type) {
    case CLEAR_FORM_TABLE: return clear(state, action.payload)
    case LOAD_FORM_TABLE: return load(state, action.payload, action.meta)
    case SET_FORM_TABLE: return set(state, action.payload, action.meta)
    case INIT_FORM_TABLE: return init(state, action.payload)
    case CHANGE_PAGE_FORM_TABLE: return { ...state, [action.meta.name]: { ...state[action.meta.name], page: action.payload, reload: true } }
    case CHANGE_LIMIT_FORM_TABLE: return { ...state, [action.meta.name]: { ...state[action.meta.name], limit: action.payload, reload: true } }
    case RELOAD_FORM_TABLE: return { ...state, [action.meta.name]: { ...state[action.meta.name], reload: true } }
    case DELETE_ROW_FORM_TABLE: return deleteRow(state, action.payload, action.meta)
    case DELETE_ROWS_FORM_TABLE: return deleteRows(state, action.payload, action.meta)
    case ADD_ROW_FORM_TABLE: return addRowTable(state, action.payload, action.meta)
    case EDIT_FIELD_TABLE: return editField(state, action.payload, action.meta)
    case SET_SORT_BY: return setSortBy(state, action.payload, action.meta)
    case SET_SORT: return setSort(state, action.payload, action.meta)
    case SET_SORT_DIRECTION: return setSortDirection(state, action.payload, action.meta)
    default: return state;
  }
}

function set(state, obj, meta) {
  const { allIds, byId, ...header } = obj;
  return ({
    ...state,
    [meta.name]: {
      ...state[meta.name],
      allIds: allIds,
      byId: byId,
      reload: false,
      count: allIds.length,
      ...header
    }
  })
}

function editField(state, newValue, meta) {
  const { id, field } = meta
  return {
    ...state,
    [meta.name]: {
      ...state[meta.name],
      byId: {
        ...state[meta.name].byId,
        [id]: {
          ...state[meta.name].byId[id],
          [field]: newValue
        },
      },
    }
  }
}

function addRowTable(state, obj, meta) {
  const newId = 'id' + obj.id + '_' + state[meta.name].allIds.length
  const orderEnd = meta.end || false
  return {
    ...state,
    [meta.name]: {
      ...state[meta.name],
      byId: {
        [newId]: obj,
        ...state[meta.name].byId
      },
      allIds: (orderEnd) ? [...state[meta.name].allIds, newId] : [newId, ...state[meta.name].allIds]
    }
  }
}

function deleteRow(state, obj, meta) {
  const { [obj.id]: value, ...second } = state[meta.name].byId
  return {
    ...state,
    [meta.name]: {
      ...state[meta.name],
      byId: second,
      allIds: state[meta.name].allIds.filter(key => key !== obj.id),
      count: state[meta.name].count - 1
    }
  }
}
function deleteRows(state, arr, meta) {
  let difference = state[meta.name].allIds.filter(x => !arr.includes(x));
  let byId = {}
  difference.forEach(id => {
    byId[id] = state[meta.name].byId[id]
  });
  return {
    ...state,
    [meta.name]: {
      ...state[meta.name],
      byId: byId,
      allIds: difference,
      count: state[meta.name].count - arr.length
    }
  }
}
function clear(state, payload) {
  const { [payload]: value, ...second } = state
  return second
}
function load(state, obj, meta) {
  let byId = {}
  let { get, ...header } = obj
  let allIds = get.map((row, key) => {
    let id = 'id' + row[meta.id] + '_' + key
    byId[id] = row;
    return id;
  })
  return ({
    ...state,
    [meta.name]: {
      ...state[meta.name],
      allIds: allIds,
      byId: byId,
      reload: false,
      ...header
    }
  })
}
function init(state, payload) {
  return {
    ...state,
    [payload.name]: {
      ...initialState.default,
      ...payload.header
    }
  }
}
function stableSort(array, objs, orderBy, order) {
  const stabilizedThis = array.map(key => ([key, String(objs[key][orderBy])]));
  const ord = order === directionSortConst.DESC ? 1 : -1
  stabilizedThis.sort((a, b) => {
    return ord * a[1].localeCompare(b[1], undefined, { numeric: true, sensitivity: 'base' })
    // return ord * desc(a[1], b[1]);
  });
  return stabilizedThis.map(el => el[0]);
}
function setSortBy(state, newSotrBy, meta) {
  const { allIds, byId, sortDirection } = state[meta.name]
  const newArr = stableSort(allIds, byId, newSotrBy, sortDirection)
  return ({
    ...state,
    [meta.name]: {
      ...state[meta.name],
      allIds: newArr,
      sortBy: newSotrBy
    }
  })
}
function setSortDirection(state, newSortDirection, meta) {
  const { allIds, byId, sortBy } = state[meta.name]
  console.log(newSortDirection)
  const newArr = stableSort(allIds, byId, sortBy, newSortDirection)
  return ({
    ...state,
    [meta.name]: {
      ...state[meta.name],
      allIds: newArr,
      sortDirection: newSortDirection
    }
  })
}
function setSort(state, obj, meta) {
  const { sortBy, sortDirection } = obj
  const { allIds, byId } = state[meta.name]
  const newArr = stableSort(allIds, byId, sortBy, sortDirection)
  return ({
    ...state,
    [meta.name]: {
      ...state[meta.name],
      allIds: newArr,
      sortDirection: sortDirection,
      sortBy: sortBy
    }
  })
}