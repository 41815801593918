import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../reducers/form';
import validateProps from '../../../functions/validateProps'
import { enqueueSnackbar } from '../../../reducers/notifier'
import SimpleAdd from '../../../components/SimpleAdd'
import { updAccountsTById } from '../../../api/tariff.api'
import { fetchDispath } from '../../../functions/fetch'
import { getCompanyAPI } from '../../../api/list'
import { getAccountsTByIdAPI } from '../../../api/tariff.api'

const arrProps = [
  {
    bp: { md: 12, sm: 12 },
    field: 'name',
    label: '  Наименование', type: 'text',
    validator: [value => value !== ''], errorText: 'Поле обязательно'
  },
  {
    bp: { md: 6, sm: 12 },
    field: 'fullName', type: 'text', label: 'Полное название компании*',
    validator: [value => value !== ''], errorText: 'Поле обязательно',
  },
  {
    bp: { md: 6, sm: 12 },
    field: 'shortName', type: 'text', label: 'Сокращенное название компании*',
    validator: [value => value !== ''], errorText: 'Поле обязательно',
  },
  {// 10-12
    bp: { md: 6, sm: 12 },
    field: 'inn', type: 'text', label: 'ИНН*',
    validator: [value => value !== '', v => v.length >= 10 && v.length <= 12], errorText: 'Поле обязательно',
  },
  { // 13 символов,15
    bp: { md: 6, sm: 12 },
    field: 'ogrn', type: 'text', label: 'ОГРН, ОГРНИП*',
    validator: [value => value !== '', v => v !== '' && v.length >= 13 && v.length <= 15], errorText: 'Поле обязательно',
  },
  { // 9
    bp: { md: 6, sm: 12 },
    field: 'kpp', type: 'text', label: 'КПП',
    validator: [v => v === '' || v.length === 9], errorText: 'Неправильное значение',
  },
  {// 8-10
    bp: { md: 6, sm: 12 },
    field: 'okpo', type: 'text', label: 'ОКПО',
    validator: [v => v === '' || (v.length >= 8 && v.length <= 10)], errorText: 'Неправильное значение',
  },
  {
    bp: { md: 6, sm: 12 },
    field: 'oktmo', type: 'text', label: 'ОКТМО',
  },
  { bp: { md: 6, sm: 12 }, field: 'address', type: 'text', label: 'Адрес', },
  { bp: { md: 6, sm: 12 }, field: 'contacts', type: 'text', label: 'Контакты', },

]
export default function AccountEdit({ history, match }) {
  const dispatch = useDispatch()
  const formName = 'AccountEdit'
  useEffect(() => {
    dispatch(actions.formInitialize({
      name: '',
      fullName: '', shortName: '', inn: '', ogrn: '', kpp: '',
      okpo: '', oktmo: '', address: '', contacts: '',
      errorValidate: { name: false }
    }, { name: formName }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
    }
  }, [dispatch])
  useEffect(() => {
    dispatch(fetchDispath({
      param: {
        id: match.params.idDoc,
      },
      progress: true,
      request: getAccountsTByIdAPI,
    }))
      .then(res => {
        dispatch(actions.formInitialize({
          name: res.obj.name,
          fullName: res.obj.fullName,
          shortName: res.obj.shortName,
          inn: res.obj.inn,
          ogrn: res.obj.ogrn,
          kpp: res.obj.kpp,
          okpo: res.obj.okpo,
          oktmo: res.obj.oktmo,
          address: res.obj.address,
          contacts: res.obj.contacts,
        }, { name: formName }))
      })
      .catch(err => console.log(err))
  }, [dispatch, match.params.idDoc])

  const values = useSelector(state => state.form[formName]?.values) || {}
  const handleSave = (e) => {
    e.preventDefault();
    const isError = validateProps(arrProps, values)
    dispatch(actions.formChange(isError.validate, { field: 'errorValidate', name: formName }))
    if (isError.check) {
      dispatch(enqueueSnackbar({
        message: `некорректные поля`,
        options: { variant: 'error' }
      }))
      return;
    }
    const { fullName, shortName, name, inn, ogrn, kpp, okpo, oktmo, address, contacts } = values
    dispatch(actions.formSubmitAPI({
      props: { fullName, shortName, name, inn, ogrn, kpp, okpo, oktmo, address, contacts },
      url: updAccountsTById.path({ id: match.params.idDoc }),
      method: updAccountsTById.type,
      history: history
    }, formName))
  };
  const handleLoad = () => {
    dispatch(fetchDispath({
      querty: {
        inn: values.inn
      },
      progress: false,
      request: getCompanyAPI,
    }))
      .then(res => {
        if (res.success) {
          dispatch(actions.formChangeAsObj({
            fullName: res.get.fullName,
            shortName: res.get.shortName,
            inn: res.get.inn,
            ogrn: res.get.ogrn,
            kpp: res.get.kpp,
            okpo: res.get.okpo,
            oktmo: res.get.oktmo,
            address: res.get.address,
            contacts: [res.get.phone, res.get.email].join('; '),
          }, { name: formName }))
        }
        else {
          dispatch(enqueueSnackbar({
            message: `Компания не найдена`,
            options: { variant: 'error' }
          }))
        }
      })
      .catch(err => console.log(err))
  }
  return (
    <div className='App-paper'>
      <Button onClick={handleLoad} color="primary" >Заполнить по ИНН</Button>
      <form autoComplete="off" onSubmit={handleSave}>
        <div style={{ padding: '16px' }}>
          <SimpleAdd
            arr={arrProps}
            formName={formName}
            title='Редактировать аккаунт'
            gridProps={{ md: 12, lg: 9 }}
          />
        </div>
        <Button color="primary" onClick={() => history.goBack()}>Назад </Button>
        <Button type="submit" color="primary">Сохранить</Button>
      </form>
    </div>
  );
}