import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { FormControl, FormLabel, FormGroup, Switch, FormControlLabel, Typography, Button } from '@material-ui/core'
import { fetchDispath } from '../../functions/fetch'
import { loadProjectById, updProjectContractor } from '../../api/projects'
import * as actions from '../../reducers/form';

const projectAccessModuleInit = {
  estimate: true,
  gant: false,
  storage: false,
  detailed_progress: false
}
export default function ProjectContract({ idProject, history }) {
  const [accessModule, setAccessModule] = useState(projectAccessModuleInit)
  const [idParticipant, setIdParticipant] = useState(null)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchDispath({
      param: {
        id: idProject,
      },
      progress: true,
      request: loadProjectById,
    })).then(res => {
      const { idParticipant, projectAccessModule = {} } = res.get
      setIdParticipant(idParticipant)
      setAccessModule(old => ({ ...old, ...projectAccessModule }))

    }).catch(err => console.log(err))
  }, [dispatch, idProject])

  const handleChange = (e) => {
    const newObj = { [e.target.name]: e.target.checked }
    dispatch(actions.formSubmitAPI({
      props: { module: e.target.name, access: e.target.checked },
      url: updProjectContractor.path({ idProject: idProject }),
      method: updProjectContractor.type,
      endFunc: (res) => {
        setAccessModule(old => ({ ...old, ...newObj }));
      }
    }, 'formName'))
  }
  return <div style={{ padding: 16 }}>
    {!idParticipant && <Typography>Нет соединений</Typography>}
    {idParticipant && <Typography>Проект выступает в качестве подрядчика</Typography>}
    <FormControl disabled={!idParticipant}>
      <FormLabel >Открыть доступ к модулям</FormLabel>
      <FormGroup>
        <FormControlLabel
          disabled
          control={<Switch checked={accessModule.estimate} onChange={handleChange} name="estimate" />}
          label="Смета"
        />
        <FormControlLabel
          control={<Switch checked={accessModule.gant} onChange={handleChange} name="gant" />}
          label="Диаграмма ганта"
        />
        <FormControlLabel
          control={<Switch checked={accessModule.storage} onChange={handleChange} name="storage" />}
          label="Склад"
        />
        <FormControlLabel
          control={<Switch checked={accessModule.detailed_progress} onChange={handleChange} name="detailed_progress" />}
          label="Детальный прогресс"
        />
      </FormGroup>
    </FormControl>
    <p/>
    <Button color="primary" onClick={() => history.goBack()}>Назад </Button>
  </div>
}