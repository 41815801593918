import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAccountList } from '../../api/accounts'
import { API_BO } from '../../conf'
import { fetchDispath } from '../../functions/fetch'
import { loginAccount } from '../../api/users'
import { Button, List, ListItem, ListItemText, Typography, ListItemIcon } from '@material-ui/core';
import { Link } from 'react-router-dom';
import * as actions from '../../reducers/formTable';
import CheckIcon from '@material-ui/icons/Check';
import { headerUnSetProject } from '../../reducers/header'

const formName = 'accountList'
function Acceptance(props) {
  const dispatch = useDispatch()
  const onClickFunction = (row, obj) => {
    if (!obj.id) return;
    dispatch(fetchDispath({
      body: {
        accountId: obj.id
      },
      progress: true,
      request: loginAccount,
    })).then(res => {
      dispatch(headerUnSetProject())
      localStorage.setItem('accountId', obj.id);
      localStorage.setItem('accountName', obj.name);
      localStorage.setItem('token', res.token);
      localStorage.setItem('role', res.role);
      props.history.push(API_BO + '/facility/our')
    }).catch(err => console.log(err))
  }
  const g = localStorage.getItem('g')
  const toLinkAdd = React.forwardRef((props, ref) => (
    <Link ref={ref} to={API_BO + `/a-panel`} {...props} />
  ));
  const currentAccount = Number(localStorage.getItem('accountId') || 0)
  useEffect(() => {
    dispatch(fetchDispath({
      progress: true,
      request: getAccountList,
    })).then(res => {
      dispatch(actions.loadFormTable({ ...res }, { name: formName, id: 'id' }))
    }).catch(err => console.log(err))
  }, [dispatch])
  const allIds = useSelector(state => state.formTable[formName]?.allIds) || []
  const byId = useSelector(state => state.formTable[formName]?.byId) || {}

  return (
    <div className='App-paper'>
      <Typography variant='h6'>Выберите аккаунт</Typography>
      {g !== 'false' && <Button component={toLinkAdd} color="primary">Панель</Button>}
      <List component="nav">
        {allIds.map(key => {
          const obj = byId[key]
          return <ListItem
            key={key}
            button
            onClick={() => onClickFunction(key, obj)}
            selected={currentAccount === obj.id}
          >
            <ListItemIcon>
              {currentAccount === obj.id && <CheckIcon style={{ color: '#2e7d32' }} />}
            </ListItemIcon>
            <ListItemText primary={obj.name} secondary={`${obj.shortName || ''} ${obj.inn ? ('ИНН ' + obj.inn) : ''}`} />
          </ListItem>
        })}
      </List>
    </div>
  )
}

export default Acceptance;